import { Theme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Stack from '@mui/material/Stack'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { deepmerge } from '@mui/utils'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import { some } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'
import { getFileSizeString } from '../DocumentPage'
import {
  ComponentDynamicGallery,
  UploadFileEntity
} from '../../../../../models/types'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TEXT_PALETTE } from '@/style/palette'
import { useSearchParams } from '@/core/hooks/useSearchParams'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const YOUTUBE = '.YouTube'
const VIDEO_TYPES = ['.mp4']
export const IMAGE_TYPES = [
  '.png',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.gif',
  '.svg',
  '.tif',
  '.webp'
]

const getYouTubeId = (url: string) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/
  )
  return match && match[1]
}

type FileAttributes = {
  href: string
  name?: string | null
  ext?: string | null
  hash?: string | null
}

export const Gallery: React.FC<ComponentDynamicGallery> = props => {
  const ref = useRef<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const hasQuery = !!searchParams?.get('q')
  const queryIndex = Number(searchParams?.get('q') || 0)

  const [lightbox, setLightbox] = useState({
    isOpen: hasQuery,
    photoIndex: queryIndex
  })

  useEffect(() => {
    setSearchParams(
      lightbox.isOpen ? { q: lightbox.photoIndex?.toString() } : {},
      { replace: true }
    )
  }, [lightbox, setSearchParams])

  const [filter, setFilter] = useState('')
  const [downloading, setDownloading] = useState(false)
  const { getImagePath, getImageStorageUrl, download } = useCMSFile()
  const { t } = useRcTranslation()

  const loweredFilter = filter?.toLocaleLowerCase()
  const images = [
    ...(props?.content?.data?.filter((it: UploadFileEntity) => {
      return (
        it.attributes?.name?.toLocaleLowerCase()?.includes(loweredFilter) ||
        it.attributes?.mime?.includes(loweredFilter)
      )
    }) || []),
    ...(props?.manualContent?.map(
      it =>
        ({
          id: it?.id,
          attributes: { name: it?.title, url: it?.path, ext: YOUTUBE, size: 0 }
        }) as UploadFileEntity
    ) || [])
  ]

  const photoIndex = mod(lightbox.photoIndex, images.length)

  const handleDownload = async () => {
    const downloadZip = async (files: FileAttributes[], title: string) => {
      const zip = new JSZip()

      const getAndZip = async (file: FileAttributes) => {
        const res = await fetch(getImageStorageUrl(file.href)!)
        const buffer = await res.arrayBuffer()
        const dedupedName =
          files.filter(it => it.name === file.name).length > 1
            ? `${file.hash}${file.ext}`
            : `${file.name}`

        zip.file(dedupedName, buffer, { base64: true })
      }

      const promises: Promise<any>[] = []
      for (const file of files) {
        promises.push(getAndZip(file))
      }

      await Promise.allSettled(promises)

      const blob = await zip.generateAsync({ type: 'blob' })
      saveAs(blob, `${title}.zip`)
    }

    setDownloading(true)
    await downloadZip(
      images
        ?.filter(it => it.attributes?.ext !== YOUTUBE)
        ?.map(it => ({
          name: it.attributes?.name,
          ext: it.attributes?.ext,
          hash: it.attributes?.hash,
          href: getImageStorageUrl(it.attributes?.url)
        })),
      props.title || 'gallery'
    )
    setDownloading(false)
  }

  const slideRenderer = ({ key, index }: any) => {
    const current = images[mod(index, images.length)]
    if (!lightbox.isOpen) return false
    return (
      <Box
        key={key}
        width='100%'
        height='80vh'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {IMAGE_TYPES.includes(current.attributes?.ext?.toLocaleLowerCase()!) ? (
          <img
            src={getImagePath(current, ImageSize.Large)!}
            alt={current?.attributes?.alternativeText!}
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        ) : index !== lightbox.photoIndex ? (
          <Box width='100vw' />
        ) : current.attributes?.ext === YOUTUBE ? (
          <iframe
            allow={
              'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            }
            allowFullScreen={false}
            height='100%'
            src={`https://youtube.com/embed/${getYouTubeId(
              current.attributes?.url!
            )}`}
            title={current?.attributes?.name!}
            style={{ border: 0, minHeight: 500 }}
            width='100%'
          />
        ) : VIDEO_TYPES.includes(current.attributes?.ext!) ? (
          lightbox.photoIndex === index ? (
            <video
              controls
              autoPlay
              playsInline
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'fill' }}
            >
              <source
                src={getImageStorageUrl(current?.attributes?.url)}
                type='video/mp4'
              />
            </video>
          ) : null
        ) : (
          <a
            href={getImageStorageUrl(current?.attributes?.url)}
            target='_new'
            rel='noopener'
          >
            <RcIcon size='10x' icon={['fal', 'file-contract']} />
          </a>
        )}
      </Box>
    )
  }

  // t('shared.get-files', {count})
  return (
    <Stack direction='column' spacing={4} mb={4}>
      <SectionHeader title={props.title} subtitle={props.subtitle}>
        <RcButton
          disabled={images.length === 0 || downloading}
          onClick={handleDownload}
        >
          {some(images?.filter(it => it.attributes?.ext !== YOUTUBE)) ? (
            <RcTrans
              i18nKey={'shared.get-files'}
              tOptions={{
                count: images?.filter(it => it.attributes?.ext !== YOUTUBE)
                  .length,
                size: getFileSizeString(
                  images?.reduce(
                    (prev, curr) => prev + curr?.attributes?.size!,
                    0
                  )
                )
              }}
            />
          ) : (
            <RcTrans i18nKey={'shared.no-files-selected'} />
          )}
        </RcButton>
      </SectionHeader>

      <Stack direction='row' justifyContent='space-between'>
        <TextField
          inputRef={ref}
          fullWidth
          placeholder={t('shared.search-placeholder')}
          value={filter}
          onChange={ev => setFilter(ev.target.value)}
        />
      </Stack>
      <Box mt={6}>
        <Grid container direction='row' spacing={6}>
          {images.map((it: UploadFileEntity, idx: number) => {
            let title = it?.attributes?.name || ''
            let caption = it?.attributes?.caption || undefined
            const tags = []
            if (caption === title) {
              // Don't duplicate identical title/captions
              caption = undefined
            }
            if (it.attributes?.size) {
              tags.push({ label: getFileSizeString(it.attributes.size) })
            }
            if (it.attributes?.ext) {
              tags.push({ label: it.attributes.ext.replace('.', '') })
              // Remove extension from title if exists
              title = title.replace(it.attributes.ext, '')
            }
            return (
              <Grid key={it.id} item xs={12} sm={6}>
                <DiscoverCard
                  title={<Stack direction='row'>{title}</Stack>}
                  actions={
                    <Box ml={0.5} mt={-2}>
                      {it.attributes?.ext === YOUTUBE ? (
                        <RcIconButton
                          color='secondary'
                          icon={['fab', 'youtube']}
                          onClick={() =>
                            setLightbox({ isOpen: true, photoIndex: idx })
                          }
                        />
                      ) : (
                        <RcIconButton
                          color='secondary'
                          icon={['fal', 'arrow-alt-circle-down']}
                          onClick={() =>
                            download(it.attributes?.url!, it.attributes?.name!)
                          }
                        />
                      )}
                    </Box>
                  }
                  content={<></>}
                  headerImage={
                    it.attributes?.ext === YOUTUBE
                      ? `https://img.youtube.com/vi/${getYouTubeId(
                          it.attributes?.url!
                        )}/0.jpg`
                      : VIDEO_TYPES.includes(
                            it.attributes?.ext?.toLocaleLowerCase()!
                          ) ||
                          IMAGE_TYPES.includes(
                            it.attributes?.ext?.toLocaleLowerCase()!
                          )
                        ? getImagePath(it, ImageSize.Small)
                        : undefined
                  }
                  onClick={() => setLightbox({ isOpen: true, photoIndex: idx })}
                  primaryChips={tags}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>

      <ThemeProvider
        theme={theme =>
          deepmerge(theme, {
            palette: {
              text: {
                primary: TEXT_PALETTE.primary.dark
              }
            },
            components: {
              MuiIconButton: {
                styleOverrides: { root: { color: TEXT_PALETTE.primary.dark } }
              },
              MuiDrawer: {
                styleOverrides: {
                  paperAnchorBottom: {
                    background: '#111111'
                  }
                }
              }
            }
          } as Theme)
        }
      >
        <SwipeableDrawer
          disableSwipeToOpen
          anchor='bottom'
          open={lightbox.isOpen}
          onClose={() => setLightbox({ isOpen: false, photoIndex: 0 })}
          onOpen={() => setLightbox({ isOpen: true, photoIndex: 0 })}
        >
          <Grid
            container
            wrap='nowrap'
            direction='column'
            sx={{
              height: '100dvh'
            }}
          >
            <Grid item>
              <Toolbar>
                <Typography sx={{ flexGrow: 1 }}>
                  {images[photoIndex]?.attributes?.name}
                </Typography>

                <RcIconButton
                  icon={['fal', 'arrow-alt-circle-down']}
                  onClick={() =>
                    download(
                      images[photoIndex]?.attributes?.previewUrl!,
                      images[photoIndex]?.attributes?.name!
                    )
                  }
                  size='large'
                />
                <RcIconButton
                  icon={['fal', 'times']}
                  onClick={() => setLightbox({ isOpen: false, photoIndex: 0 })}
                  size='large'
                />
              </Toolbar>
            </Grid>

            <Grid item xs>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Hidden mdDown>
                  <RcIconButton
                    icon={['fal', 'chevron-circle-left']}
                    onClick={() => {
                      setLightbox(s => ({ ...s, photoIndex: s.photoIndex - 1 }))
                    }}
                    size='large'
                  />
                </Hidden>
                <VirtualizeSwipeableViews
                  enableMouseEvents
                  slideRenderer={slideRenderer}
                  index={lightbox.photoIndex}
                  onChangeIndex={index =>
                    setLightbox(s => ({ ...s, photoIndex: index }))
                  }
                />
                <Hidden mdDown>
                  <RcIconButton
                    icon={['fal', 'chevron-circle-right']}
                    onClick={() => {
                      setLightbox(s => ({ ...s, photoIndex: s.photoIndex + 1 }))
                    }}
                    size='large'
                  />
                </Hidden>
              </Stack>
            </Grid>

            <Grid item>
              <Toolbar>
                <Typography sx={{ flexGrow: 1 }}>
                  {images[photoIndex]?.attributes?.caption}
                </Typography>
              </Toolbar>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </ThemeProvider>
    </Stack>
  )
}
