import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CommunityDocument } from '@rallycry/api-suite-typescript/dist/models/CommunityDocument'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { isArray } from 'lodash-es'
import { Dispatch, SetStateAction, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { CompetitionSearchResultSlider } from './CompetitionSearchResultSlider'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { TranslationNamespace } from '@/core/translation'
import { useCommunity } from '@/entity/community/useCommunity'
import { useCommunityDocument } from '@/entity/community/useCommunityDocument'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const CompetitionFinder = () => {
  const isMobile = useIsMobile()
  const [community, setCommunity] = useState<CommunityDocument | null>(null)

  return (
    <Grid container direction='row' spacing={isMobile ? 5 : 20}>
      <Grid item xs={12} sm={6}>
        <Stack direction='column' spacing={10}>
          <Typography variant='h3'>
            <RcTrans
              i18nKey={'shared.competition-finder.find-competition-title'}
            />
          </Typography>
          <CommunityInfoSearch
            community={community}
            setCommunity={setCommunity}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CompetitionSearchResultSlider
          request={{
            stateNot: [CompetitionState.CONCLUDED, CompetitionState.INITIAL],
            restrictedToCommunities: community?.id ? [community?.id] : undefined
          }}
        />
      </Grid>
    </Grid>
  )
}

const CommunityInfoSearch = ({
  community,
  setCommunity
}: {
  community: CommunityDocument | null
  setCommunity: Dispatch<SetStateAction<CommunityDocument | null>>
}) => {
  const { imgCommunityAvatar } = useFeatures()
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 300)
  const {
    communities,
    query: { isValidating, size, setSize }
  } = useCommunityDocument({
    request: {
      q: debouncedQuery
    }
  })

  const defaultIconImageSrc = imgCommunityAvatar as string
  const { t } = useRcTranslation([TranslationNamespace.Community]) // ph

  return (
    <>
      <EntityAutocomplete
        isValidating={isValidating}
        options={communities}
        selected={community?.id ? [community?.id] : []}
        placeholder={t('shared.competition-finder.search-placeholder')}
        fallbackImage={defaultIconImageSrc}
        onSearch={q => setQuery(q)}
        onLoadOptions={() => setSize(size + 1)}
        onChange={async updated => {
          setCommunity(isArray(updated) ? null : updated)
          setQuery('')
        }}
      />
      {community ? (
        <RouteParamOverrideProvider
          context={{ communityId: community?.id?.toString() }}
        >
          <RcSuspense skipLoader>
            <CommunityDetailDisplay />
          </RcSuspense>
        </RouteParamOverrideProvider>
      ) : null}
    </>
  )
}

const CommunityDetailDisplay = () => {
  const { profile } = useCommunity()

  const location = profile?.location
  const description = profile?.description

  return (
    <Stack spacing={5}>
      {location ? (
        <Typography
          sx={{
            whiteSpace: 'pre-line'
          }}
        >
          {location}
        </Typography>
      ) : null}

      {description ? (
        <Typography
          sx={{
            whiteSpace: 'pre-line'
          }}
        >
          {description}
        </Typography>
      ) : null}
    </Stack>
  )
}
