import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import ManualTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { BracketKind } from '@rallycry/api-suite-typescript/dist/models/BracketKind'
import { CompetitionBracketCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketCreateCommand'
import { CompetitionBracketResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketResource'
import { CompetitionBracketSettingsCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsCreateCommand'
import { CompetitionBracketSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsResource'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { Form, Formik, FormikHelpers } from 'formik'
import { Autocomplete, Select, TextField } from 'formik-mui'
import { first, isEmpty, orderBy, some, uniq } from 'lodash-es'
import { useMemo, useState } from 'react'
import * as Yup from 'yup'
import { WinCondition } from '@rallycry/api-suite-typescript'
import { TeamsSeeding } from '../../teams/TeamsSeeding'
import { PreviewBracketAssignments } from './PreviewBracketAssignments'
import { keysOf } from '@/core/utils'
import { TranslationNamespace } from '@/core/translation'
import {
  CompetitionRoute,
  CompetitionSettingsRoute,
  RootRoute
} from '@/core/route-keys'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useApiError } from '@/core/hooks/useApiError'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useBrackets } from '@/entity/bracket/useBrackets'
import { useCompetitionTeamsIncludingIssues } from '@/entity/competition-team/useCompetitionTeams'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useSubmissionScores } from '@/entity/leaderboard/useSubmissionScores'
import { useBracket } from '@/entity/bracket/useBracket'
import { RcTrans } from '@/components/atoms/RcTrans'
import { AcceptableSubmit } from '@/components/molecules/input/AcceptableSubmit'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { LabeledSwitch } from '@/components/organisms/form/LabeledSwitch'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { SubmissionScoreDetails } from '@/components/pages/Administration/components/scores/SubmissionScoreDetails'
import {
  getBracketCount,
  getPlayerLimit,
  snakeAssignTeamsToGroups
} from '@/core/assignmentLogic'
import { LADDER_KINDS } from '../../_Competition'

export const SUPPORTED_EXTERNAL_NETWORKS = [
  NetworkKind.RIOT_VALORANT_AMERICAS,
  NetworkKind.RIOT_VALORANT_ASIA,
  NetworkKind.RIOT_VALORANT_EUROPE
] as NetworkKind[]
export const BracketCreateEditForm = ({
  bracket,
  settings,
  selectedTeams,
  onComplete,
  onReseed
}: {
  bracket?: CompetitionBracketResource
  settings?: CompetitionBracketSettingsResource
  selectedTeams?: number[]
  onComplete: () => any
  onReseed?: () => any
}) => {
  const { navTo } = useNavigation()
  const { competition, settings: competitionSettings } = useCompetition()
  const { teams, updateTeams } = useCompetitionTeamsIncludingIssues({
    paused: isEmpty(selectedTeams),
    request: { competitionId: competition?.id }
  })
  const { create, brackets } = useBrackets()
  const { update, updateBracketSettings, assignBracket } = useBracket()
  const { submissionScores } = useSubmissionScores()
  const { handle } = useApiError()
  const { getNetworkString } = useNetworkKinds()
  const [progress, setProgress] = useState(0)
  const { t } = useRcTranslation([TranslationNamespace.Competition])

  const sortedFilteredSelected = orderBy(
    teams.filter(it => selectedTeams?.includes(it.id!)),
    ['seed'],
    ['asc']
  )

  const bracketGroups = uniq(
    brackets
      ?.filter(it => !!it.disambiguatingDescription)
      .map(it => it.disambiguatingDescription) || []
  )

  const validation = Yup.object<
    CompetitionBracketCreateCommand & {
      playerLimit?: number
      bracketCount?: number
    }
  >({
    name: Yup.string().required(),
    disambiguatingDescription: Yup.string().nullable() as any,
    externalIdOrKey: Yup.string(),
    settings: Yup.object<
      CompetitionBracketSettingsCreateCommand & {
        playerLimit?: number
        ladderCutoff?: number
        ladderQualifier?: number
      }
    >({
      kind: Yup.mixed(),
      winCondition: Yup.mixed(),
      winConditionAmount: Yup.number().required(),
      holdThirdPlaceMatch: Yup.boolean(),
      doubleGrandFinals: Yup.boolean(),
      skipMatchChats: Yup.boolean(),
      submissionMax: Yup.number(),
      submissionCutoff: Yup.number(),
      submissionInquiry: Yup.string(),
      submissionModifyTimerMinutes: Yup.number(),
      submissionOrderAscending: Yup.boolean(),
      submissionScoreA: Yup.number(),
      submissionScoreB: Yup.number(),
      submissionScoreC: Yup.number(),
      submissionScoreD: Yup.number(),
      submissionScoreE: Yup.number(),
      submissionTopIncluded: Yup.number(),
      ladderCutoff: Yup.number(),
      ladderQualifier: Yup.number().min(
        1,
        t('competition:bracket.ladder-qualifier-min')
      )
    })
  })

  const isElimination = (kind: BracketKind) =>
    kind === BracketKind.SINGLE_ELIMINATION ||
    kind === BracketKind.DOUBLE_ELIMINATION

  const hasSetting = (
    key: keyof (CompetitionBracketSettingsCreateCommand & {
      ladderCutoff?: number
      ladderQualifier?: number
    }),
    kind: BracketKind
  ) => {
    const isSwiss = kind === BracketKind.SWISS
    const isLeaderboard = kind === BracketKind.LEADERBOARD_SUBMISSION
    const isLadder = LADDER_KINDS.includes(kind)

    const requiredNetwork = competitionSettings?.requiredNetwork
    const required2ndNetwork = competitionSettings?.required2ndNetwork

    const shouldDisplayExternalNetworks =
      SUPPORTED_EXTERNAL_NETWORKS.includes(required2ndNetwork!) ||
      SUPPORTED_EXTERNAL_NETWORKS.includes(requiredNetwork!)

    return {
      kind: true,
      winCondition: !isLeaderboard,
      winConditionAmount: !isLeaderboard,
      holdThirdPlaceMatch: kind === BracketKind.SINGLE_ELIMINATION,
      doubleGrandFinals: kind === BracketKind.DOUBLE_ELIMINATION,
      skipMatchChats: true,
      swissRounds: isSwiss,
      submissionMax: isLeaderboard,
      submissionCutoff: isLeaderboard,
      submissionInquiry: isLeaderboard,
      submissionModifyTimerMinutes: isLeaderboard,
      submissionOrderAscending: isLeaderboard,
      submissionScoreA: isLeaderboard,
      submissionScoreB: isLeaderboard,
      submissionScoreC: isLeaderboard,
      submissionScoreD: isLeaderboard,
      submissionScoreE: isLeaderboard,
      submissionTopIncluded: isLeaderboard,
      numericGameScore: !isLeaderboard,
      ladderCutoff: isLadder,
      ladderQualifier: isLadder,
      externalGameNetwork: !isLadder && shouldDisplayExternalNetworks,
      clearGameIntegrationNetwork: true,
      matchCheckInEnabled: true
    }[key]
  }

  const createBracket = async (
    values: CompetitionBracketCreateCommand & { playerLimit?: number },
    helpers: FormikHelpers<
      CompetitionBracketCreateCommand & { playerLimit?: number }
    >,
    teams?: number[]
  ) => {
    if (values?.settings?.kind !== BracketKind.UNKNOWN) {
      values.externalIdOrKey = undefined
    }

    if (!values?.settings?.externalGameNetwork) {
      values.settings.externalGameNetwork = undefined
      values.settings.clearGameIntegrationNetwork = true
    }

    keysOf(values?.settings).forEach(key => {
      const isUnsetSubmissionScore = () =>
        key.startsWith('submissionScore') && values?.settings[key] === 0

      if (
        !hasSetting(key, values?.settings.kind!) ||
        isUnsetSubmissionScore()
      ) {
        values.settings[key] = undefined
      }
    })

    try {
      const res = bracket?.id
        ? await Promise.allSettled([
            update(bracket?.id, values),
            updateBracketSettings(values.settings)
          ])
        : await create(values)

      if (teams && some(teams)) {
        await assignBracket(res.id, teams)
      }

      return res.id
    } catch (error) {
      await handle(error, { values, helpers })
    }
  }

  const submit = async (
    values: CompetitionBracketCreateCommand & {
      bracketCount?: number
      playerLimit?: number
    },
    helpers: FormikHelpers<
      CompetitionBracketCreateCommand & {
        playerLimit?: number
        bracketCount?: number
      }
    >
  ) => {
    const bracketCount = values.bracketCount || 1

    // group teams by snake assignment into brackets
    // or create a dummy group with no teams to support legacy flow
    const grouped = some(sortedFilteredSelected)
      ? snakeAssignTeamsToGroups(sortedFilteredSelected || [], bracketCount)
      : Array.from({ length: 1 }, () => [])

    setProgress(5)
    let firstId: number = 0
    // create bracket for each grouped result
    await Promise.all(
      grouped.map(async (bracketTeams, index) => {
        const bracketValues = {
          ...values,
          name: `${values.name}${
            grouped.length > 1
              ? ` ${String.fromCharCode(97 + index).toUpperCase()}`
              : ''
          }`
        }
        const id = await createBracket(
          bracketValues,
          helpers,
          bracketTeams.map(it => it.id!)
        )
        setProgress(progress => progress + 100 / bracketCount)
        if (index === 0) firstId = id
      })
    )

    firstId > 0 &&
      navTo({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Bracket,
        subId: firstId
      })

    onComplete()
  }

  const sortedScores = useMemo(
    () =>
      orderBy(
        submissionScores,
        ['name', 'optional', 'numberType', 'multiplier', 'mathSign'],
        ['asc', 'asc', 'desc', 'asc', 'desc']
      ),
    [submissionScores]
  )
  const scoreOptions = [
    <MenuItem value={0} key='none'>
      <RcTrans i18nKey='shared.none-selected' />
    </MenuItem>,
    ...(sortedScores?.map(score => (
      <MenuItem key={score.id!} value={score.id!}>
        <Stack direction='column'>
          <Typography>{score.name}</Typography>
          <Typography variant='subtitle1'>
            <SubmissionScoreDetails score={score} />
          </Typography>
        </Stack>
      </MenuItem>
    )) || [])
  ]

  const initial =
    bracket && settings
      ? ({
          ...bracket,
          settings: {
            ...settings,
            submissionScoreA: settings?.submissionScoreA?.id || 0,
            submissionScoreB: settings?.submissionScoreB?.id || 0,
            submissionScoreC: settings?.submissionScoreC?.id || 0,
            submissionScoreD: settings?.submissionScoreD?.id || 0,
            submissionScoreE: settings?.submissionScoreE?.id
          }
        } as CompetitionBracketCreateCommand)
      : null

  return (
    <Formik
      initialValues={
        (initial || {
          externalIdOrKey: '',
          name: '',
          disambiguatingDescription: null as any,
          playerLimit: selectedTeams?.length || 256,
          bracketCount: getBracketCount(selectedTeams, 256) || 1,
          settings: {
            kind: BracketKind.DOUBLE_ELIMINATION,
            winCondition: WinCondition.FIRST_TO,
            winConditionAmount: 2,
            holdThirdPlaceMatch: false,
            doubleGrandFinals: false,
            submissionInquiry: '',
            submissionMax: 3,
            submissionCutoff: 1,
            submissionModifyTimerMinutes: 10,
            submissionOrderAscending: false,
            submissionScoreA: first(submissionScores)?.id || 0,
            submissionScoreB: 0,
            submissionScoreC: 0,
            submissionScoreD: 0,
            submissionScoreE: 0,
            submissionTopIncluded: 1,
            numericGameScore: false,
            externalGameNetwork: '' as NetworkKind,
            clearGameIntegrationNetwork: false,
            ladderCutoff: 700,
            ladderQualifier: 10,
            matchCheckInEnabled: false
          }
        }) as CompetitionBracketCreateCommand & {
          playerLimit?: number
          bracketCount?: number
          settings: CompetitionBracketSettingsCreateCommand
        }
      }
      validationSchema={validation}
      onSubmit={submit}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Form id='bracket-create-form'>
          <Container maxWidth='xs'>
            {some(selectedTeams) && some(teams, entry => !entry.seed) ? (
              <Alert
                severity='info'
                variant='filled'
                sx={{ mb: 6 }}
                action={
                  <ModalTrigger
                    modalProps={{
                      noHeader: true,
                      forceDirtyCheck: true,
                      maxWidth: 'md'
                    }}
                    activation={handleOpen => (
                      <RcButton variant='outlined' onClick={handleOpen}>
                        <RcTrans i18nKey='competition:seed-label'>Seed</RcTrans>
                      </RcButton>
                    )}
                  >
                    {({ handleClose }) => (
                      <TeamsSeeding
                        selected={selectedTeams || []}
                        onComplete={async teams => {
                          await updateTeams(competition?.id!, teams)
                          onReseed?.()
                          handleClose()
                        }}
                        onCancel={handleClose}
                      />
                    )}
                  </ModalTrigger>
                }
              >
                <RcTrans i18nKey='competition:seed-desc'>
                  Some teams have not been seeded. (This is optional)
                </RcTrans>
              </Alert>
            ) : isEmpty(selectedTeams) && !bracket?.id ? (
              <Alert
                severity='info'
                variant='filled'
                sx={{ mb: 6 }}
                action={
                  <Button
                    variant='contained'
                    onClick={() =>
                      navTo({
                        root: RootRoute.Competition,
                        rootId: competition?.id,
                        subRoute: CompetitionRoute.Settings,
                        subId: CompetitionSettingsRoute.ManageTeams
                      })
                    }
                  >
                    <RcTrans i18nKey='competition:try-label'>
                      Try it out!
                    </RcTrans>
                  </Button>
                }
              >
                There&apos;s now a better way to create multiple brackets and
                auto-assign from the Admin Teams view.
              </Alert>
            ) : null}
            <Grid container direction='row' spacing={5}>
              {some(selectedTeams) ? (
                <>
                  <Grid item xs={6}>
                    <LabeledField
                      component={ManualTextField}
                      value={values.playerLimit}
                      onChange={(e: any) => {
                        setFieldValue('playerLimit', parseInt(e.target.value))
                        setFieldValue(
                          'bracketCount',
                          getBracketCount(
                            selectedTeams,
                            parseInt(e.target.value)
                          )
                        )
                      }}
                      type='number'
                      label={
                        <RcTrans
                          i18nKey={'competition:bracket.player-limit-label'}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledField
                      component={ManualTextField}
                      value={values.bracketCount}
                      onChange={(e: any) => {
                        setFieldValue('bracketCount', parseInt(e.target.value))
                        setFieldValue(
                          'playerLimit',
                          getPlayerLimit(
                            selectedTeams,
                            parseInt(e.target.value)
                          )
                        )
                      }}
                      type='number'
                      label={
                        <RcTrans
                          i18nKey={'competition:bracket.bracket-count-label'}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <Typography variant='subtitle2' color='text.secondary'>
                      {(values.bracketCount || 1) > 1 ? (
                        <RcTrans
                          i18nKey={
                            'competition:bracket.bracket-creation-multiple'
                          }
                          tOptions={{
                            selectedCount: selectedTeams?.length,
                            bracketCount: values.bracketCount
                          }}
                        />
                      ) : (
                        <RcTrans
                          i18nKey={
                            'competition:bracket.bracket-creation-single'
                          }
                          tOptions={{
                            selectedCount: selectedTeams?.length
                          }}
                        />
                      )}
                    </Typography>
                    <ModalTrigger
                      modalProps={{ skipDirtyCheck: true }}
                      activation={handleOpen => (
                        <Typography variant='subtitle2'>
                          <Button size='small' onClick={handleOpen}>
                            <RcTrans i18nKey='competition:bracket.preview-assignments' />
                          </Button>
                        </Typography>
                      )}
                    >
                      {() => (
                        <PreviewBracketAssignments
                          teams={sortedFilteredSelected}
                          bracketCount={values.bracketCount}
                          bracketName={values.name}
                        />
                      )}
                    </ModalTrigger>
                  </Grid>
                </>
              ) : null}
              <Grid item>
                <LabeledField
                  component={Select}
                  onChange={(e: any) => {
                    if (e.target.value === BracketKind.ROUND_ROBIN) {
                      setFieldValue('settings.skipMatchChats', true)
                    } else {
                      setFieldValue('settings.skipMatchChats', false)
                    }
                    const isChanging =
                      isElimination(e.target.value) !==
                      isElimination(values.settings.kind!)

                    setFieldValue('settings.kind', e.target.value)
                    if (!isChanging) return

                    if (isElimination(e.target.value)) {
                      setFieldValue(
                        'settings.winCondition',
                        WinCondition.FIRST_TO
                      )
                      setFieldValue('settings.winConditionAmount', 2)
                    }
                  }}
                  name='settings.kind'
                  variant='outlined'
                  label='Type'
                  disabled={bracket && settings}
                  info={
                    bracket && settings ? (
                      <RcTrans
                        i18nKey={'competition:bracket.kind-disabled-for-edit'}
                      />
                    ) : undefined
                  }
                >
                  <MenuItem value={BracketKind.SINGLE_ELIMINATION}>
                    <RcTrans i18nKey={'BracketKind.SINGLE_ELIMINATION'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.DOUBLE_ELIMINATION}>
                    <RcTrans i18nKey={'BracketKind.DOUBLE_ELIMINATION'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.SWISS}>
                    <RcTrans i18nKey={'BracketKind.SWISS'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.SWISS_LADDER}>
                    <RcTrans i18nKey={'BracketKind.SWISS_LADDER'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.LADDER}>
                    <RcTrans i18nKey={'BracketKind.LADDER'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.LEADERBOARD_SUBMISSION}>
                    <RcTrans i18nKey={'BracketKind.LEADERBOARD_SUBMISSION'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.ROUND_ROBIN}>
                    <RcTrans i18nKey={'BracketKind.ROUND_ROBIN'} />
                  </MenuItem>
                  <MenuItem value={BracketKind.CUSTOM}>
                    <RcTrans i18nKey={'BracketKind.CUSTOM'} />
                  </MenuItem>
                </LabeledField>
              </Grid>

              <Grid item xs={12}>
                <LabeledField
                  component={Autocomplete}
                  freeSolo
                  options={bracketGroups}
                  name='disambiguatingDescription'
                  renderInput={(params: any) => (
                    <ManualTextField
                      {...params}
                      value={values.disambiguatingDescription}
                      onChange={(e: any) =>
                        setFieldValue(
                          'disambiguatingDescription',
                          e.target.value
                        )
                      }
                      error={
                        touched['disambiguatingDescription'] &&
                        !!errors['disambiguatingDescription']
                      }
                      helperText={errors['disambiguatingDescription']}
                    />
                  )}
                  label={
                    <RcTrans
                      i18nKey={
                        'competition:settings.disambiguating-description-label'
                      }
                    />
                  }
                  info={
                    <RcTrans
                      i18nKey={
                        'competition:bracket.disambiguating-description-tooltip'
                      }
                    />
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <LabeledField
                  component={TextField}
                  name='name'
                  type='text'
                  variant='outlined'
                  required
                  label={
                    <RcTrans
                      i18nKey={'competition:bracket.bracket-name-label'}
                    />
                  }
                  info={
                    <RcTrans
                      i18nKey={'competition:bracket.bracket-name-tooltip'}
                    />
                  }
                  fullWidth
                />
              </Grid>

              {values.settings?.kind === BracketKind.UNKNOWN && (
                <Grid item xs={12}>
                  <LabeledField
                    component={TextField}
                    name='externalIdOrKey'
                    type='text'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.external-id-or-key-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.external-id-or-key-tooltip'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              )}

              {hasSetting('winCondition', values.settings.kind!) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={Select}
                    name='settings.winCondition'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.win-condition-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.win-condition-tooltip'}
                      />
                    }
                    fullWidth
                  >
                    <MenuItem
                      value={WinCondition.BEST_OF}
                      disabled={isElimination(values.settings.kind!)}
                    >
                      <RcTrans i18nKey={'competition:win-condition.BEST_OF'} />
                    </MenuItem>
                    <MenuItem value={WinCondition.FIRST_TO}>
                      <RcTrans i18nKey={'competition:win-condition.FIRST_TO'} />
                    </MenuItem>
                  </LabeledField>
                </Grid>
              ) : null}

              {hasSetting('winConditionAmount', values.settings.kind!) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={TextField}
                    name='settings.winConditionAmount'
                    type='number'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.win-condition-amount-label'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting('matchCheckInEnabled', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.matchCheckInEnabled'
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.tooltip.match-checkins'}
                      />
                    }
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.match-checkins-label'}
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('numericGameScore', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.numericGameScore'
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.tooltip.numeric-score'}
                      />
                    }
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.numeric-game-score-label'}
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('holdThirdPlaceMatch', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.holdThirdPlaceMatch'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.hold-third-place-match-label'
                        }
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.tooltip.hold-third-place'}
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('doubleGrandFinals', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.doubleGrandFinals'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.double-grand-finals-label'
                        }
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.tooltip.double-grand-finals'
                        }
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('skipMatchChats', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.skipMatchChats'
                    inverse
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.skip-match-chats-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.tooltip.skip-match-chats'}
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('submissionInquiry', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={TextField}
                    name='settings.submissionInquiry'
                    type='text'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-inquiry-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-inquiry-tooltip'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting('externalGameNetwork', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={Select}
                    name='settings.externalGameNetwork'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.external-game-network-label'
                        }
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.external-game-network-tooltip'
                        }
                      />
                    }
                    fullWidth
                  >
                    <MenuItem value=''>
                      <RcTrans i18nKey='shared.none-selected' />
                    </MenuItem>
                    {Object.values(NetworkKind)
                      .filter(it => SUPPORTED_EXTERNAL_NETWORKS.includes(it))
                      .map(it => (
                        <MenuItem key={it} value={it}>
                          {getNetworkString(it)}
                        </MenuItem>
                      ))}
                  </LabeledField>
                </Grid>
              ) : null}

              {hasSetting('submissionMax', values.settings.kind!) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={TextField}
                    name='settings.submissionMax'
                    type='number'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-max-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-max-tooltip'}
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting('submissionCutoff', values.settings.kind!) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={TextField}
                    name='settings.submissionCutoff'
                    type='number'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-cutoff-label'}
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-cutoff-tooltip'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting(
                'submissionModifyTimerMinutes',
                values.settings.kind!
              ) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={TextField}
                    name='settings.submissionModifyTimerMinutes'
                    type='number'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-timer-minutes-label'
                        }
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-timer-minutes-tooltip'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting('submissionTopIncluded', values.settings.kind!) ? (
                <Grid item xs={6}>
                  <LabeledField
                    component={TextField}
                    name='settings.submissionTopIncluded'
                    type='number'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-top-included-label'
                        }
                      />
                    }
                    info={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submission-top-included-tooltip'
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              ) : null}

              {hasSetting('submissionOrderAscending', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledSwitch
                    name='settings.submissionOrderAscending'
                    label={
                      <RcTrans
                        i18nKey={
                          'competition:bracket.submisison-order-ascending-label'
                        }
                      />
                    }
                  />
                </Grid>
              ) : null}

              {hasSetting('submissionScoreA', values.settings.kind!) ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <LabeledField
                      component={Select}
                      name='settings.submissionScoreA'
                      variant='outlined'
                      label={
                        <RcTrans
                          i18nKey={
                            'competition:bracket.submission-score-a-label'
                          }
                        />
                      }
                      fullWidth
                    >
                      {scoreOptions}
                    </LabeledField>
                  </Grid>
                </>
              ) : null}

              {hasSetting('submissionScoreB', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={Select}
                    name='settings.submissionScoreB'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-score-b-label'}
                      />
                    }
                    fullWidth
                  >
                    {scoreOptions}
                  </LabeledField>
                </Grid>
              ) : null}

              {hasSetting('submissionScoreC', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={Select}
                    name='settings.submissionScoreC'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-score-c-label'}
                      />
                    }
                    fullWidth
                  >
                    {scoreOptions}
                  </LabeledField>
                </Grid>
              ) : null}

              {hasSetting('submissionScoreD', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={Select}
                    name='settings.submissionScoreD'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-score-d-label'}
                      />
                    }
                    fullWidth
                  >
                    {scoreOptions}
                  </LabeledField>
                </Grid>
              ) : null}

              {hasSetting('submissionScoreE', values.settings.kind!) ? (
                <Grid item xs={12}>
                  <LabeledField
                    component={Select}
                    name='settings.submissionScoreE'
                    variant='outlined'
                    label={
                      <RcTrans
                        i18nKey={'competition:bracket.submission-score-e-label'}
                      />
                    }
                    fullWidth
                  >
                    {scoreOptions}
                  </LabeledField>
                </Grid>
              ) : null}
            </Grid>
          </Container>

          <ModalConfiguration
            title={
              <RcTrans i18nKey='competition:bracket.create-bracket-title' />
            }
          >
            {progress > 0 ? (
              <LinearProgress
                variant='determinate'
                value={progress}
                sx={{ borderRadius: 25, height: 35 }}
              />
            ) : (
              <AcceptableSubmit />
            )}
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
