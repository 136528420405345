import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { find, isEmpty, some } from 'lodash-es'
import { assign, createMachine } from 'xstate'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { ErrorCode } from '@/core/error-code'
import { IssueErrorType } from '@/core/utils'

export interface JoinFlowContext {
  user?: UserResource | null

  orgCommunities?: CommunityResource[]
  isFreeAgent?: boolean
  isSoloCompetition?: boolean

  skipHandles?: boolean

  // pre-determined team to join
  selectedTeam?: number

  // members selected from existing team
  selectedMembers?: number[]

  // team properties
  image?: string
  alternateName?: string
  teamRepresentingOptional?: boolean
  joinRestriction?: JoinRestriction
  lookingForMore?: boolean
  nonParticipantLeader?: boolean
  representing?: number
  network?: NetworkKind

  // to disable control if user is already a participant of a team
  nonParticipantLeaderLocked?: boolean

  // free agent properties
  message?: string

  error?: IssueErrorType
}

export type JoinFlowActions =
  | { type: 'SET_REVIEWED' }
  | { type: 'CONFIRM_HANDLES' }
  | {
      type: 'SET_COMMUNITY'
      image: string
      representing: number
      alternateName: string
      nonParticipantLeader: boolean
    }
  | {
      type: 'SET_MEMBERS'
      selectedMembers: number[]
    }
  | {
      type: 'SET_TEAM'
      image: string
      representing: number
      alternateName: string
      joinRestriction: JoinRestriction
      lookingForMore: boolean
      nonParticipantLeader: boolean
    }
  | { type: 'SET_HANDLE' }
  | { type: 'BACK' }

export const joinFlowMachine = createMachine<JoinFlowContext, JoinFlowActions>(
  {
    predictableActionArguments: true,
    id: 'JoinFlow',
    initial: 'reviewRules',
    context: {
      alternateName: '',
      lookingForMore: true,
      nonParticipantLeader: false,
      joinRestriction: JoinRestriction.NORMAL
    },
    states: {
      reviewRules: {
        always: [
          { cond: 'findCommunity', target: 'findCommunity' },
          { cond: 'skipHandles', target: 'setCommunity' }
        ],
        on: { SET_REVIEWED: 'confirmHandles' }
      },
      // step to allow user to confirm handles before joining
      confirmHandles: {
        on: {
          BACK: { target: 'reviewRules' },
          CONFIRM_HANDLES: { target: 'setCommunity' }
        }
      },
      findCommunity: {},
      // allow user to pick an existing squad community
      setCommunity: {
        always: [
          // skip if user is joining an existing team or solo competition without commmunity requirement
          {
            cond: 'isAutoJoin',
            target: 'setTeam.joining',
            actions: assign(context => ({
              alternateName: context.user?.name,
              lookingForMore: undefined
            }))
          },
          // skip if user is joining a solo competition where community is required
          {
            cond: 'isSoloCompetition',
            target: 'setTeam',
            actions: assign(context => ({
              alternateName: context.user?.name,
              lookingForMore: undefined
            }))
          },
          // skip is user is a free agent
          { cond: 'isFreeAgent', target: 'setTeam' },
          // skip if user has no community team
          { cond: 'noCommunityTeam', target: 'setTeam' }
        ],
        on: {
          BACK: [
            { cond: 'skipHandles', target: 'reviewRules' },
            { target: 'reviewRules' }
          ],
          SET_COMMUNITY: [
            {
              cond: (_, event) => !!event.representing,
              actions: assign((_, event) => ({
                image: event.image,
                representing: event.representing,
                alternateName: event.alternateName,
                nonParticipantLeader: event.nonParticipantLeader,
                lookingForMore: false,
                joinRestriction: JoinRestriction.INVITE_ONLY
              })),
              target: 'setTeam.joining'
            },
            {
              actions: assign((_, event) => ({
                representing: event.representing,
                alternateName: event.alternateName,
                nonParticipantLeader: event.nonParticipantLeader
              })),
              target: 'setTeam'
            }
          ]
        }
      },
      // allow user to pick team members
      setMembers: {
        on: {
          BACK: { target: 'setCommunity' },
          SET_MEMBERS: {
            actions: assign((_, event) => ({
              selectedMembers: event.selectedMembers
            })),
            target: 'setTeam.joining'
          }
        }
      },
      // allow user to set team details before creation. not shown if user is joining with existing team.
      setTeam: {
        initial: 'initial',
        states: {
          // step to allow user to set team preferences
          initial: {},
          // handles service call to join or create the team (implementation provided by useMachine's services)
          joining: {
            invoke: {
              src: 'join',
              onDone: {
                target: '#JoinFlow.success'
              },
              onError: [
                {
                  cond: 'hasMissingNetwork',
                  actions: assign((_, event) => ({
                    network: find(
                      event.data.issues,
                      it => it.code === ErrorCode.MissingRequiredNetwork
                    )?.context?.missingRequiredNetwork
                  })),
                  target: '#JoinFlow.setHandle'
                },
                {
                  // we dont have a lot of options to handle errors other than "missing handle" for autojoining teams
                  cond: 'isAutoJoin',
                  actions: assign((_, event) => ({
                    error: event.data
                  })),
                  target: '#JoinFlow.failure'
                },
                {
                  actions: assign((_, event) => ({
                    error: event.data
                  })),
                  target: 'initial'
                }
              ]
            }
          }
        },
        on: {
          BACK: [
            { cond: 'hasSelectedTeam', target: 'reviewRules' },
            { cond: 'isFreeAgent', target: 'reviewRules' },
            { cond: 'noCommunityTeam', target: 'reviewRules' },
            { cond: 'isSoloCompetition', target: 'reviewRules' },
            { target: 'setCommunity' }
          ],
          SET_TEAM: {
            actions: assign((_, event) => ({
              error: undefined,
              ...event
            })),
            target: '.joining'
          }
        }
      },
      setHandle: {
        initial: 'initial',
        states: {
          initial: {},
          // handles service call to join or create the team (implementation provided by useMachine's services)
          joining: {
            invoke: {
              src: 'join',
              onDone: {
                target: '#JoinFlow.success'
              },
              onError: [
                {
                  // more missing networks! loop back to initial of this same step.
                  cond: 'hasMissingNetwork',
                  actions: assign((_, event) => ({
                    network: find(
                      event.data.issues,
                      it => it.code === ErrorCode.MissingRequiredNetwork
                    )?.context?.missingRequiredNetwork
                  })),
                  target: 'initial'
                },
                {
                  // we dont have a lot of options to handle errors other than "missing handle" for autojoining teams
                  cond: 'isAutoJoin',
                  actions: assign((_, event) => ({
                    error: event.data
                  })),
                  target: '#JoinFlow.failure'
                },
                {
                  // go back to create team if a new error appeared after adding handles
                  actions: assign((_, event) => ({
                    error: event.data
                  })),
                  target: '#JoinFlow.setTeam.initial'
                }
              ]
            }
          }
        },
        on: {
          BACK: [{ target: 'reviewRules' }],
          SET_HANDLE: { target: '.joining' }
        }
      },
      // final success calls completed function provided by useMachine's services
      success: { invoke: { src: 'completed' } },
      // step to allow user to cancel the flow for impersonated creates
      cancel: { invoke: { src: 'cancel' } },
      // step for error display for unhandled errors when joining existing team
      failure: {}
    }
  },
  {
    guards: {
      skipHandles: context => !!context.skipHandles,
      findCommunity: context =>
        !context.teamRepresentingOptional &&
        isEmpty(
          context.orgCommunities?.filter(it => it.kind !== CommunityKind.TEAM)
        ),
      noCommunityTeam: context =>
        isEmpty(
          context.orgCommunities?.filter(it => it.kind === CommunityKind.TEAM)
        ),
      hasSelectedTeam: context => !!context.selectedTeam,
      isFreeAgent: context => !!context.isFreeAgent,
      isSoloCompetition: context => !!context.isSoloCompetition,
      isAutoJoin: context =>
        !!context.selectedTeam ||
        (!!context.isSoloCompetition && !!context.teamRepresentingOptional),
      hasMissingNetwork: (_, event: any) =>
        some(
          event.data.issues,
          it => it.code === ErrorCode.MissingRequiredNetwork
        )
    }
  }
)
