import Alert from '@mui/material/Alert'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCompetitionTicket } from '@/entity/competition/useCompetitionTicket'

// show alert for existing ticket with option to close and create new
export const ExistingCompetitionTicket = ({
  ticketId,
  description,
  onResolved
}: {
  ticketId: number
  description: string
  onResolved: (chatId?: string) => void
}) => {
  const { ticket, create, close } = useCompetitionTicket({
    idOrKey: ticketId
  })

  return (
    <Alert severity='warning'>
      <RcTrans i18nKey='competition:support.existing'>
        ticket already open
        <Link
          onClick={() => {
            onResolved(ticket?.socialId)
          }}
        >
          show
        </Link>
        <Link
          onClick={async () => {
            await close(ticketId)
            const res = await create(description)
            onResolved(res.socialId)
          }}
        >
          close
        </Link>
      </RcTrans>
    </Alert>
  )
}
