import { RcTrans } from '@/components/atoms/RcTrans'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { usePage } from '@/components/providers/site/PageProvider'
import { useCompetitionTicket } from '@/entity/competition/useCompetitionTicket'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { useEffect } from 'react'
import { ExistingCompetitionTicket } from './ExistingCompetitionTicket'

// inserts the competition support option into contact flow
export const useCompetitionTicketFlow = ({
  competition
}: {
  competition?: CompetitionResource
}) => {
  const { setContact } = usePage()
  const { featChatSupport } = useFeatures()
  const { create } = useCompetitionTicket()

  useEffect(() => {
    if (!featChatSupport) return
    setContact({
      title: (
        <RcTrans
          i18nKey='competition:support.title'
          tOptions={{ name: competition?.name }}
        />
      ),
      subtitle: (
        <RcTrans
          i18nKey='competition:support.subtitle'
          tOptions={{ name: competition?.name }}
        />
      ),
      entity: competition,
      action: async (description: string) => {
        const res = await create(description)
        return { chatId: res.socialId }
      },
      existing: (
        ticketId: number,
        description: string,
        onResolved: (chatId?: string) => void
      ) => (
        <ExistingCompetitionTicket
          ticketId={ticketId}
          description={description}
          onResolved={onResolved}
        />
      )
    })
    //clear option on unmount
    return () => setContact(undefined)
  }, [featChatSupport, competition, create, setContact])
}
