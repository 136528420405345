import { CompetitionCheckInControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionCheckInControllerApi'
import { CompetitionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionControllerApi'
import { CompetitionEntryControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { CompetitionEntryCopyCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryCopyCommand'
import { CompetitionEntryCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryCreateCommand'
import { EmailParticipantsCommand } from '@rallycry/api-suite-typescript/dist/models/EmailParticipantsCommand'
import {
  CommonDuration,
  CompetitionEntryDocumentControllerApi,
  CompetitionEntryInviteControllerApi
} from '@rallycry/api-suite-typescript'
import moment from 'moment-timezone'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionActions = () => {
  const competitionId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta()
  const { ctrl: competitionCtrl } = useController(CompetitionControllerApi)
  const { ctrl: entryCtrl } = useController(CompetitionEntryControllerApi)
  const { ctrl: entryInviteCtrl } = useController(
    CompetitionEntryInviteControllerApi
  )
  const { ctrl: entryDocCtrl } = useController(
    CompetitionEntryDocumentControllerApi
  )
  const { ctrl: checkinCtrl } = useController(CompetitionCheckInControllerApi)

  const checkin = async (
    eventId: number,
    entryId: number,
    dismissed?: boolean
  ) => {
    await checkinCtrl().saveCompetitionCheckIn({
      eventId,
      competitionCheckInSaveCommand: { entryId, dismissed }
    })
    await meta.mutate()
  }

  const copyCompetitionTeam = async (
    id: number,
    cmd: CompetitionEntryCopyCommand
  ) => {
    await entryCtrl().copyCompetitionEntry({
      id,
      competitionEntryCopyCommand: cmd
    })
  }

  const createTeam = async (
    competitionId: number,
    cmd: CompetitionEntryCreateCommand
  ) => {
    const res = await entryCtrl().createCompetitionEntry({
      competitionId,
      competitionEntryCreateCommand: cmd
    })
    await meta.mutate()
    return res
  }

  const emailParticipants = async (cmd: EmailParticipantsCommand) => {
    await competitionCtrl().emailCompetitionParticipants({
      id: competitionId,
      emailParticipantsCommand: cmd
    })
  }

  const reindexTeam = async (entryId: number) => {
    await entryDocCtrl().indexCompetitionEntry({ id: entryId })
  }

  return {
    checkin,
    copyCompetitionTeam,
    createTeam,
    emailParticipants,
    reindexTeam
  }
}
