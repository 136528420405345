import { CompetitionFormControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionFormControllerApi'
import { FormAnswerControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormAnswerControllerApi'
import { FormResponseControllerApi } from '@rallycry/api-suite-typescript/dist/apis/FormResponseControllerApi'
import { FormAnswerCreateCommand } from '@rallycry/api-suite-typescript/dist/models/FormAnswerCreateCommand'
import { FormAnswerUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/FormAnswerUpdateCommand'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

const EXPANSION =
  'source{event{competition{game,profile}}},form{_links,sections{questions{options}}},answers{questions,_links},user'
export const useFormResponse = (entityOptions?: EntityOptions) => {
  const responseId = useParsedParam('responseId')
  const { ctrl } = useController(FormResponseControllerApi)
  const { ctrl: competitionCtrl } = useController(CompetitionFormControllerApi)
  const { ctrl: answerCtrl } = useController(FormAnswerControllerApi)
  const { read: meta } = useCompetitionMeta()

  const rcea = window.sessionStorage.getItem('rcea') || ''

  const idOrKey = Number(entityOptions?.idOrKey || responseId)
  const entity = useReadEntity({
    key: 'useFormResponse',
    expand: EXPANSION,
    persist: true,
    ...entityOptions,
    idOrKey,
    read: req => ctrl({ rcea }).readFormResponse(req),
    create: req => ctrl().createFormResponse(req)
  })

  const createFormAnswer = async (cmd: FormAnswerCreateCommand) => {
    if (cmd.complete === undefined) {
      // convention: always set complete true on creates, if not defined
      cmd.complete = true
    }

    await answerCtrl({ rcea }).saveFormAnswer({
      responseId: idOrKey,
      formAnswerCreateCommand: cmd
    })

    await entity.read.mutate()
  }

  const updateFormAnswer = async (
    answerId: number,
    cmd: FormAnswerUpdateCommand
  ) => {
    if (cmd.complete === undefined) {
      // convention: always set complete true on updates if not defined
      cmd.complete = true
    }

    await answerCtrl({ rcea }).updateFormAnswer({
      id: answerId,
      formAnswerUpdateCommand: cmd,
      expand: ''
    })

    await entity.read.mutate()
  }

  const removeFormAnswer = async (answerId: number) => {
    await answerCtrl({ rcea }).removeFormAnswer({
      id: answerId
    })
    await entity.read.mutate()
  }

  const createCompetitionFormResponse = async ({
    competitionFormId
  }: {
    competitionFormId: number
  }) => {
    const res = await competitionCtrl().createCompetitionFormResponse({
      id: competitionFormId,
      formResponseCreateCommand: {}
    })
    await meta.mutate()
    return res
  }

  const completeFormResponse = async () => {
    await ctrl({ rcea }).submitFormResponse({
      id: idOrKey
    })
    await meta.mutate(d => ({ ...d, root: d?.root! + 1 }), false)
    await entity.read.mutate()
  }

  const getAuthenticatedLink = async (id: number) =>
    answerCtrl().viewFormAnswerEmailAuthUrl({ id })

  const answers = entity.read.data?._expanded?.formAnswer

  return {
    ...entity,
    response: entity.read.data,
    answers,
    createFormAnswer,
    updateFormAnswer,
    removeFormAnswer,
    createCompetitionFormResponse,
    completeFormResponse,
    getAuthenticatedLink
  }
}
