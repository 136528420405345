import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import React, { useEffect, useState } from 'react'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import {
  CompetitionJoinState,
  useCompetitionJoinState
} from './useCompetitionJoinState'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useCompetitionTeamSelfApplications } from '@/entity/competition-team/useCompetitionTeamSelfApplications'
import { useCompetitionTeamSelfInvitations } from '@/entity/competition-team/useCompetitionTeamSelfInvitations'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { CompetitionJoinModal } from '@/flows/Competition/JoinFlowXstate/JoinModal'
import { useNavigation } from '@/core/hooks/useNavigation'
import { find } from 'lodash-es'

export interface TeamJoinButtonProps {
  team?: CompetitionEntryResource
  condensed?: boolean
  rosterHasRoom?: boolean
}

export const TeamJoinButton: React.FC<TeamJoinButtonProps> = ({
  team,
  condensed,
  rosterHasRoom
}) => {
  const state = useCompetitionJoinState()
  const { navTo } = useNavigation()
  const { account } = useUserAccount()
  const { competition, settings } = useCompetition()
  const { involvement, isPlayer, isCoach } = useCompetitionParticipant()
  const { application, withdraw } = useCompetitionTeamSelfApplications({
    idOrKey: team?.id
  })
  const { invitations } = useCompetitionTeamSelfInvitations()
  const { orgCommunities } = useUserSelfOrgCommunities()

  const [buttonState, setButtonState] = useState<
    'joined' | 'invited' | 'join' | 'view' | 'pending' | 'unset'
  >('unset')

  const applicationId = application?.id
  const onAnyTeam = isPlayer() || isCoach()
  const onThisTeam = find(involvement, it => it.entry?.id === team?.id)

  useEffect(() => {
    const isInCommunity = orgCommunities.find(
      c => c?.id === team?.representing?.id
    )
    const teamValid = settings?.representSameCommunity ? isInCommunity : true
    const teamHasRoom = team?._membersTotalElements! < settings?.teamSizeMax!

    const canJoin =
      !onAnyTeam &&
      teamValid &&
      (teamHasRoom || rosterHasRoom) &&
      [CompetitionJoinState.Eligible, CompetitionJoinState.Interested].includes(
        state
      ) &&
      team?.joinRestriction !== JoinRestriction.INVITE_ONLY

    if (onThisTeam) {
      setButtonState('joined')
    } else if (
      !onAnyTeam &&
      invitations.find(
        it => it?.entry?.id === team?.id && it.recipient?.id === account?.id
      )
    ) {
      setButtonState('invited')
    } else if (!onAnyTeam && applicationId) {
      setButtonState('pending')
    } else if (canJoin) {
      setButtonState('join')
    }
  }, [
    account,
    orgCommunities,
    settings,
    team,
    applicationId,
    invitations,
    rosterHasRoom,
    state,
    onAnyTeam,
    onThisTeam
  ])

  const viewTeam = () => {
    navTo({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Team,
      subId: team?.id
    })
  }

  return {
    joined: () => <></>,
    invited: () => (
      <RcButton size={condensed ? 'small' : 'medium'} variant='outlined'>
        <RcTrans i18nKey='competition:team.invited-button' />
      </RcButton>
    ),
    join: () => (
      <CompetitionJoinModal
        buttonSize={condensed ? 'small' : 'medium'}
        selectedTeam={team?.id}
      />
    ),
    pending: () => (
      <ConfirmingButton
        size={condensed ? 'small' : 'medium'}
        message={
          <RcTrans i18nKey='competition:applications.withdraw-confirmation' />
        }
        buttonName={<RcTrans i18nKey='shared.withdraw-label' />}
        onClick={() => withdraw()}
        variant='outlined'
      >
        <RcTrans i18nKey='competition:team.pending-button' />
      </ConfirmingButton>
    ),
    view: () => (
      <RcIconButton onClick={viewTeam} icon={['fal', 'chevron-right']} />
    ),
    unset: () => null
  }[buttonState]()
}
