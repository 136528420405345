import { CompetitionEntryControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { CompetitionParticipantControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionParticipantControllerApi'
import { CompetitionEntryCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryCreateCommand'
import { EntityOptions } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useCompetitionUserView } from './useCompetitionUserView'
import { useCompetitionInvolvement } from './useCompetitionInvolvement'
import {
  CompetitionTeamsRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useCompetition } from './useCompetition'

export const useCompetitionParticipant = (options?: EntityOptions) => {
  const parsedId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta(options)
  const { requiredNetworks } = useCompetition()
  const { involvement } = useCompetitionInvolvement(options)
  const {
    read: { mutate: mutateAll }
  } = useCompetitionUserView()
  const { ctrl } = useController(CompetitionParticipantControllerApi)
  const { ctrl: entryCtrl } = useController(CompetitionEntryControllerApi)
  const { account } = useUserAccount()
  const { navTo } = useNavigation()

  const competitionId = Number(options?.idOrKey) || parsedId

  const createTeam = async (
    competitionId: number,
    cmd: CompetitionEntryCreateCommand,
    shouldNavigate?: boolean
  ) => {
    const res = await entryCtrl({
      skipErrorDisplay: true
    }).createCompetitionEntry({
      competitionId,
      competitionEntryCreateCommand: {
        ...cmd,
        authorizedNetworks: requiredNetworks
      }
    })
    shouldNavigate &&
      navTo({
        root: RootRoute.Competition,
        rootId: competitionId,
        subRoute: CompetitionRoute.Team,
        subId: res.id,
        query: 'scrollTo=true'
      })
    await Promise.allSettled([meta.mutate(), mutateAll()])
    return res
  }

  const joinSolo = async ({
    competitionId,
    userId,
    description,
    representingOnCreate
  }: {
    competitionId: number
    userId: number
    description?: string
    representingOnCreate?: number[]
  }) => {
    const res = await ctrl({
      skipErrorDisplay: true,
      expectedStatuses: [422]
    }).createCompetitionParticipant({
      competitionId,
      competitionParticipantCreateCommand: {
        participant: userId,
        description,
        representingOnCreate,
        authorizedNetworks: requiredNetworks
      }
    })
    navTo({
      root: RootRoute.Competition,
      rootId: competitionId,
      subRoute: CompetitionRoute.Team,
      subId: CompetitionTeamsRoute.All
    })
    await Promise.allSettled([meta.mutate(), mutateAll()])
    return res
  }

  const removeSolo = async () => {
    const freeAgent = involvement?.find(it => !it.entry)
    if (!freeAgent?.id) return
    await ctrl().removeCompetitionParticipant({
      id: freeAgent?.id
    })
    Promise.allSettled([meta.mutate(), mutateAll()])
  }

  const joinWithCode = async (teamId: number, code?: string) => {
    return ctrl({ skipErrorDisplay: true }).createCompetitionParticipant({
      competitionId,
      competitionParticipantCreateCommand: {
        participant: account?.id!,
        entry: teamId,
        code,
        authorizedNetworks: requiredNetworks
      }
    })
  }

  const isOnTeam = (id?: number) => involvement?.some(it => it.entry?.id === id)
  const hasTeam = () => involvement?.some(it => !!it.entry)
  const isCoach = () => involvement?.some(it => it.management && !it.player)
  const isPlayer = () => involvement?.some(it => !!it.entry && it.player)
  const isFreeAgent = () => involvement?.some(it => !it.entry)

  return {
    involvement,
    read: meta,
    createTeam,
    joinSolo,
    removeSolo,
    joinWithCode,
    isOnTeam,
    hasTeam,
    isCoach,
    isPlayer,
    isFreeAgent
  }
}
