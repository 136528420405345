import { RcTrans } from '@/components/atoms/RcTrans'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { ErrorCode } from '@/core/error-code'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionIssues } from '@/entity/competition/useCompetitionIssues'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { SetHandle } from '@/flows/Competition/JoinFlowXstate/SetHandle'
import Button from '@mui/material/Button'

export const useCompetitionAlertsUserNetwork = () => {
  const { issues } = useCompetitionIssues()
  const { getNetworkString } = useNetworkKinds()
  const { user } = useUserAccount()
  const {
    read: { mutate }
  } = useCompetition()

  const items: TutorialItem[] = []

  const missing = issues?.find(
    it =>
      it.code === ErrorCode.MissingRequiredNetwork &&
      it.context?.entryMember === user?.id
  )

  if (!missing) return items

  items.push({
    header: <RcTrans i18nKey='competition:issues.missing-network-header' />,
    type: TutorialType.TASK,
    severity: 'warning',
    children: (
      <RcTrans
        i18nKey='competition:issues.missing-network-content'
        tOptions={{
          network: getNetworkString(
            missing?.context?.missingRequiredNetwork as any
          )
        }}
      />
    ),
    action: (
      <ModalTrigger
        activation={handleOpen => (
          <Button onClick={handleOpen}>
            <RcTrans i18nKey='shared.update' />
          </Button>
        )}
      >
        {({ handleClose }) => (
          <SetHandle
            context={{
              network: missing?.context?.missingRequiredNetwork as any
            }}
            processing={false}
            onHandleSet={() => {
              mutate()
              handleClose()
            }}
          />
        )}
      </ModalTrigger>
    )
  })

  return items
}
