import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { Form, Formik } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import dynamic from 'next/dynamic'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { orderBy, startCase } from 'lodash-es'
import { CalendarKindIcon } from '../../../../organisms/calendar/CalendarKindIcon'
import { CompetitionEventReminders } from './CompetitionEventReminders'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { PageForm } from '@/components/organisms/page/PageForm'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { LabeledSwitch } from '@/components/organisms/form/LabeledSwitch'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'

const RcDateTimePicker = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcDateTimePicker'))
      .RcDateTimePicker,
  { ssr: false, loading: () => <></> }
)

export const excludedTypes: EventKind[] = [
  EventKind.DONATION,
  EventKind.OPENING,
  EventKind.CLOSING
]

export const CompetitionEventCreateEdit = ({
  resource,
  onComplete
}: {
  resource?: CompetitionEventResource
  onComplete: () => any
}) => {
  const { create, update } = useCompetitionEvents()

  const start = resource?.startDate || moment().minutes(0)?.toDate()
  const end = resource?.endDate || moment(start).add(1, 'h')?.toDate()
  const initial = {
    name: resource?.name || '',
    kind: resource?.kind || EventKind.OTHER,
    description: resource?.description || '',
    startDate: start,
    endDate: end,
    pinned: resource?.pinned || false,
    hidden: resource?.hidden || false,
    ...resource
  }

  type FormModel = typeof initial

  const handleSubmit = async (values: FormModel) => {
    const res = initial.id
      ? await update(initial.id, values)
      : await create(values)

    onComplete()
  }

  const validation = Yup.object<FormModel>({
    kind: Yup.string(),
    pinned: Yup.boolean(),
    hidden: Yup.boolean(),
    name: Yup.string().required(),
    description: Yup.string(),
    startDate: Yup.date().required(),
    endDate: Yup.date().test(
      'is-greater',
      'End date must be after start date',
      function (val) {
        return moment(val).isAfter(this.parent.startDate)
      }
    )
  })

  return (
    <Formik
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={validation}
    >
      {({ values, setFieldValue, getFieldMeta, isSubmitting }) => (
        <Form id='form-schedule-edit-form'>
          <Stack direction='column' spacing={3}>
            <LabeledField
              disabled={!!initial?.id}
              component={Select}
              name='kind'
              label={<RcTrans i18nKey='shared.kind' />}
              fullWidth
            >
              {orderBy(
                Object.values(EventKind).filter(
                  it => !excludedTypes.includes(it)
                ),
                it => it
              ).map(kind => {
                return (
                  <MenuItem key={kind} value={kind}>
                    <Box pr={2}>
                      <CalendarKindIcon kind={kind as EventKind} />
                    </Box>
                    {startCase(kind.toLocaleLowerCase())}
                  </MenuItem>
                )
              })}
            </LabeledField>

            <LabeledField
              component={TextField}
              name='name'
              label={<RcTrans i18nKey='shared.name' />}
              fullWidth
            />

            <LabeledField
              component={TextField}
              name='description'
              label={<RcTrans i18nKey='shared.description' />}
              fullWidth
              multiline
            />

            <LabeledField
              label={
                <RcTrans i18nKey='competition:schedule.event-start-date' />
              }
              component={RcDateTimePicker}
              selected={values.startDate}
              onChange={(val: Date) => setFieldValue('startDate', val)}
            />

            <LabeledField
              label={<RcTrans i18nKey='competition:schedule.event-end-date' />}
              component={RcDateTimePicker}
              selected={values.endDate}
              onChange={(val: Date) => setFieldValue('endDate', val)}
            />
            {getFieldMeta('endDate').touched &&
            getFieldMeta('endDate').error ? (
              <FormHelperText error>
                <RcTrans i18nKey={getFieldMeta('endDate').error || ''} />
              </FormHelperText>
            ) : null}

            <PageForm
              title={<RcTrans i18nKey='competition:schedule.is-pinned-title' />}
              description={
                <RcTrans i18nKey='competition:schedule.is-pinned-description' />
              }
              sideChild
            >
              <LabeledSwitch name='pinned' />
            </PageForm>

            <PageForm
              title={<RcTrans i18nKey='competition:schedule.is-hidden-title' />}
              description={
                <RcTrans i18nKey='competition:schedule.is-hidden-description' />
              }
              sideChild
            >
              <LabeledSwitch name='hidden' />
            </PageForm>

            <Divider />

            {initial?.id ? (
              <CompetitionEventReminders eventId={initial?.id} />
            ) : null}

            <ModalConfiguration
              title={
                initial?.id ? (
                  <RcTrans i18nKey='shared.update' />
                ) : (
                  <RcTrans i18nKey='shared.create-new-event' />
                )
              }
            >
              <RcButton
                type='submit'
                disabled={isSubmitting}
                form='form-schedule-edit-form'
                fullWidth
                variant='contained'
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            </ModalConfiguration>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
