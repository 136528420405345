import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Typography, { TypographyProps } from '@mui/material/Typography'
import moment from 'moment-timezone'

import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useTime } from '@/core/hooks/useTime'

export interface ScheduleTypographyProps extends TypographyProps {
  startDate?: Date
  endDate?: Date
  name?: string
  chip?: boolean
}

export const ScheduleTypography: React.FC<ScheduleTypographyProps> = ({
  children,
  startDate,
  endDate,
  name,
  chip,
  ...props
}) => {
  const { displayDateSpan, getNow } = useTime()

  const now = getNow()
  const start = moment(startDate)
  const end = moment(endDate)
  const isPast = end.isBefore(now)
  const isFuture = start.isAfter(now)
  const isCurrent = !isPast && !isFuture

  // Keep these comments for i18next-parser dynamic strings
  // t('schedule.name.past-event')
  // t('schedule.solo.past-event')
  // t('schedule.name.current-event')
  // t('schedule.solo.current-event')
  // t('schedule.name.future-event')
  // t('schedule.solo.future-event')
  return (
    <Tooltip
      title={displayDateSpan(startDate!, endDate)}
      placement={'top'}
      enterTouchDelay={0}
      disableTouchListener
    >
      <span>
        {chip ? (
          <Chip
            icon={<RcIcon icon={['fal', 'hourglass-half']} />}
            label={
              <RcTrans
                i18nKey={`schedule.${name ? 'name' : 'solo'}.${
                  isPast ? 'past' : isCurrent ? 'current' : 'future'
                }-event`}
                tOptions={{
                  name: name,
                  fromNow: isFuture ? start.fromNow(true) : end.fromNow(true)
                }}
              >
                <strong>in</strong>
              </RcTrans>
            }
          />
        ) : (
          <>
            {children}
            <span>
              <RcTrans
                i18nKey={`schedule.${name ? 'name' : 'solo'}.${
                  isPast ? 'past' : isCurrent ? 'current' : 'future'
                }-event`}
                tOptions={{
                  name: name,
                  fromNow: isFuture ? start.fromNow(true) : end.fromNow(true)
                }}
              >
                <strong>in</strong>
              </RcTrans>
            </span>
          </>
        )}
      </span>
    </Tooltip>
  )
}
