import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript'
import { MatchCard } from '../match/card/MatchCard'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useBracketMatchesUber } from '@/entity/bracket-match/useBracketMatchesUber'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import Box from '@mui/material/Box'

export const TeamMatches = () => {
  const parsedId = useParsedParam('teamId')
  const [sortDesc, setSortDesc] = useLocalStorage({
    key: 'matchesSortDesc',
    defaultValue: false
  })
  const { competition } = useCompetition()
  const {
    matches,
    hasMore,
    query: { mutate, isValidating, setSize, size }
  } = useBracketMatchesUber({
    request: { entry: [parsedId], sortDesc }
  })

  const displayMatches = (matches: BracketMatchResource[]) =>
    matches.map(it => {
      return (
        <MatchCard
          key={it.id}
          match={it}
          competition={competition}
          onFlagged={mutate}
        />
      )
    })

  return (
    <PageItem contained={false} fillContent>
      <PageItemContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
          {/* ['fal', 'arrow-up-wide-short'] */}
          {/* ['fal', 'arrow-down-wide-short'] */}
          <RcIconButton
            disabled={isValidating}
            onClick={() => setSortDesc(!sortDesc)}
            icon={[
              'fal',
              sortDesc ? 'arrow-up-wide-short' : 'arrow-down-wide-short'
            ]}
            sx={{ mb: 2 }}
            TooltipProps={{
              title: sortDesc
                ? 'Sort Oldest to Newest'
                : 'Sort Newest to Oldest',
              arrow: true,
              placement: 'top'
            }}
          />
        </Box>
        <Stack direction='column' spacing={3}>
          {displayMatches(matches)}
        </Stack>
        <InfiniteScrollV2
          hasMore={hasMore}
          isLoading={isValidating}
          onLoad={() => setSize(size + 1)}
        />
      </PageItemContent>
    </PageItem>
  )
}
