import { PrettifyJson } from '@/components/atoms/dev/PrettifyJson'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { ActionMenu } from '@/components/molecules/interactive/ActionMenu'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { usePage } from '@/components/providers/site/PageProvider'
import { borderAnimation } from '@/core/animations'
import { expand, ExpansionType } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import {
  CompetitionRoute,
  CompetitionTeamsRoute,
  RootRoute
} from '@/core/route-keys'
import { useCommunityMembers } from '@/entity/community/useCommunityMembers'
import { useCommunityPlaceholders } from '@/entity/community/useCommunityPlaceholders'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipantDocument } from '@/entity/competition/useCompetitionPartipantDocument'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserDocument } from '@/entity/user/useUserDocument'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CommunityResource,
  UserChildCreateCommand,
  UserResource
} from '@rallycry/api-suite-typescript'
import { isArray, uniqBy } from 'lodash-es'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export const TeamRosterSearch = ({
  condensed,
  existing
}: {
  condensed?: boolean
  existing?: { user: UserResource & { rank: TeamRank } }[]
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { navTo } = useNavigation()
  const { open } = usePage()
  const { t } = useRcTranslation()

  const { account } = useUserAccount()
  const { competition, settings } = useCompetition()
  const { team, copyTeamInvite } = useCompetitionTeam()
  const { invite } = useCompetitionTeamInvitations()

  // filter for the user picker
  const [user, setUser] = useState<UserResource | null>(null)
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 300)

  const {
    family,
    query: { isValidating: iv3, size: s3, setSize: ss3 }
  } = useUserFamilyMembers({
    paused: !settings?.allowChildUsers
  })
  const {
    members,
    totalElements: te2,
    query: { isValidating: iv2, size: s2, setSize: ss2 }
  } = useCommunityMembers({
    paused: !team?.representing?.id,
    request: {
      communityId: team?.representing?.id,
      q: debouncedQuery
    }
  })
  const {
    participants,
    totalElements: te4,
    query: { isValidating: iv4, size: s4, setSize: ss4 }
  } = useCompetitionParticipantDocument({
    request: { q: debouncedQuery || undefined, entryIsNull: true }
  })
  const {
    users,
    query: { isValidating: iv1, size: s1, setSize: ss1 }
  } = useUserDocument({
    paused: settings?.representSameCommunity,
    request: {
      includeMe: false,
      q: debouncedQuery
    }
  })

  const { addMember } = useCompetitionTeamMembers()
  const { createPlaceholder } = useCommunityPlaceholders()

  const handleAddMember = async (
    user?: UserResource | null,
    isCoach?: boolean
  ) => {
    if (!user) return
    setIsSaving(true)
    try {
      if (isCoach) {
        const res = await addMember(user?.id!, {
          management: true,
          player: false
        })
        open(null, `${user?.name} added as a coach`, 5000)
      } else {
        await addMember(user?.id!, { management: false, player: true })
        open(null, `${user?.name} added to team`, 5000)
      }
    } catch (e) {
      await invite(user?.id!)
      open(null, `${user?.name} invited to team`, 5000)
    } finally {
      setUser(null)
      setIsSaving(false)
    }
  }

  const handlePlaceholderCreate = async (name?: string) => {
    setIsSaving(true)
    try {
      const representing = expand<CommunityResource>(
        team,
        team?._expanded,
        ExpansionType.CompetitionRepresenting
      )
      const id = representing?.parent?.id || representing?.id
      if (!id) return
      const res = await createPlaceholder(id, {
        account: { name }
      } as UserChildCreateCommand)
      await handleAddMember(res)
    } finally {
      setIsSaving(false)
    }
  }

  const existingIds = existing?.map(it => it.user.id) || []
  const options = uniqBy(
    [
      ...family,
      ...members.map(it => it.member!),
      ...participants.map(
        it =>
          ({
            ...it.participant,
            disambiguatingDescription: it.description
          }) as any
      ),
      ...users
    ].filter(it => it.id !== account?.id && !existingIds.includes(it.id)),
    it => it.id
  )

  return (
    <Stack
      direction='row'
      spacing={2}
      pl={4}
      pr={condensed ? 4 : 8}
      alignItems='center'
    >
      <Box flexGrow={1}>
        <EntityAutocomplete
          noOptionsText={(handleClose, inputValue) => (
            <>
              <MenuItem
                onClick={async () => {
                  await handlePlaceholderCreate(inputValue)
                  handleClose()
                }}
              >
                <RcIcon icon={['fal', 'user']} mr={2} />
                <Typography> Create Placeholder</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navTo({
                    root: RootRoute.Competition,
                    rootId: competition?.id,
                    subRoute: CompetitionRoute.Team,
                    subId: CompetitionTeamsRoute.FreeAgent
                  })
                }
              >
                <RcIcon icon={['fal', 'user-bounty-hunter']} mr={2} />
                <Typography> Search Free Agents</Typography>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await copyTeamInvite()
                  handleClose()
                }}
              >
                <RcIcon icon={['fal', 'copy']} mr={2} />
                <Typography>Copy Invite Link</Typography>
              </MenuItem>
            </>
          )}
          isValidating={iv1 || iv2 || iv3 || iv4}
          options={options}
          selected={user?.id ? [user?.id] : []}
          placeholder={t('competition:team.roster-search-placeholder')}
          variant='circular'
          onSearch={q => setQuery(q)}
          onLoadOptions={async () => {
            ss1(s1 + 1)
            ss2(s2 + 1)
            ss3(s3 + 1)
            ss4(s4 + 1)
          }}
          onChange={async updated => setUser(isArray(updated) ? null : updated)}
          enableReinitialize
        />
      </Box>
      <Box position={'relative'} p={0.5} m={-0.5}>
        {user && !isSaving ? (
          <Box
            sx={theme => ({
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              p: 0.5,
              borderRadius: 15,
              ...borderAnimation()
            })}
          />
        ) : null}
        <ActionMenu
          options={
            user
              ? [
                  {
                    key: 'add-member',
                    display: (
                      <RcTrans i18nKey='competition:team.roster-add-member' />
                    ),
                    action: () => handleAddMember(user)
                  },
                  {
                    key: 'add-coach',
                    display: (
                      <RcTrans i18nKey='competition:team.roster-add-coach' />
                    ),
                    action: () => handleAddMember(user, true)
                  }
                ]
              : []
          }
          buttonProps={{
            variant: 'contained',
            sx: {},
            disabled: !user || isSaving
          }}
        >
          <RcIcon icon={['fal', 'plus']} mr={condensed ? 0 : 1} />
          {condensed ? null : <RcTrans i18nKey='shared.add' />}
        </ActionMenu>
      </Box>

      <RcButton variant='outlined' onClick={copyTeamInvite}>
        <RcIcon icon={['fal', 'copy']} mr={condensed ? 0 : 1} />
        {condensed ? null : <RcTrans i18nKey='competition:team.invite-link' />}
      </RcButton>
    </Stack>
  )
}
