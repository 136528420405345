import { EntityOptions } from '../useEntity'
import { useCompetition } from './useCompetition'

export const useCompetitionInvolvement = (options?: EntityOptions) => {
  const { competition } = useCompetition(options)

  return {
    involvement: competition?._viewInvolvement?.participation?.map(it => ({
      ...it,
      _expanded: competition?._expanded
    }))
  }
}
