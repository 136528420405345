import { RcTrans } from '@/components/atoms/RcTrans'
import {
  PageNavigator,
  PageNavigatorOption
} from '@/components/organisms/page/PageNavigator'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ExpansionType, expander } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionMeta } from '@/entity/competition/useCompetitionMeta'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useCompetitionTickets } from '@/entity/competition/useCompetitionTickets'
import { useCompetitionTutorial } from '@/entity/competition/useCompetitionTutorial'
import {
  ChatProvider,
  CompetitionChannel
} from '@rallycry/api-suite-typescript'
import { some } from 'lodash-es'
import { usePathname } from 'next/navigation'

const skipChatModes: ChatProvider[] = [ChatProvider.NONE, ChatProvider.DISCORD]
export const CompetitionNavigator = () => {
  const { meta } = useCompetitionMeta()
  const { competition, isCompetitionModerator, settings } = useCompetition()
  const { involvement } = useCompetitionParticipant()

  const { getPath } = useNavigation()
  const { tickets } = useCompetitionTickets({ suspense: false })
  const { featChat } = useFeatures()
  const { tasks, administration } = useCompetitionTutorial()
  const pathname = usePathname()

  const channel = expander<CompetitionChannel>(
    competition,
    ExpansionType.CompetitionChannel
  )

  const { room } = useChatRoomDocument({
    idOrKey: channel?.socialId,
    paused: !featChat || skipChatModes.includes(settings?.chatProvider!)
  })

  const options: PageNavigatorOption[] = [
    {
      text: <RcTrans i18nKey='competition:nav.info' />,
      alert: tasks?.filter(it => !it.dismissed)?.length,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Info,
        subId: CompetitionInfoRoute.Details
      })
    }
  ]

  room &&
    options.push({
      text: <RcTrans i18nKey='competition:nav.chat' />,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Chat
      })
    })

  if (
    isCompetitionModerator ||
    some(involvement) ||
    !settings?.hideRegistration
  ) {
    options.push({
      text: <RcTrans i18nKey='competition:nav.teams' />,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Team
      })
    })
  }

  if (isCompetitionModerator || meta.brackets! > 0) {
    options.push({
      text: <RcTrans i18nKey='competition:nav.bracket' />,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Bracket
      })
    })
  }

  if (meta.brackets! > 0) {
    options.push({
      active: pathname.includes(CompetitionRoute.Match),
      text: <RcTrans i18nKey='competition:nav.matches' />,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Matches
      })
    })
  }
  if (isCompetitionModerator) {
    options.push({
      alert: tickets?.length + administration?.length,
      text: <RcTrans i18nKey='competition:nav.settings' />,
      to: getPath({
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Settings
      })
    })
  }

  return <PageNavigator options={options} variant='horizontal' skipScroll />
}
