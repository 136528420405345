import { BracketSubmissionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/BracketSubmissionControllerApi'
import {
  BracketSubmissionDocumentControllerApi,
  SearchBracketSubmissionsRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketSubmissionDocumentControllerApi'
import { BracketSubmissionCreateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionCreateCommand'
import { BracketSubmissionResource } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionResource'
import { BracketSubmissionUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketSubmissionUpdateCommand'
import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { clone, first, orderBy, some, take, uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { useBracket } from '../bracket/useBracket'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useBracketLeaderboard } from './useBracketLeaderboard'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBracketSubmissionDocument = (
  options?: EntityOptions<SearchBracketSubmissionsRequest>
) => {
  const bracketId = useParsedParam('bracketId')
  const { bracketSettings } = useBracket()
  const {
    query: { mutate }
  } = useBracketLeaderboard()
  const { ctrl } = useController(BracketSubmissionDocumentControllerApi)
  const { ctrl: submissionCtrl } = useController(BracketSubmissionControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketSubmissionDocument',
    ...options,
    request: options?.request ? { bracketId, ...options?.request } : undefined,
    read: req => ctrl().searchBracketSubmissions(req)
  })

  const getFilteredSubmissions = () => {
    const submissions = entity.query.data?.[0]?.content
    const sort = bracketSettings?.submissionOrderAscending ? 'asc' : 'desc'
    const byPoints = orderBy(submissions, it => it.points, sort)
    const filteredSubmissions = take(
      byPoints,
      bracketSettings?.submissionCutoff
    )
    return filteredSubmissions
  }

  const create = async ({
    assignmentId,
    ...cmd
  }: BracketSubmissionCreateCommand & { assignmentId: number }) => {
    const res = await submissionCtrl().createSubmission({
      assignmentId,
      bracketSubmissionCreateCommand: cmd,
      expand: 'assignment{entry}'
    })

    await handleResult(res)
  }

  const update = async (id: number, cmd: BracketSubmissionUpdateCommand) => {
    const res = await submissionCtrl().updateBracketSubmission({
      id,
      bracketSubmissionUpdateCommand: cmd,
      expand: 'assignment{entry}'
    })

    await handleResult(res)
  }

  async function handleResult(res: BracketSubmissionResource) {
    // merge result into 1st page of user submissions
    await entity.query.mutate(data => {
      const d = clone(first(data) || {})
      d.content = [...(d.content || []).filter(it => it.id !== res.id), res]
      return [d]
    }, false)

    // merge top N submissions into leaderboard results
    await mutate(data => {
      const d = first(data) || { content: [] }

      const assignment = d.content?.find(
        it => it.assignment?.id === res?.assignment?.id
      )
      if (assignment) {
        assignment.submissions = getFilteredSubmissions()
      } else {
        d.content = [
          ...(d.content || []),
          {
            assignment: res?.assignment,
            submissions: getFilteredSubmissions()
          }
        ]
      }

      const mergeExpand = (
        key: keyof Expanded,
        source?: Expanded,
        extra?: Expanded
      ) => {
        source![key] = uniqBy(
          [...(source?.[key] || []), ...(extra?.[key] || [])],
          it => it.id
        ) as any
      }
      mergeExpand('community', d._expanded, res._expanded)
      mergeExpand('bracketAssignment', d._expanded, res._expanded)
      mergeExpand('competitionEntry', d._expanded, res._expanded)

      return [d]
    })
  }

  const remove = async (id: number) => {
    await submissionCtrl().removeBRacketSubmission({
      id
    })

    // remove from self submissions
    await entity.query.mutate(data => {
      data?.forEach(d => (d.content = d.content?.filter(it => it.id !== id)))
      return [...(data || [])]
    }, false)

    // update leaderboard submissions
    await mutate(data => {
      const submissions = getFilteredSubmissions()

      data?.forEach(d => {
        if (some(submissions)) {
          const match = d.content?.find(
            it => it.assignment?.id === options?.request?.assignment
          )
          if (match) {
            match.submissions = getFilteredSubmissions()
          }
        } else {
          d.content = d.content?.filter(
            it => it.assignment?.id !== options?.request?.assignment
          )
        }
      })
      return [...(data || [])]
    })
  }

  const sorted = useMemo(
    () => orderBy(entity.flat, it => it.ordinal),
    [entity.flat]
  )

  return {
    ...entity,
    submissions: sorted,
    create,
    update,
    remove
  }
}
