import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useTime } from '@/core/hooks/useTime'

const formatMaskLong = 'D [d]'
const formatMask = 'HH:mm:ss'
const tinyMask = 'mm:ss'

export const Countdown: React.FC<{
  countUp?: boolean
  target?: Date
  longMask?: string
  shortMask?: string
  onComplete?: () => void
}> = ({ countUp, target, longMask, shortMask, onComplete }) => {
  const { getNow } = useTime()
  const [completed, setCompleted] = useState(false)
  const [display, setDisplay] = useState('')

  // reset completion on new target
  useEffect(() => setCompleted(false), [target])

  useEffect(() => {
    if (!target) return
    const targetMoment = moment(target)

    const tick = () => {
      const now = getNow()
      const countdownMillisecond = targetMoment.diff(now)

      if (!countUp && countdownMillisecond < 0 && !completed) {
        setCompleted(true)
        onComplete?.()
      } else {
        const mask =
          countdownMillisecond < 1000 * 60 * 60
            ? tinyMask
            : countdownMillisecond < 1000 * 60 * 60 * 24
              ? shortMask || formatMask
              : longMask || formatMaskLong
        const formatted = moment
          .utc(Math.abs(countdownMillisecond))
          .format(mask)

        setDisplay(formatted)
      }
    }

    tick()
    const timer = setInterval(tick, 250)

    return () => clearInterval(timer)
  }, [countUp, target, onComplete, completed, getNow, longMask, shortMask])

  if (!target || completed) return null
  return <Box sx={{ whiteSpace: 'nowrap', display: 'inline' }}>{display}</Box>
}
