import Stack from '@mui/material/Stack'

import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'
import { CreateDependentButton } from '@/flows/Site/CreateDependentFlow/CreateDependentButton'
import Typography from '@mui/material/Typography'

export const DependentAccounts = ({
  onSelected
}: {
  onSelected?: () => void
}) => {
  const { setImpersonation } = useImpersonation()
  const { account: rootAccount } = useUserAccount({ skipImpersonation: true })
  const { account } = useUserAccount()
  const { family } = useUserFamilyMembers()

  return (
    <Stack direction='column' spacing={5}>
      {family?.map((it, idx) => (
        <RcPaperButton
          key={it.id}
          onClick={() => {
            onSelected?.()
            // stop impersonation for parent user with null
            setImpersonation({
              impersonation: it.id === rootAccount?.id ? null : it,
              isProfile: idx !== 0
            })
          }}
          selected={it.id === account?.id}
        >
          <UserAvatar
            disableClick
            user={it}
            textColor={it.id === account?.id ? 'inherit' : 'text.secondary'}
          />
        </RcPaperButton>
      ))}
      <ModalConfiguration
        title={
          <RcTrans i18nKey='competition:dependent-account.whos-playing-title' />
        }
        subtitle={
          <Typography color='textSecondary'>
            <RcTrans i18nKey='competition:dependent-account.select-profile-description' />
          </Typography>
        }
      >
        <CreateDependentButton />
      </ModalConfiguration>
    </Stack>
  )
}
