import {
  CompetitionEntryControllerApi,
  CreateCompetitionEntryRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { CompetitionEntryUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryUpdateCommand'
import {
  CommonDuration,
  CompetitionEntryInviteControllerApi
} from '@rallycry/api-suite-typescript'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { useCompetitionParticipant } from '../competition/useCompetitionParticipant'
import { useCompetitionUserView } from '../competition/useCompetitionUserView'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { usePage } from '@/components/providers/site/PageProvider'
import { useSessionStorage } from '@/core/hooks/useLocalStorage'
import { useCompetition } from '../competition/useCompetition'

export const useCompetitionTeam = (options?: EntityOptions) => {
  const parsedId = useParsedParam('teamId')
  const { read: competitionMeta } = useCompetitionMeta()
  const { requiredNetworks } = useCompetition()
  const { read: userMeta } = useUserMeta()
  const { ctrl } = useController(CompetitionEntryControllerApi)
  const { ctrl: entryInviteCtrl } = useController(
    CompetitionEntryInviteControllerApi
  )
  const {
    read: { mutate: mutateParticipant }
  } = useCompetitionParticipant()

  const {
    read: { mutate: mutateAll }
  } = useCompetitionUserView()
  const { getLinkUrl } = useNavigation()
  const { open } = usePage()

  const entryId = Number(options?.idOrKey) || parsedId
  const entity = useReadEntity({
    key: `useCompetitionTeam-${competitionMeta?.data?.root}-${userMeta?.data?.access}`,
    idOrKey: entryId,
    expand:
      '_links,_issues,representing{profile,parent{additionalOrganizations},additionalOrganizations},leader,assistants,_membersTotalElements',
    ...options,
    read: req => ctrl().readCompetitionEntry(req),
    create: (req: CreateCompetitionEntryRequest) =>
      ctrl().createCompetitionEntry({
        ...req,
        competitionEntryCreateCommand: {
          ...req.competitionEntryCreateCommand,
          authorizedNetworks: requiredNetworks
        }
      }),
    update: (id, cmd: CompetitionEntryUpdateCommand, expand) =>
      ctrl().updateCompetitionEntry({
        id,
        competitionEntryUpdateCommand: cmd,
        expand
      }),
    remove: async id => {
      await ctrl().removeCompetitionEntry({ id })
      await Promise.allSettled([
        competitionMeta.mutate(),
        mutateParticipant(),
        mutateAll()
      ])
    }
  })

  const drop = async () => {
    await ctrl().dropCompetitionEntry({ id: entryId })
    await Promise.allSettled([
      competitionMeta.mutate(),
      entity.read.mutate(),
      mutateAll()
    ])
  }

  const unDrop = async () => {
    await ctrl().unDropCompetitionEntry({ id: entryId })
    await entity.read.mutate()
    await Promise.allSettled([
      competitionMeta.mutate(),
      entity.read.mutate(),
      mutateAll()
    ])
  }

  const handleCopyDisplayLink = (code: string) => {
    const link = getLinkUrl({
      root: RootRoute.Competition,
      rootId: entity.read.data?.competition?.id,
      subRoute: CompetitionRoute.Team,
      subId: entity.read.data?.id,
      query: `code=${code}`
    })
    navigator.clipboard.writeText(link)
    open(
      null,
      `Invite link copied to clipboard! Expires in 7 days. ${link}`,
      10000
    )
  }

  const createTeamInvite = async () =>
    entryInviteCtrl().createCompetitionEntryInvite({
      id: entryId,
      competitionInviteCreateCommand: {
        expireAfter: CommonDuration.ONE_WEEK
      }
    })

  const [code, setCode] = useSessionStorage<string | null>({
    key: 'team-invite-code-' + entryId,
    defaultValue: null
  })

  const copyTeamInvite = async () => {
    if (code) {
      handleCopyDisplayLink(code)
      return
    }
    const res = await createTeamInvite()
    if (!res.code) return

    setCode(res.code)
    handleCopyDisplayLink(res.code)
  }

  return {
    ...entity,
    team: entity.read.data,
    isLeader: entity.read.data?.leader?.id === userMeta.data?.id,
    drop,
    unDrop,
    copyTeamInvite
  }
}
