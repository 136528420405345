import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { TranslationNamespace } from '@/core/translation'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserProfile } from '@/entity/user/useUserProfile'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export const ArchiveUser = ({ userId }: { userId: number }) => {
  const { user, archive } = useUserProfile({ idOrKey: userId })
  const { t } = useRcTranslation([TranslationNamespace.Administration])

  return (
    <ConfirmingButton
      onClick={archive}
      icon={['fal', 'archive']}
      message={<RcTrans i18nKey='administration:archive-user-confirmation' />}
      confirmToken={user?.name}
      buttonName={<RcTrans i18nKey='shared.archive' />}
      placeholder={t('administration:type-username-to-confirm')}
    >
      <RcTrans i18nKey='administration:archive-user-label' />
    </ConfirmingButton>
  )
}
