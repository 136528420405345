import {
  MatchSchedulerControllerApi,
  PollVotesCommand
} from '@rallycry/api-suite-typescript'
import { QueryMatchGamesRequest } from '@rallycry/api-suite-typescript/dist/apis/MatchGameControllerApi'
import { first } from 'lodash-es'
import moment from 'moment-timezone'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useBracketMatchMeta } from './useBracketMatchMeta'
import { useTime } from '@/core/hooks/useTime'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useMatchSchedulerPoll = (
  options?: EntityOptions<QueryMatchGamesRequest> & { filter?: number[] }
) => {
  const { getNow } = useTime()
  const { user } = useUserAccount()
  const parsedId = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)

  const { ctrl } = useController(MatchSchedulerControllerApi)

  const matchId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useMatchSchedulerPoll',
    pageSize: 1000,
    metas: [meta],
    ...options,
    expand: 'content{votes{user}}',
    request: { matchId, ...options?.request },
    read: (req, { scheduler }) =>
      ctrl({
        metas: { scheduler },
        expectedStatuses: [404],
        fallbackResponse: { content: [] }
      }).queryBracketMatchSchedulerOptions(req)
  })

  const setAvailability = async (votes: string[]) => {
    const up = votes?.map(it => ({ optionText: it, retracted: false }))

    const down = entity.flat
      .filter(it => !up.find(that => that.optionText === it.text))
      .map(it => ({ id: it.id, optionText: it.text!, retracted: true }))

    const now = await getNow()
    const combined = [...up, ...down].filter(
      it => it.retracted || moment(it.optionText).isAfter(now)
    )

    await vote({ content: combined })
  }

  const vote = async (cmd: PollVotesCommand) => {
    await ctrl().voteBracketMatchScheduler({
      matchId,
      pollVotesCommand: cmd
    })
    await meta.mutate()
  }

  return {
    ...entity,
    setAvailability,
    vote
  }
}
