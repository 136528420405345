import {
  MatchGameControllerApi,
  QueryMatchGamesRequest
} from '@rallycry/api-suite-typescript/dist/apis/MatchGameControllerApi'
import { MatchGameReportControllerApi } from '@rallycry/api-suite-typescript/dist/apis/MatchGameReportControllerApi'
import { MatchGameResultCommand } from '@rallycry/api-suite-typescript/dist/models/MatchGameResultCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useBracketMatchesPersonal } from './useBracketMatchesPersonal'
import { useBracketMatchMeta } from './useBracketMatchMeta'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useMatchGameReports = (
  options?: EntityOptions<QueryMatchGamesRequest>
) => {
  const parsedId = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)
  const {
    query: { mutate: refreshPersonal }
  } = useBracketMatchesPersonal()
  const { ctrl } = useController(MatchGameReportControllerApi)
  const { ctrl: gameCtrl } = useController(MatchGameControllerApi)

  const matchId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useMatchGameReports',
    expand: '_links',
    ...options,
    request: { matchId, ...options?.request },
    metas: [meta],
    read: (req, { reports }) =>
      ctrl({ metas: { reports } }).queryMatchGameReports(req)
  })

  const accept = async () => {
    await ctrl().acceptMatchGameReports({ matchId })
    await Promise.allSettled([meta.mutate(), refreshPersonal()])
  }

  const decline = async () => {
    await ctrl().declineMatchGameReports({ matchId })
    await Promise.allSettled([meta.mutate(), refreshPersonal()])
  }

  const reportGames = async (
    games: (MatchGameResultCommand & { isLocked?: boolean })[]
  ) => {
    await ctrl().saveMatchGameReports({
      matchId,
      matchGameResultsCommand: {
        content: games.filter(it => !it.isLocked)
      }
    })
    await Promise.allSettled([meta.mutate(), refreshPersonal()])
  }

  const adminReportGames = async (
    games: (MatchGameResultCommand & { isLocked?: boolean })[]
  ) => {
    await gameCtrl().saveMatchGames({
      matchId,
      matchGameResultsCommand: {
        content: games.filter(it => !it.isLocked && !!it.winner)
      }
    })
    await Promise.allSettled([meta.mutate(), refreshPersonal()])
  }

  return {
    ...entity,
    matchGameReports: entity.flat,
    accept,
    decline,
    reportGames,
    adminReportGames
  }
}
