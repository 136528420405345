import { useOnScreen } from '@/core/hooks/useOnScreen'
import Typography from '@mui/material/Typography'
import { useLayoutEffect, useRef, useState } from 'react'

export const MatchRatingAnimation = ({
  start,
  delta
}: {
  start: number
  delta: number
}) => {
  const ref = useRef(null)
  const [number, setNumber] = useState(start)
  const scrolled = useOnScreen({ ref, setOnce: true })

  useLayoutEffect(() => {
    if (!scrolled) return
    if (delta === 0) return
    if (
      (delta > 0 && number > start + delta) ||
      (delta < 0 && number < start + delta)
    ) {
      return
    }

    const duration = 800
    const framesPerSecond = 30
    const totalFrames = duration / (1000 / framesPerSecond)
    const increment = delta / totalFrames

    const interval = setInterval(() => {
      setNumber(prevNumber => prevNumber + increment)
    }, 1000 / framesPerSecond)

    return () => clearInterval(interval)
  }, [start, delta, number, scrolled])

  const max = delta > 0 ? start + delta : start
  const min = delta > 0 ? start : start + delta
  const bounded = Math.min(Math.max(number, min), max)
  const rounded = Math.round(bounded / 1000)

  return (
    <Typography ref={ref} variant='subtitle2'>
      {rounded}
    </Typography>
  )
}
