import { AchievementResource } from '@rallycry/api-suite-typescript/dist/models/AchievementResource'
import { UserAchievedResource } from '@rallycry/api-suite-typescript/dist/models/UserAchievedResource'
import { orderBy } from 'lodash-es'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { ModalTrigger } from '../modal/ModalTrigger'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcDropdown } from '@/components/molecules/interactive/RcDropdown'
import { AchievementCard } from '@/components/pages/Administration/components/achievements/AchievementCard'
import { ExpansionType, expandById } from '@/core/expand'
import { useUserAchievements } from '@/entity/user/useUserAchievements'
import { useOrganization } from '@/entity/organization/useOrganization'
import { GrantAchievement } from '@/components/pages/Community/GrantCommunityAchievement'

export interface UserProfileAchievementProps {
  achievements?: UserAchievedResource[]
  userId?: number
}

export const UserProfileAchievement = ({ userId }: { userId: number }) => {
  const { isOrgModerator } = useOrganization()
  const {
    grantAchievement,
    achievements,
    pinAchievement,
    unPinAchievement,
    remove
  } = useUserAchievements({
    request: { userId }
  })

  const orderedList = orderBy(achievements, ['pinned'], ['desc'])

  return (
    <>
      {isOrgModerator ? (
        <ModalTrigger
          activation={handleOpen => (
            <Button onClick={handleOpen}>
              <RcTrans i18nKey='shared.grant-achievement' />
            </Button>
          )}
        >
          {({ handleClose }) => (
            <GrantAchievement
              onGrantAchievement={values => {
                grantAchievement({
                  userId,
                  achievedId: values.selectedAchievement,
                  userAchievedSaveCommand: { dateAchieved: new Date() }
                })
              }}
              onComplete={handleClose}
            />
          )}
        </ModalTrigger>
      ) : null}
      {orderedList?.map((it, idx) => {
        const expandedAchievement = expandById<AchievementResource>(
          it.achieved?.id!,
          it._expanded!,
          ExpansionType.Achievement
        )

        const options = it.pinned
          ? [
              {
                label: <RcTrans i18nKey='shared.unpin' />,
                onClick: () => unPinAchievement({ id: it.id! })
              }
            ]
          : [
              {
                label: <RcTrans i18nKey='shared.pin' />,
                onClick: () => pinAchievement({ id: it.id! })
              }
            ]

        if (isOrgModerator) {
          options.push({
            label: <RcTrans i18nKey='shared.remove' />,
            onClick: () => remove(it.id!)
          })
        }

        return (
          <AchievementCard
            key={idx}
            resource={it}
            id={it.id!}
            achievement={expandedAchievement}
            actions={
              <RcDropdown
                size='small'
                icon={['fal', 'ellipsis-h']}
                options={options}
              />
            }
          />
        )
      })}
      {/* empty box to trick bottom of view to allow popper some space */}
      <Box height={150} />
    </>
  )
}
