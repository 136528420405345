import { uniqBy } from 'lodash-es'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'

export enum CompetitionJoinState {
  Unknown = 'Unknown',
  RegisterAccount = 'RegisterAccount',
  Playing = 'Playing',
  Interested = 'Interested',
  Eligible = 'Eligible',
  Ineligible = 'Ineligible',
  SwitchToParent = 'SwitchToParent',
  SwitchToChild = 'SwitchToChild',
  Concluded = 'Concluded'
}

export const useCompetitionJoinState = () => {
  const { account } = useUserAccount()
  const { parent } = useUserFamilyMembers()
  const { isProfile } = useImpersonation()
  const { orgCommunities: selfCommunities } = useUserSelfOrgCommunities()
  const { orgCommunities: parentOrgCommunities } = useUserSelfOrgCommunities({
    skipImpersonation: true,
    paused: !isProfile
  })

  const { competition, settings } = useCompetition()
  const { hasTeam, isFreeAgent } = useCompetitionParticipant()
  const { events, isRosterLocked } = useCompetitionEvents()

  // competition has not yet loaded
  if (!competition) return CompetitionJoinState.Unknown

  if (competition.state === CompetitionState.CONCLUDED) {
    return CompetitionJoinState.Concluded
  }

  // user needs to make an account before joining competition(s)s
  if (!account) {
    return CompetitionJoinState.RegisterAccount
  }

  if (!settings?.allowChildUsers && account?.id !== parent?.id) {
    return CompetitionJoinState.SwitchToParent
  }

  if (!settings?.allowParentUsers && account?.id === parent?.id) {
    return CompetitionJoinState.SwitchToChild
  }

  const restricted = competition?._restrictToCommunities?.map(it => it.id) || []
  const orgCommunities = uniqBy(
    [...selfCommunities, ...parentOrgCommunities],
    it => it.id
  )

  const canJoin =
    competition?.state === CompetitionState.IN_PROGRESS &&
    (restricted.length === 0 ||
      orgCommunities.find(it => restricted?.includes(it?.id)))

  // user does not satisfy community join restictions
  if (!canJoin) return CompetitionJoinState.Ineligible

  const registration = events([EventKind.REGISTRATION])
  const registrationAvailable = registration?.current || registration?.previous
  const rostersOpen = !isRosterLocked

  // if registration has not started or rosters have locked, nobody can play
  if (!registrationAvailable || !rostersOpen)
    return CompetitionJoinState.Ineligible

  // user has a team
  if (hasTeam()) return CompetitionJoinState.Playing

  // user has enlisted as free agent
  if (isFreeAgent()) return CompetitionJoinState.Interested

  // user has not joined, but should be able to do so
  return CompetitionJoinState.Eligible
}
