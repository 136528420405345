import {
  MatchEscalationControllerApi,
  MatchEscalationCreateCommand,
  ReadMatchEscalationRequest
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchEscalation = (
  options?: EntityOptions<ReadMatchEscalationRequest>
) => {
  const { ctrl } = useController(MatchEscalationControllerApi)

  const entity = useReadEntity({
    key: 'useBracketMatchEscalation',
    expand: 'author',
    suspense: false,
    ...options,
    read: (req, { escalations }) =>
      ctrl({ metas: { escalations } }).readMatchEscalation(req)
  })

  const create = async (matchId: number, cmd: MatchEscalationCreateCommand) => {
    await ctrl().createMatchEscalation({
      matchId,
      matchEscalationCreateCommand: cmd
    })
  }

  const close = async (id: number) => {
    await ctrl().closeMatchEscalation({ id })
  }

  return {
    ...entity,
    escalation: entity.read.data,
    create,
    close
  }
}
