import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { AlertProps } from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { isEmpty, some } from 'lodash-es'
import { ModalTrigger } from '../modal/ModalTrigger'
import { RcAlertItem } from './RcAlertItem'
import { useSessionStorage } from '@/core/hooks/useLocalStorage'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'

export const RcAlertDisplay = ({
  issues,
  id
}: {
  issues: AlertProps[]
  id: string
}) => {
  const [expanded, setExpanded] = useSessionStorage({
    key: 'alert-expanded' + id,
    defaultValue: true
  })

  if (isEmpty(issues)) return null

  const severity = some(issues, it => it.severity === 'error')
    ? { severity: 'error', icon: ['fal', 'info-circle'] }
    : some(issues, it => it.severity === 'warning')
    ? { severity: 'warning', icon: ['fal', 'triangle-exclamation'] }
    : some(issues, it => it.severity === 'info')
    ? { severity: 'info', icon: ['fal', 'info-circle'] }
    : { severity: 'success', icon: ['fal', 'info-circle'] }

  return issues.length <= 4 ? (
    <Box position='relative'>
      <Tooltip
        title={
          expanded ? (
            <RcTrans i18nKey='shared.collapse' />
          ) : (
            <RcTrans i18nKey='shared.expand' />
          )
        }
      >
        <Box
          sx={theme => ({
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            zIndex: theme.zIndex.fab,
            top: -18,
            right: 17
          })}
        >
          <Button
            onClick={() => setExpanded(!expanded)}
            variant='outlined'
            size='small'
            sx={{
              minWidth: 0,
              borderColor: expanded
                ? 'primary.main'
                : severity.severity + '.main',
              '&:hover': {
                borderColor: expanded
                  ? 'primary.dark'
                  : severity.severity + '.dark'
              }
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              {expanded ? null : (
                <RcIcon
                  icon={severity.icon as IconProp}
                  color={severity.severity as any}
                />
              )}
              {expanded ? null : (
                <Typography variant='body1'>
                  {issues.length > 1 && issues.length}
                </Typography>
              )}
              <RcIcon
                icon={['fal', 'chevron-up']}
                rotate={expanded ? 360 : 180}
              />
            </Stack>
          </Button>
        </Box>
      </Tooltip>
      <Collapse in={expanded}>
        <Stack direction='column' spacing={2} mb={2} mx={{ xs: 1, md: 0 }}>
          {issues?.map((issue, idx) => <RcAlertItem key={idx} issue={issue} />)}
        </Stack>
      </Collapse>
    </Box>
  ) : (
    <>
      <RcAlertItem
        issue={{
          variant: 'outlined',
          sx: { mb: 2, minHeight: 48 },
          severity: severity.severity as any,
          children: <RcTrans i18nKey='shared.multiple-alerts' />,
          action: (
            <ModalTrigger
              title={<RcTrans i18nKey='shared.alerts' />}
              activation={handleOpen => (
                <Button onClick={handleOpen} size='small'>
                  <RcTrans i18nKey='shared.view' />
                </Button>
              )}
            >
              {() => (
                <Stack direction='column' spacing={2} alignItems='stretch'>
                  {issues?.map((issue, idx) => (
                    <RcAlertItem key={idx} issue={issue} />
                  ))}
                </Stack>
              )}
            </ModalTrigger>
          )
        }}
      />
    </>
  )
}
