import { RcTrans } from '@/components/atoms/RcTrans'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import Stack from '@mui/material/Stack'
import React from 'react'
import { JoinFlowContext } from './join-flow-machine'
import { useCompetition } from '@/entity/competition/useCompetition'
import { HandleForm } from '@/components/organisms/handles/HandleForm'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { some } from 'lodash-es'
import { RcButton } from '@/components/molecules/interactive/RcButton'

interface ConfirmHandlesProps {
  context: JoinFlowContext
  onSelected: (values: JoinFlowContext) => void
  onBack?: () => void
}

export const ConfirmHandles: React.FC<ConfirmHandlesProps> = ({
  context,
  onSelected,
  onBack
}) => {
  const { settings } = useCompetition()

  const { handles } = useMyContactAccounts()

  const network1 = settings?.requiredNetwork
  const network2 = settings?.required2ndNetwork

  const canContinue =
    !settings?.requireNetworkUpfront ||
    [network1, network2].every((network, index) => {
      if (!network) return true
      return some(handles, it => it.network === network && it.handle)
    })

  return (
    <Stack direction='column' spacing={3}>
      {network1 ? <HandleForm network={network1} /> : null}
      {network2 ? <HandleForm network={network2} /> : null}

      <ModalConfiguration
        title={
          <RcTrans i18nKey='competition:join-flow.confirm-handles-title' />
        }
        subtitle={
          <RcTrans i18nKey='competition:join-flow.confirm-handles-description' />
        }
        topAction={onBack}
      >
        <RcButton
          disabled={!canContinue}
          variant='contained'
          onClick={() => onSelected(context)}
          fullWidth
        >
          <RcTrans i18nKey='shared.looks-good' />
        </RcButton>
      </ModalConfiguration>
    </Stack>
  )
}
