'use client'

import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenuItems,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { CalendarKindIcon } from '@/components/organisms/calendar/CalendarKindIcon'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { CompetitionEventWithVotes } from '@/components/pages/Competition/components/match/scheduler/scheduler-utils'
import { usePage } from '@/components/providers/site/PageProvider'
import { useKeyPressed } from '@/core/hooks/useKeydown'
import { useIsMdUp, useIsMobileDevice } from '@/core/hooks/useMediaQueries'
import { useOnScreen } from '@/core/hooks/useOnScreen'
import { useTime } from '@/core/hooks/useTime'
import { scrollIntoViewWithOffset, useIsMac } from '@/core/utils'
import { HotkeyItem, useHotkeys } from '@mantine/hooks'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  CompetitionEventCreateCommand,
  CompetitionEventResource,
  CompetitionEventUpdateCommand
} from '@rallycry/api-suite-typescript/dist/models'
import { isEmpty, maxBy, some } from 'lodash-es'
import moment from 'moment-timezone'
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Calendar as BigCalendar,
  EventWrapperProps,
  SlotInfo,
  ToolbarProps,
  momentLocalizer
} from 'react-big-calendar'
import withDragAndDrop, {
  EventInteractionArgs
} from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { UserAvatar } from '../avatar/UserAvatar'
import { ModalCard } from '../modal/ModalCard'
import { calendarTheme } from './calendarTheme'
import { CalendarToolbar } from './CalendarToolbar'

export const localizer = momentLocalizer(moment)
export const DnDCalendar = withDragAndDrop(BigCalendar)

export const Calendar = ({
  height = 700,
  padding = 0,
  title,
  editable,
  views,
  simpleMode,
  events,
  backgroundEvents,
  create,
  update,
  remove,
  remind,
  onSceneCreate,
  createEdit,
  rowActions,
  SideBarComponent
}: {
  height?: number
  padding?: number
  title?: React.ReactNode
  editable?: boolean
  views?: ('month' | 'week' | 'work_week' | 'day' | 'agenda')[]
  simpleMode?: boolean
  events: CompetitionEventResource[]
  backgroundEvents?: CompetitionEventWithVotes[]
  create: (cmd: CompetitionEventCreateCommand) => Promise<any>
  update: (id: number, cmd: CompetitionEventUpdateCommand) => Promise<void>
  remove: (id: number) => Promise<void>
  remind?: (id: number) => Promise<void>
  onSceneCreate?: (scene: CompetitionEventResource) => Promise<any>
  createEdit?: (
    handleClose: () => any,
    resource?: CompetitionEventResource
  ) => ReactNode
  rowActions?: (resource: CompetitionEventResource) => ReactNode
  SideBarComponent?: (props: CalendarSidebarProps) => ReactNode
}) => {
  const { displayEventDateTime } = useTime()
  const [pasteProgress, setPasteProgress] = useState<number>(0)
  const [isCut, setIsCut] = useState(false)
  const [isPasteMode, setIsPasteMode] = useState(false)
  const [editingEvent, setEditingEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [selectedEvent, setSelectedEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [hoveredEvent, setHoveredEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())
  const [view, setView] = useState<
    'month' | 'week' | 'work_week' | 'day' | 'agenda'
  >('month')
  const [toastOpen, setToastOpen] = useState(false)
  const maxBackgroundVotes = maxBy(backgroundEvents, it => it.users?.length)
    ?.users?.length

  const isMac = useIsMac()
  const isMobileDevice = useIsMobileDevice()

  const calendarRef = useRef<HTMLDivElement>()
  const isCalendarVisible = useOnScreen({ ref: calendarRef, rootMargin: '5px' })

  const isMdUp = useIsMdUp()
  const { getNow, displayDateSpan } = useTime()
  const { open, close } = usePage()
  const now = getNow()
  // scroll calendar into view when events are selected on mobile
  useEffect(() => {
    if (selectedEvent && !isCalendarVisible)
      scrollIntoViewWithOffset(calendarRef.current!, 55)
    // disable isCalendarVisible on purpose for proper scroll up behavior on mobile
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent])

  const undoQueue = useRef<(() => any)[]>([])
  const handleUndo = () => {
    const undo = undoQueue.current.pop()
    if (undo) undo()
    close()
  }
  const undoButton = (
    <Button size='small' onClick={handleUndo}>
      Undo
    </Button>
  )

  const simpleHotKeys: HotkeyItem[] = [
    ['mod + z', () => handleUndo()],
    ['backspace', () => handleEventDelete(hoveredEvent)],
    ['del', () => handleEventDelete(hoveredEvent)]
  ]
  const complexHotKeys: HotkeyItem[] = [
    ['escape', () => setIsPasteMode(false)],
    ['mod + c', () => handleCopy(hoveredEvent, false)],
    ['shift + mod + c', () => copyAllEvents()],
    ['mod + x', () => handleCopy(hoveredEvent, true)],
    ['mod + v', () => handleStartPasteMode()],
    [
      'mod + d',
      () =>
        handleEventDuplicate({
          start: hoveredEvent?.startDate,
          end: hoveredEvent?.endDate,
          event: hoveredEvent
        } as any)
    ]
  ]

  useHotkeys(
    simpleMode ? simpleHotKeys : [...simpleHotKeys, ...complexHotKeys],
    ['INPUT', 'TEXTAREA']
  )

  // when shift is down set cloning true
  const cloning = useKeyPressed('Shift')

  const handleEventDelete = async (event?: CompetitionEventResource) => {
    if (!event?.id) return

    await remove(event.id)
    undoQueue.current.push(() => create(event as any))
    setHoveredEvent(undefined)
    setSelectedEvent(undefined)

    !simpleMode && open(undoButton, <>{event.name} deleted</>, 7000)
  }

  const handleEventDuplicate = async (
    args: EventInteractionArgs<CompetitionEventResource>
  ) => {
    if (!editable) return
    const startDate = new Date(args.start)
    const endDate = new Date(args.end)

    const res = await create({
      ...args.event,
      startDate,
      endDate,
      kind: args.event.kind!,
      name: args.event.name!
    })

    return res
    // undoQueue.current.push(() => remove(res.id!))
  }

  const handleEventUpdate = async (
    args: EventInteractionArgs<CompetitionEventResource>
  ) => {
    if (!editable) return
    const startDate = moment(args.start)
    const endDate = moment(args.end)

    if (Math.abs(startDate.diff(endDate, 'minutes')) < 15) {
      endDate.add(15, 'm')
    }

    await update(args.event.id!, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    })

    undoQueue.current.push(() =>
      update(args.event.id!, {
        startDate: args.event.startDate,
        endDate: args.event.endDate
      })
    )

    !simpleMode &&
      open(
        undoButton,
        <>
          {args.event.name} updated to {displayDateSpan(startDate, endDate)}
        </>,
        7000
      )
  }

  const getCopiedEvents = async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      return JSON.parse(clipboard) as CompetitionEventResource[]
    } catch (e) {
      return undefined
    }
  }

  const copyAllEvents = () => {
    navigator.clipboard.writeText(JSON.stringify(events))
  }

  const setCopiedEvent = (event?: CompetitionEventResource) => {
    event
      ? navigator.clipboard.writeText(JSON.stringify([event]))
      : navigator.clipboard.writeText('')
  }

  const handleCopy = async (
    event: CompetitionEventResource | undefined,
    isCut: boolean
  ) => {
    if (!event) return
    setCopiedEvent(event)
    setIsCut(isCut)
    !simpleMode &&
      open(
        undoButton,
        <>
          <Typography>
            {event.name} is in site clipboard. Use {modKey}+v to select a time
            range to paste.
          </Typography>
        </>,
        5000
      )
  }

  const handleStartPasteMode = async () => {
    const copiedEvent = await getCopiedEvents()
    if (!copiedEvent) return alert('No event(s) copied')
    setIsPasteMode(true)
  }

  const handlePaste = async (slot: SlotInfo) => {
    setIsPasteMode(false)
    setPasteProgress(0.01)

    const copiedEvents = await getCopiedEvents()
    if (!copiedEvents || isEmpty(copiedEvents)) return

    const firstEvent = moment(copiedEvents[0].startDate)
    const offsetSource =
      view === 'month'
        ? moment(slot.start)
            .hours(firstEvent.hour())
            .minutes(firstEvent.minute())
        : moment(slot.start)
    const offset = offsetSource.diff(firstEvent, 'seconds')

    const created: number[] = []
    for (const copiedEvent of copiedEvents) {
      const newStartDateTime = moment(copiedEvent.startDate)
        .add(offset, 'seconds')
        .toDate()
      const newEndDateTime = moment(copiedEvent.endDate)
        .add(offset, 'seconds')
        .toDate()

      if (isCut) {
        await handleEventUpdate({
          event: copiedEvent,
          start: newStartDateTime,
          end: newEndDateTime
        })
        setCopiedEvent(undefined)
        setIsCut(false)
      } else {
        const res = await handleEventDuplicate({
          event: copiedEvent,
          start: newStartDateTime,
          end: newEndDateTime
        })
        created.push(res.id!)
      }
      const index = copiedEvents.indexOf(copiedEvent)
      setPasteProgress(((index + 1) / copiedEvents.length) * 100)
    }

    if (some(created)) {
      undoQueue.current.push(() => {
        created.forEach(id => remove(id))
      })

      !simpleMode &&
        open(undoButton, <>{created.length} event(s) pasted</>, 7000)
    }
    setPasteProgress(0)
  }

  const handleEventEdit = async (event?: CompetitionEventResource) => {
    createEdit
      ? setEditingEvent(event)
      : event?.id
        ? update(event?.id!, {
            startDate: event?.startDate,
            endDate: event?.endDate
          })
        : create(event as any)
  }

  const handleEventSelection = (event: CompetitionEventResource) => {
    setSelectedEvent(event)
    if (!editable || view === 'month') {
      setSelectedDate(event.startDate!)
      setView('day')
      return
    }
    handleEventEdit(event)
  }

  const isPastDate = (date: Date) => {
    const todayStart = new Date(now.year(), now.month(), now.date())

    const inputDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    )
    return inputDate < todayStart
  }

  const handleSlotSelection = (slot: SlotInfo) => {
    if (isPasteMode) return handlePaste(slot)

    if (view === 'month' && moment(slot.end).diff(slot.start, 'days') === 1) {
      setSelectedDate(slot.start)
      setView('day')
      return
    }

    if (!editable) {
      setSelectedDate(slot.start)
      setView('day')
      return
    }

    const isDisabled = isPastDate(slot.start)

    if (isDisabled) {
      open(
        null,
        <RcTrans i18nKey='shared.forbidden-calendar-description' />,
        3000
      )
      return
    }

    handleEventEdit({
      startDate: slot.start,
      endDate: slot.end
    })
  }

  const handleRemind = async (event: CompetitionEventResource) => {
    await remind?.(event.id!)
    !simpleMode &&
      open(null, <>{event.name} reminder sent to all participants.</>, 7000)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const [menuEvent, setMenuEvent] = useState<CompetitionEventResource>()

  const components = {
    toolbar: (props: ToolbarProps<CompetitionEventResource>) => (
      <CalendarToolbar {...props} />
    ),
    eventWrapper: ({
      event,
      children
    }: PropsWithChildren<EventWrapperProps<CompetitionEventWithVotes>>) => {
      return (
        <Tooltip
          placement='right'
          title={
            some(event.users) ? (
              <Stack direction='column' spacing={1}>
                {event.users?.map(u => (
                  <UserAvatar key={u.id} user={u} size='small' />
                ))}
              </Stack>
            ) : (
              <Stack direction='column'>
                <Typography variant='subtitle2'>
                  {displayEventDateTime(event.startDate!, event.endDate)}
                </Typography>
                <Typography variant='subtitle2'>{event.name}</Typography>
              </Stack>
            )
          }
          followCursor
        >
          <Box
            onClick={() => handleEventSelection(event)}
            onMouseEnter={() => setHoveredEvent(event)}
            onMouseLeave={() => setHoveredEvent(undefined)}
            onContextMenu={e => {
              e.preventDefault()
              e.stopPropagation()
              setMenuEvent(event)
              setAnchorEl(e.currentTarget)
              setMouseX(e.clientX)
              setMouseY(e.clientY)
            }}
            sx={{
              '& .rbc-background-event': {
                opacity: event.users?.length! / (maxBackgroundVotes || 1)
              }
            }}
          >
            {children}
          </Box>
        </Tooltip>
      )
    }
  }

  const props: React.ComponentProps<typeof DnDCalendar> = {
    getNow: () => getNow()?.toDate(),
    step: 15,
    components: components,
    selected: selectedEvent,
    onSelectEvent: () => {}, // handled by event wrapper
    selectable: editable,
    onSelectSlot: handleSlotSelection,
    date: selectedDate,
    onNavigate: setSelectedDate,
    views: some(views)
      ? views
      : isMdUp
        ? ['month', 'week', 'day']
        : ['month', 'day'],
    view: some(views)
      ? views?.[0]
      : !isMdUp && view === 'week'
        ? 'month'
        : view,
    onView: setView,
    onEventDrop: cloning ? handleEventDuplicate : handleEventUpdate,
    onEventResize: handleEventUpdate,
    resizable: editable,
    localizer: localizer,
    events: events,
    backgroundEvents: backgroundEvents,
    scrollToTime: selectedEvent?.startDate || moment().hour(10).toDate(),
    eventPropGetter: (it: CompetitionEventWithVotes) => ({
      className: it === hoveredEvent ? 'rbc-event-hovered' : 'rbc-event'
    }),
    titleAccessor: (it: CompetitionEventWithVotes) => {
      const startDate = moment(it.startDate!)
      const endDate = moment(it.endDate!)
      const duration = endDate.diff(startDate)
      const isLessThan24h = duration <= 24 * 60 * 60 * 1000

      if (!isLessThan24h)
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            {simpleMode ? null : (
              <CalendarKindIcon kind={it.kind!} size='2xs' />
            )}
            <Typography variant='body2' noWrap>
              {it.name}
            </Typography>
          </Stack>
        )
      return (
        <Stack direction='column'>
          <Stack direction='row' alignItems='center' spacing={1}>
            {simpleMode ? null : (
              <CalendarKindIcon kind={it.kind!} size='2xs' />
            )}
            <Typography variant='body2' noWrap>
              {moment(startDate).format('hh:mma')}
            </Typography>
          </Stack>

          {view !== 'month' ? (
            <EllipsisBox>
              <Typography variant='body2' noWrap>
                {it.name}
              </Typography>
            </EllipsisBox>
          ) : null}
        </Stack>
      ) as any
    },
    startAccessor: (it: CompetitionEventResource) => it.startDate!,
    endAccessor: (it: CompetitionEventResource) => it.endDate!,
    formats: {
      monthHeaderFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'MMMM YYYY' : 'MMM YYYY')
      },
      timeGutterFormat: (date: Date) => {
        return isMdUp
          ? moment(date).format('hh:mm a')
          : moment(date).minute() === 0
            ? moment(date).format('ha')
            : ''
      },
      dayHeaderFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'dddd MMM DD' : 'ddd MMM DD')
      },
      dayFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'ddd D' : 'ddd')
      },
      weekdayFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'ddd' : 'dd')
      }
    },
    style: {
      height
    }
  }

  const modKey = isMac ? '⌘' : 'Ctrl'
  const iconProps = { inline: true, size: '1xs' as any }
  const menuItems = (
    menuEvent?: CompetitionEventResource
  ): ActionMenuOption[] => {
    const res: ActionMenuOption[] = []

    if (simpleMode) return res
    if (menuEvent && editable) {
      res.push(
        ...[
          {
            key: 'edit',
            display: 'Edit',
            icon: <RcIcon icon={['fal', 'calendar-edit']} {...iconProps} />,
            shortcut: 'Click/Drag',
            action: async () => handleEventEdit(menuEvent)
          },
          {
            key: 'cut',
            display: 'Cut',
            icon: <RcIcon icon={['fal', 'cut']} {...iconProps} />,
            shortcut: `${modKey}+X`,
            action: () => handleCopy(menuEvent, true)
          },
          {
            key: 'copy',
            display: 'Copy',
            icon: <RcIcon icon={['fal', 'copy']} {...iconProps} />,
            shortcut: `${modKey}+C`,
            action: async () => handleCopy(menuEvent, false)
          },

          {
            key: 'copy-all',
            display: 'Copy All Events',
            icon: <RcIcon icon={['fal', 'book-copy']} {...iconProps} />,
            shortcut: `shift+${modKey}+C`,
            action: async () => copyAllEvents()
          },
          {
            key: 'paste',
            display: 'Paste',
            icon: <RcIcon icon={['fal', 'paste']} {...iconProps} />,
            shortcut: `${modKey}+V`,
            action: async () => handleStartPasteMode()
          },
          {
            key: 'duplicate',
            display: 'Duplicate',
            icon: <RcIcon icon={['fal', 'clone']} {...iconProps} />,
            shortcut: `Shift+Drag or ${modKey}+D`,
            action: () =>
              handleEventDuplicate({
                start: menuEvent.startDate,
                end: menuEvent.endDate,
                event: menuEvent
              } as any)
          },
          {
            key: 'delete',
            display: 'Delete',
            icon: <RcIcon icon={['fal', 'trash-alt']} {...iconProps} />,
            shortcut: `Del`,
            action: () => handleEventDelete(menuEvent),
            divider: true
          }
        ]
      )
    }

    if (remind && menuEvent && editable) {
      res.push({
        key: 'remind',
        display: 'Send Reminder',
        icon: <RcIcon icon={['fal', 'bell']} {...iconProps} />,
        action: async () => handleRemind(menuEvent),
        divider: true
      })
    }

    if (!menuEvent && editable) {
      res.push({
        key: 'paste',
        display: 'Paste',
        icon: <RcIcon icon={['fal', 'paste']} {...iconProps} />,
        shortcut: `${modKey}+V`,
        action: async () => handleStartPasteMode()
      })
      res.push({
        key: 'copy-all',
        display: 'Copy All Events',
        icon: <RcIcon icon={['fal', 'book-copy']} {...iconProps} />,
        shortcut: `Ctrl+${modKey}+C`,
        action: async () => copyAllEvents(),
        divider: true
      })
    }

    if (editable) {
      res.push({
        key: 'create-event',
        display: <RcTrans i18nKey='shared.create-new-event' />,
        icon: <RcIcon icon={['fal', 'calendar-plus']} {...iconProps} />,
        action: async () =>
          handleEventEdit(
            selectedDate
              ? {
                  startDate: moment(selectedDate).add(12, 'hour').toDate(),
                  endDate: moment(selectedDate).add(13, 'hour').toDate()
                }
              : {}
          )
      })
    }

    if (menuEvent && onSceneCreate) {
      res.push({
        key: 'create-scene',
        display: (
          <RcTrans i18nKey='competition:schedule.create-countdown-scene' />
        ),
        icon: <RcIcon icon={['fal', 'video']} {...iconProps} />,
        action: async () => onSceneCreate(menuEvent)
      })
    }

    return res
  }

  return (
    <>
      <Tooltip
        title={
          isPasteMode
            ? `Select a timeslot to paste ${
                isMobileDevice ? '' : '(ESC to cancel)'
              }`
            : undefined
        }
        followCursor
        onContextMenu={e => {
          e.preventDefault()
          setMenuEvent(hoveredEvent)
          setAnchorEl(anchorEl ? undefined : e.currentTarget)
          setMouseX(e.clientX)
          setMouseY(e.clientY)
        }}
      >
        <Grid container direction='row'>
          <Grid item sm={12} md={SideBarComponent ? 8 : 12} sx={{ padding }}>
            <Box sx={calendarTheme}>
              <Box ref={calendarRef} />
              {title}
              {/* key with selected event to force scroll-to logic to fire on selectedEvent change */}
              {editable ? (
                <DnDCalendar key={selectedEvent?.id} {...props} />
              ) : (
                <BigCalendar key={selectedEvent?.id} {...props} />
              )}
            </Box>

            <ModalTrigger
              open={!!editingEvent}
              onClose={() => handleEventEdit(undefined)}
            >
              {({ handleClose }) => createEdit?.(handleClose, editingEvent)}
            </ModalTrigger>
          </Grid>
          {SideBarComponent ? (
            <Grid item xs={12} md={4}>
              <SideBarComponent
                events={events}
                editable={editable}
                createEdit={createEdit}
                setView={setView}
                setSelectedEvent={setSelectedEvent}
                setSelectedDate={setSelectedDate}
                hoveredEvent={hoveredEvent}
                setHoveredEvent={setHoveredEvent}
                rowActions={rowActions}
                height={height}
                menuItems={menuItems}
              />
            </Grid>
          ) : null}
          <Menu
            id='calendar-context'
            open={Boolean(anchorEl) && some(menuItems(menuEvent))}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(undefined)}
            anchorReference='anchorPosition'
            anchorPosition={{ top: mouseY, left: mouseX }}
          >
            <ActionMenuItems
              options={menuItems(menuEvent)}
              onAction={it => {
                setAnchorEl(undefined)
                it.action?.()
              }}
            />
          </Menu>
          <ModalCard
            title={
              pasteProgress === 1
                ? 'Successfully Pasted Events'
                : 'Pasting Events...'
            }
            open={pasteProgress > 0}
            onClose={() => setPasteProgress(0)}
          >
            <Stack direction='column' spacing={2} p={3}>
              <Typography variant='h4'>
                Pasting {Math.round(pasteProgress)}%
              </Typography>
              <LinearProgress variant='determinate' value={pasteProgress} />
            </Stack>
          </ModalCard>
        </Grid>
      </Tooltip>
    </>
  )
}

export interface CalendarSidebarProps {
  events: CompetitionEventResource[]
  editable?: boolean
  createEdit?: (handleClose: () => any) => ReactNode
  setView: (view: 'month' | 'week' | 'work_week' | 'day' | 'agenda') => void
  setSelectedEvent: (event: CompetitionEventResource) => void
  setSelectedDate: (date: Date) => void
  hoveredEvent?: CompetitionEventResource
  setHoveredEvent: (event?: CompetitionEventResource) => void
  rowActions?: (resource: CompetitionEventResource) => ReactNode
  height: number
  menuItems: (menuEvent?: CompetitionEventResource) => ActionMenuOption[]
}
