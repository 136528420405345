import {
  BracketMatchControllerApi,
  ViewPersonalRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { orderBy } from 'lodash-es'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionParticipant } from '../competition/useCompetitionParticipant'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBracketMatchesPersonal = (
  options?: EntityOptions<ViewPersonalRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { ctrl } = useController(BracketMatchControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketMatchesPersonal',
    dedupingInterval: 1000,
    expand: '_links,content{bracket{settings},event,games,assignments{entry}',
    ...options,
    request: {
      competitionId,
      state: [MatchState.OPEN, MatchState.PENDING, MatchState.COMPLETE],
      ...options?.request
    },
    read: req =>
      ctrl({
        expectedStatuses: [401, 403, 404],
        fallbackResponse: {}
      }).viewPersonal(req)
  })

  const reset = async (id: number) => {
    await ctrl().resetBracketMatch({
      id,
      bracketMatchResetCommand: {}
    })
    await entity.query.mutate()
  }

  const remove = async (id: number) => {
    await ctrl().removeBracketMatch({ id })
    await entity.query.mutate()
  }

  return {
    ...entity,
    personal: orderBy(entity.flat, it => it.id),
    reset,
    remove
  }
}
