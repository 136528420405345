import Box from '@mui/material/Box'
import Chip, { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { AccessLevel } from '@rallycry/api-suite-typescript/dist/models/AccessLevel'
import { CommunityMemberDocument } from '@rallycry/api-suite-typescript/dist/models/CommunityMemberDocument'
import { SidType } from '@rallycry/api-suite-typescript/dist/models/SidType'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ModalCard } from '../modal/ModalCard'
import { ModalProvider } from '../modal/ModalProvider'
import { UserViewer } from '../user/UserViewer'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import { getUserDisplayName } from '@/core/utils'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import Stack from '@mui/material/Stack'
import { ModalTrigger } from '../modal/ModalTrigger'

/*
 * Avatar wrapper that provides user modal when clicked. Also merges additional contacts into modal for match views.
 */
interface UserAvatarProps extends AvatarTextProps {
  chipProps?: ChipProps[]
  user: UserResource
  extra?: React.ReactNode
  textColor?: string
  disableClick?: boolean
  rcVariant?: 'chip' | 'text' | 'avatar' | 'standard'
}

export const UserAvatar = ({
  chipProps,
  user,
  extra,
  textColor,
  rcVariant = 'standard',
  disableClick,
  ...avatarProps
}: UserAvatarProps) => {
  const [open, setOpen] = useState(false)

  const handleOpen = async () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const hasFullName = !!user?.givenName
  const displayName = getUserDisplayName(user)

  const standardOrAvatar = () => (
    <AvatarText
      src={user?.image}
      alt='User avatar'
      isOnline={user?.activeRecently}
      onClick={
        disableClick
          ? undefined
          : ev => {
              ev.stopPropagation()
              return handleOpen()
            }
      }
      avatarChildren={user?.name?.charAt(0)?.toLocaleUpperCase()}
      {...avatarProps}
      badgeIcon={
        user?.ownerType === SidType.COMMUNITY_OFFICER
          ? ['fal', 'ghost']
          : avatarProps.badgeIcon
      }
      badgeTooltip={
        user?.ownerType === SidType.COMMUNITY_OFFICER ? (
          <RcTrans i18nKey='shared.placeholder-profile' />
        ) : (
          avatarProps.badgeTooltip
        )
      }
    >
      {rcVariant === 'avatar' ? null : (
        <>
          <Stack direction='row' spacing={2} alignItems={'center'}>
            <Typography variant='body0' noWrap textAlign='left'>
              {displayName}
            </Typography>
            {chipProps
              ? chipProps.map((chip, idx) => (
                  <Chip key={idx} size='small' {...chip} />
                ))
              : null}
          </Stack>
          {extra ? (
            extra
          ) : hasFullName ? (
            <Typography
              variant='subtitle2'
              color={textColor || 'text.secondary'}
              textAlign='left'
              sx={{ mt: 1 }}
            >
              {user?.name}
            </Typography>
          ) : null}
        </>
      )}
    </AvatarText>
  )

  return (
    <>
      {{
        chip: () => (
          <Chip
            label={`@${user?.name}`}
            onClick={() => handleOpen()}
            color='primary'
            sx={{
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' }
            }}
          />
        ),
        text: () => (
          <Box
            display='inline'
            onClick={ev => {
              ev.stopPropagation()
              return handleOpen()
            }}
            sx={{
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            {user?.name}
          </Box>
        ),
        avatar: standardOrAvatar,
        standard: standardOrAvatar
      }[rcVariant]()}

      <ModalTrigger
        open={open}
        onClose={handleClose}
        modalProps={{
          noFooter: true,
          noHeader: true,
          noPadding: true
        }}
      >
        {() => (
          <RouteParamOverrideProvider
            context={{ userId: user?.id?.toString() }}
          >
            <UserViewer userId={user?.id} isModal />
          </RouteParamOverrideProvider>
        )}
      </ModalTrigger>
    </>
  )
}

interface CommunityMemberDocumentAvatarProps
  extends Omit<UserAvatarProps, 'user'> {
  member: CommunityMemberDocument
}

export const CommunityMemberDocumentAvatar = ({
  member,
  ...entityAvatarProps
}: CommunityMemberDocumentAvatarProps) => {
  return (
    <UserAvatar
      {...entityAvatarProps}
      user={member.member!}
      badgeTooltip={member.level}
      badgeIcon={
        member.level === AccessLevel.ADMINISTRATOR
          ? ['fal', 'flag']
          : member.level === AccessLevel.MODERATOR
            ? ['fal', 'pennant']
            : undefined
      }
    />
  )
}
