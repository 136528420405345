import { CompetitionEventReminderControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEventReminderControllerApi'
import { CompetitionEventReminderCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventReminderCreateCommand'
import { CompetitionEventReminderUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventReminderUpdateCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionEventReminders = (options?: EntityOptions<any>) => {
  const { ctrl } = useController(CompetitionEventReminderControllerApi)

  const eventId = Number(options?.idOrKey)
  const entity = useQueryEntity({
    key: 'useCompetitionEventReminders',
    expand: '',
    paused: !options?.idOrKey,
    ...options,
    request: { eventId },

    read: () => ctrl().queryCompetitionEventReminders({ eventId }),
    create: (cmd: CompetitionEventReminderCreateCommand) =>
      ctrl().createCompetitionEventReminder({
        eventId,
        competitionEventReminderCreateCommand: cmd
      }),
    update: (id, cmd: CompetitionEventReminderUpdateCommand) =>
      ctrl().updateCompetitionEventReminder({
        id,
        competitionEventReminderUpdateCommand: cmd
      }),
    remove: id => ctrl().removeCompetitionEventReminder({ id })
  })

  return {
    ...entity,
    reminders: entity.flat
  }
}
