import { clone, first, filter } from 'lodash-es'
import {
  CommunityMemberDocumentControllerApi,
  SearchCommunityMembersByCommunityRequest
} from '@rallycry/api-suite-typescript/dist/apis/CommunityMemberDocumentControllerApi'
import { CommunityMemberControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CommunityMemberControllerApi'
import { CommunityMemberCommand } from '@rallycry/api-suite-typescript/dist/models/CommunityMemberCommand'
import { CommunityMemberResource } from '@rallycry/api-suite-typescript/dist/models/CommunityMemberResource'
import { AccessLevel } from '@rallycry/api-suite-typescript/dist/models/AccessLevel'
import { useMemo } from 'react'
import { useGateway } from '../site/useGateway'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useUserAccount } from '../user/useUserAccount'
import { useOrganization } from '../organization/useOrganization'
import { useCommunity } from './useCommunity'
import { useCommunityId } from './useCommunityId'
import { tryExtractJsonError } from '@/core/utils'
import { useController } from '@/core/hooks/useSWRApi'

export const useCommunityMembers = (
  options?: EntityOptions<SearchCommunityMembersByCommunityRequest>
) => {
  const communityId = useCommunityId(options)
  const { account } = useUserAccount()
  const { read: userMeta } = useUserMeta()
  const { community, isCommunityMember } = useCommunity()
  const { refresh: refreshGateway } = useGateway()
  const { refresh: refreshOrganization, isOrgModerator } = useOrganization()
  const { ctrl: memberCtrl } = useController(CommunityMemberControllerApi)
  const { ctrl } = useController(CommunityMemberDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCommunityMembers',
    paused: community?.confidential && !isCommunityMember && !isOrgModerator,
    ...options,
    request: {
      communityId,
      ...options?.request,
      q: options?.request?.q || undefined
    },
    read: req =>
      ctrl({
        expectedStatuses: [403, 404],
        fallbackResponse: []
      }).searchCommunityMembersByCommunity(req)
  })

  const mutateUserCommunities = async () =>
    userMeta.mutate({ communities: userMeta.data!.communities! + 1 }, false)

  const join = async (id: number, cmd: CommunityMemberCommand) => {
    try {
      const req = { id, communityMemberCommand: cmd }
      await memberCtrl({
        expectedStatuses: [403, 418, 422, 500]
      }).saveSelfCommunityMember(req)
      await mutateUserCommunities()
    } catch (error) {
      const data = await tryExtractJsonError(error)
      console.log(data)
      const domainsMissing = data?.issues?.[0]?.context?.domainsMissing
      if (domainsMissing) {
        return domainsMissing as string[]
      } else {
        throw error
      }
    }
  }

  const leave = async () => {
    await memberCtrl().removeSelfCommunityMember({ id: communityId })

    await Promise.allSettled([
      refreshGateway(),
      refreshOrganization(),
      mutateUserCommunities(),
      entity.query.mutate(
        d => [
          {
            content: entity.flat.filter(it => it.member?.id !== account?.id),
            totalElements: first(d)?.totalElements! - 1
          }
        ],
        false
      )
    ])
  }

  const getMember = (member: CommunityMemberResource) =>
    entity.flat.find(it => it.member?.id === member?.member?.id)

  const updateMember = async (
    member: CommunityMemberResource,
    cmd: CommunityMemberCommand
  ) => {
    const res = await memberCtrl().saveCommunityMember({
      id: communityId,
      memberId: member.member?.id!,
      communityMemberCommand: cmd,
      expand: '_links'
    })
    const existing = getMember(member)
    if (existing) {
      existing.level = res.level
      existing.roleName = res.roleName
    }
    entity.query.mutate(
      d => [
        {
          content: entity.flat?.map(it => clone(it)),
          totalElements: first(d)?.totalElements
        }
      ],
      false
    )
  }

  const promote = async (member: CommunityMemberResource) => {
    const existing = getMember(member)
    return updateMember(member, {
      level:
        existing?.level === AccessLevel.NORMAL
          ? AccessLevel.MODERATOR
          : AccessLevel.ADMINISTRATOR
    })
  }

  const demote = async (member: CommunityMemberResource) => {
    const existing = getMember(member)
    return updateMember(member, {
      level:
        existing?.level === AccessLevel.ADMINISTRATOR
          ? AccessLevel.MODERATOR
          : AccessLevel.NORMAL
    })
  }

  const remove = async (communityId: number, memberId: number) => {
    await memberCtrl().removeCommunityMember({
      id: communityId,
      memberId
    })
    await entity.query.mutate()
  }

  const moderators = useMemo(
    () => filter(entity.flat, it => it.level !== AccessLevel.NORMAL),
    [entity.flat]
  )

  return {
    ...entity,
    moderators,
    members: entity.flat,
    join,
    leave,
    promote,
    demote,
    remove,
    updateMember
  }
}
