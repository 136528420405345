import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FormQuestionKind } from '@rallycry/api-suite-typescript/dist/models/FormQuestionKind'
import { FormQuestionResource } from '@rallycry/api-suite-typescript/dist/models/FormQuestionResource'
import { FormResponseEditApproval } from './Edit/FormResponseEditApproval'
import { FormResponseEditAssignment } from './Edit/FormResponseEditAssignment'
import { FormResponseEditCheckboxes } from './Edit/FormResponseEditCheckboxes'
import { FormResponseEditDateTime } from './Edit/FormResponseEditDateTime'
import { FormResponseFileUpload } from './Edit/FormResponseEditFileUpload'
import { FormResponseEditRadio } from './Edit/FormResponseEditRadio'
import { FormResponseEditScale } from './Edit/FormResponseEditScale'
import { FormResponseEditSelect } from './Edit/FormResponseEditSelect'
import { FormResponseEditSignature } from './Edit/FormResponseEditSignature'
import { FormResponseEditText } from './Edit/FormResponseEditText'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useCuratedForm } from '@/entity/curated-forms/useCuratedForm'

export interface FormResponseEditProps {
  responseId?: number
  question: FormQuestionResource
  canLock?: boolean
}

export const FormResponseQuestionEdit = (props: FormResponseEditProps) => {
  const { answers, updateFormAnswer } = useFormResponse({
    idOrKey: props.responseId
  })
  return (
    <Box>
      <LabeledFieldHeader
        label={
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            {props.question?.name}
            {props.canLock ? (
              <RcIconButton
                icon={['fal', 'unlock']}
                size='small'
                disableRipple
                IconProps={{ size: 'xs' }}
                onClick={() =>
                  updateFormAnswer(
                    answers?.find(it => it.question?.id === props.question?.id)
                      ?.id!,
                    { locked: true }
                  )
                }
              />
            ) : null}
          </Stack>
        }
        description={props.question.disambiguatingDescription}
      />
      {{
        [FormQuestionKind.APPROVAL]: () => (
          <FormResponseEditApproval {...props} />
        ),
        [FormQuestionKind.ASSIGNMENT]: () => (
          <FormResponseEditAssignment {...props} />
        ),
        [FormQuestionKind.CHECKBOXES]: () => (
          <FormResponseEditCheckboxes {...props} />
        ),
        [FormQuestionKind.DATE]: () => (
          <FormResponseEditDateTime {...props} showTimeSelect={false} />
        ),
        [FormQuestionKind.DATE_TIME]: () => (
          <FormResponseEditDateTime {...props} showTimeSelect />
        ),
        [FormQuestionKind.FILE_UPLOAD]: () => (
          <FormResponseFileUpload {...props} />
        ),
        [FormQuestionKind.LONG_TEXT]: () => (
          <FormResponseEditText {...props} multiline />
        ),
        [FormQuestionKind.RADIO]: () => <FormResponseEditRadio {...props} />,
        [FormQuestionKind.SCALE]: () => <FormResponseEditScale {...props} />,
        [FormQuestionKind.SELECT]: () => <FormResponseEditSelect {...props} />,
        [FormQuestionKind.SHORT_TEXT]: () => (
          <FormResponseEditText {...props} />
        ),
        [FormQuestionKind.SIGNATURE]: () => (
          <FormResponseEditSignature {...props} />
        )
      }[props.question.kind!]?.()}
    </Box>
  )
}
