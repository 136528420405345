import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useTimeoutWindow } from '@/core/hooks/useTimeoutWindow'
import { TranslationNamespace } from '@/core/translation'
import { useConfig } from '@/entity/site/useConfig'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useUserMeta } from '@/entity/user/useUserMeta'
import { InputAdornment, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { LabeledFieldHeader } from '../form/LabeledField'
import { HandleFormProps } from './HandleForm'

export const OauthHandleForm: React.FC<HandleFormProps> = ({
  network,
  audience,
  onSuccess,
  onError,
  canRefresh
}) => {
  const [refreshTooltip, setRefreshTooltip] = useState<string>()

  const {
    read: { mutate }
  } = useUserMeta()
  const { handles, remove: removeHandle, refresh } = useMyContactAccounts()
  const config = useConfig()
  const { getNetworkString, getNetworkIcon } = useNetworkKinds()
  const { t } = useRcTranslation([TranslationNamespace.Profile])
  const localizedNetworkString = getNetworkString(network)
  const icon = getNetworkIcon(network)
  const handle = handles.find(handle => handle.network === network)
  const append = audience ? `-${audience}` : ''

  const showLink = useTimeoutWindow(
    config.api + `/oauth2/authorization/${network.toLowerCase()}${append}`,
    () => mutate(),
    onError
  )

  useEffect(() => {
    if (handles?.find(handle => handle.network === network)) {
      onSuccess?.()
    }
  }, [handles, onSuccess, network])

  const handleRefresh = async (id: number) => {
    try {
      setRefreshTooltip(`...`)
      await refresh(id)
      setRefreshTooltip(`${t('profile:provider-refreshed')}!`)
      setTimeout(() => setRefreshTooltip(''), 4000)
    } catch (e) {
      console.log(e)
      setRefreshTooltip(t('shared.error'))
    }
  }

  const isLocked = !handle?._links?._delete

  return (
    <Box>
      <LabeledFieldHeader label={localizedNetworkString} />
      <TextField
        fullWidth
        onClick={handle?.handle ? undefined : showLink}
        value={
          handle?.handle ||
          t('profile:connect-provider', { network: localizedNetworkString })
        }
        slotProps={{
          htmlInput: {
            sx: { cursor: 'pointer' }
          },
          input: {
            sx: { cursor: 'pointer' },
            startAdornment: (
              <InputAdornment position='start'>
                <RcIcon icon={icon} />
              </InputAdornment>
            ),
            endAdornment: handle?.id ? (
              <InputAdornment position='end'>
                {canRefresh ? (
                  <RcIconButton
                    size='small'
                    icon={['fal', 'refresh']}
                    TooltipProps={{
                      title: refreshTooltip || (
                        <RcTrans i18nKey='profile:refresh-provider' />
                      )
                    }}
                    onClick={() => handleRefresh(handle?.id!)}
                  />
                ) : null}
                {isLocked ? null : (
                  <RcIconButton
                    size='extraSmall'
                    color='error'
                    icon={['fal', 'trash']}
                    onClick={() => removeHandle(handle?.id!)}
                  />
                )}
              </InputAdornment>
            ) : null
          }
        }}
      />
    </Box>
  )
}
