import { useParams } from 'next/navigation'
import { RcAlertDisplay } from '@/components/organisms/site/RcAlertDisplay'
import { useCompetitionTutorial } from '@/entity/competition/useCompetitionTutorial'

export const CompetitionAlerts = () => {
  const { competitionId } = useParams()
  const { issues } = useCompetitionTutorial()

  return <RcAlertDisplay issues={issues} id={`competition-${competitionId}`} />
}

export const CompetitionAlertsV2 = () => {
  const { competitionId } = useParams()
  const { alerts } = useCompetitionTutorial()

  return <RcAlertDisplay issues={alerts} id={`competition-${competitionId}`} />
}
