import { CompetitionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionControllerApi'
import { SearchCompetitionEntriesRequest } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryDocumentControllerApi'
import { CompetitionEventControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEventControllerApi'
import { CompetitionEventReminderControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEventReminderControllerApi'
import {
  CompetitionFormControllerApi,
  CreateCompetitionFormRequest,
  UpdateCompetitionFormRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionFormControllerApi'
import {
  CompetitionFormFilterControllerApi,
  CreateCompetitionFormFilterRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionFormFilterControllerApi'
import { CompetitionEventCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventCreateCommand'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { CompetitionEventUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventUpdateCommand'
import { CompetitionFormFilterResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionFormFilterResource'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { findLast, isArray, orderBy, some } from 'lodash-es'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionMeta } from './useCompetitionMeta'
import { expandById, ExpansionType } from '@/core/expand'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'
import { useTime } from '@/core/hooks/useTime'

export const useCompetitionEvents = (
  options?: EntityOptions<SearchCompetitionEntriesRequest>
) => {
  const parsedId = useParsedParam('competitionId')
  const { getNow } = useTime()
  const { read: meta } = useCompetitionMeta(options)
  const { ctrl: competitionCtrl } = useController(CompetitionControllerApi)
  const { ctrl: competitionFormCtrl } = useController(
    CompetitionFormControllerApi
  )
  const { ctrl: competitionFormFilterCtrl } = useController(
    CompetitionFormFilterControllerApi
  )
  const { ctrl: reminderCtrl } = useController(
    CompetitionEventReminderControllerApi
  )
  const { ctrl } = useController(CompetitionEventControllerApi)

  const competitionId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useCompetitionEvents',
    expand: 'content{_links,forms{form,filters{bracket}}}',
    paused: !competitionId,
    ...options,
    request: { competitionId, ...options?.request },
    metas: [meta],
    read: (req, { events }) =>
      ctrl({ metas: { events } }).readCompetitionEvents(req)
  })

  const create = async (cmd: CompetitionEventCreateCommand) => {
    //optimistic create
    entity.query.mutate(
      data =>
        data?.map(it => ({
          ...it,
          content: isArray(it.content)
            ? [...it?.content?.map(that => ({ ...that }))!, { ...cmd, id: 0 }]
            : [{ ...cmd, id: 0 }]
        })),
      {
        revalidate: false
      }
    )

    const res = await competitionCtrl().createCompetitionEvent({
      id: competitionId,
      competitionEventCreateCommand: cmd
    })
    await meta.mutate()
    return res
  }

  const update = async (id: number, cmd: CompetitionEventUpdateCommand) => {
    // optimistic update
    entity.query.mutate(
      data =>
        data?.map(it => ({
          ...it,
          content: it?.content?.map(that =>
            that.id === id ? { ...that, ...cmd } : that
          )
        })),
      {
        revalidate: false
      }
    )

    await ctrl()
      .updateCompetitionEvent({
        id,
        competitionEventUpdateCommand: cmd
      })
      .then(() => meta.mutate())
  }

  const remove = async (id: number) => {
    // optimistic update
    entity.query.mutate(
      data =>
        data?.map(it => ({
          ...it,
          content: it?.content?.filter(that => that.id !== id)
        })),
      {
        revalidate: false
      }
    )

    await ctrl()
      .removeCompetitionEvent({
        id
      })
      .then(() => meta.mutate())
  }

  const remindEvent = async (eventId: number) => {
    await ctrl().remindCompetitionEvent({
      id: eventId
    })
  }

  const events = useCallback(
    (filter?: EventKind[], notFilter?: EventKind[]) => {
      const filtered = entity.flat?.filter(
        it =>
          (!filter || filter?.includes(it.kind!)) &&
          !notFilter?.includes(it.kind!)
      )
      const sorted = orderBy(filtered, ['startDate', 'id'])
      const now = getNow()
      const previous = findLast<CompetitionEventResource>(sorted, ev =>
        now.isAfter(ev.endDate)
      )
      const current = sorted.find(
        ev => now.isAfter(ev.startDate) && now.isBefore(ev.endDate)
      )
      const next = sorted.find(ev => now.isBefore(ev.startDate))
      const final = sorted[sorted.length - 1]
      const allCurrentOrPrevious = sorted.filter(
        ev => ev.startDate! < now.toDate()
      )

      return {
        allCurrentOrPrevious,
        previous,
        current,
        next,
        final,
        all: sorted
      }
    },
    [entity.flat, getNow]
  )

  const isRegistrationActive = !!events([EventKind.REGISTRATION])?.current

  const hasRegistrationStarted = events([EventKind.REGISTRATION])?.all?.find(
    it => it.startDate! < new Date()
  )

  const getIsRosterLocked = () => {
    const locks = events([EventKind.ROSTER_LOCK])

    return some(locks.allCurrentOrPrevious)
  }

  const isRosterLocked = getIsRosterLocked()

  const cloneEvent = async (
    resource: CompetitionEventResource,
    interval: 'hour' | 'day' | 'week'
  ) => {
    const res = await entity.create({
      name: resource.name!,
      kind: resource.kind!,
      description: resource.description,
      startDate: moment(resource.startDate).add(1, interval).toDate(),
      endDate: moment(resource.endDate).add(1, interval).toDate()
    })

    const reminders = await reminderCtrl().queryCompetitionEventReminders({
      eventId: resource?.id!
    })

    if (reminders.content) {
      for (const reminder of reminders.content) {
        await reminderCtrl().createCompetitionEventReminder({
          eventId: res?.id,
          competitionEventReminderCreateCommand: reminder
        })
      }
    }
  }

  const createForm = async (
    req: CreateCompetitionFormRequest,
    bracketFilters: number[]
  ) => {
    const res = await competitionFormCtrl().createCompetitionForm(req)

    for (const bracket of bracketFilters) {
      await competitionFormFilterCtrl().createCompetitionFormFilter({
        competitionFormId: res.id!,
        competitionFormFilterCreateCommand: { bracket }
      })
    }

    await meta.mutate(
      s => ({ ...s, events: s?.events! + 1 + bracketFilters.length }),
      false
    )
  }

  const updateForm = async (
    req: UpdateCompetitionFormRequest,
    bracketFilters: number[]
  ) => {
    const res = await competitionFormCtrl().updateCompetitionForm({
      ...req,
      expand: 'filters{bracket}}'
    })

    const current = res._expanded?.competitionBracket?.map(it => it.id!)
    const additions = bracketFilters.filter(it => !current?.includes(it)) || []
    const removals =
      res.filters
        ?.filter(it => {
          const expanded = expandById<CompetitionFormFilterResource>(
            it.id,
            res._expanded,
            ExpansionType.CompetitionFormFilter
          )
          return !bracketFilters.includes(expanded?.bracket?.id!)
        })
        .map(it => it.id!) || []

    // create new,
    for (const bracket of additions) {
      await competitionFormFilterCtrl().createCompetitionFormFilter({
        competitionFormId: res.id!,
        competitionFormFilterCreateCommand: { bracket }
      })
    }

    // remove old
    for (const bracket of removals) {
      await competitionFormFilterCtrl().removeCompetitionFormFilter({
        id: bracket
      })
    }

    await meta.mutate(
      s => ({ ...s, events: s?.events! + 1 + bracketFilters.length }),
      false
    )
  }

  const removeForm = async (id: number) => {
    await competitionFormCtrl().removeCompetitionForm({ id })
    await meta.mutate(s => ({ ...s, events: s?.events! + 1 }), false)
  }

  const createFormFilter = async (req: CreateCompetitionFormFilterRequest) => {
    await competitionFormFilterCtrl().createCompetitionFormFilter(req)
    await meta.mutate(s => ({ ...s, events: s?.events! + 1 }), false)
  }

  const removeFormFilter = async (id: number) => {
    await competitionFormFilterCtrl().removeCompetitionFormFilter({ id })
    await meta.mutate(s => ({ ...s, events: s?.events! + 1 }), false)
  }

  const handleEventUpdates = async (
    created: CompetitionEventResource[],
    updated: CompetitionEventResource[],
    removed: CompetitionEventResource[],
    onComplete: () => void
  ) => {
    const creating = created.map(
      async event =>
        await competitionCtrl().createCompetitionEvent({
          id: competitionId,
          competitionEventCreateCommand: event as any
        })
    )

    const updating = updated.map(
      async event =>
        await ctrl().updateCompetitionEvent({
          id: event?.id!,
          competitionEventUpdateCommand: event as any
        })
    )

    const removing = removed.map(
      async event => await ctrl().removeCompetitionEvent({ id: event?.id! })
    )

    await Promise.all([...creating, ...updating, ...removing])
    await meta.mutate()

    onComplete()
  }

  // force a refresh for events() without hitting server
  const refresh = () =>
    entity.query.mutate(
      data =>
        data?.map(it => ({
          ...it,
          content: [...it.content?.map(that => ({ ...that }))!]
        })),
      {
        revalidate: false
      }
    )

  return {
    ...entity,
    create,
    update,
    remove,
    refresh,
    events,
    remindEvent,
    isRegistrationActive,
    hasRegistrationStarted,
    isRosterLocked,
    cloneEvent,
    createForm,
    updateForm,
    removeForm,
    createFormFilter,
    removeFormFilter,
    handleEventUpdates
  }
}
