import { ScheduleTypography } from '@/components/molecules/text/ScheduleTypography'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useTime } from '@/core/hooks/useTime'
import { RootRoute } from '@/core/route-keys'
import { CompetitionDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionDocument'
import { DiscoverCardvNext } from './DiscoverCardvNext'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ChipProps } from '@mui/material'
import { DiscoverCard } from './DiscoverCard'
import { ListingMode } from '@rallycry/api-suite-typescript/dist/models'
import { RcIcon } from '@/components/atoms/RcIcon'
import { CHIPINATOR_DELIMITER } from '@/components/molecules/text/Chipinator'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { ChipGrid } from '@/components/molecules/text/ChipGrid'

export const CompetitionDocumentCard = ({
  googleTag,
  resource,
  onTagSelected
}: {
  googleTag?: string
  resource: CompetitionDocument
  onTagSelected?: (tag: string) => void
}) => {
  const { getNow } = useTime()
  const {
    featOldDiscoveryCards,
    featCompetitionDiscoveryAvatars,
    featCompetitionDiscoveryBanners,
    imgCommunityBanner
  } = useFeatures()

  const defaultBannerImageSrc = imgCommunityBanner as string
  const now = getNow()
  const activeEvent = resource.events?.find(
    it => now.isAfter(it.startDate) && now.isBefore(it.endDate)
  )
  const hiddenChip =
    resource?.listingMode === ListingMode.NONE
      ? ({
          label: 'Hidden',
          color: 'default',
          icon: <RcIcon icon={['fal', 'eye']} size='xs' />
        } as ChipProps)
      : null
  const gameChip = resource?.game
    ? ({
        label: resource.game?.name,
        color: 'primary',
        icon: (
          <RcAvatar
            variant='rounded'
            alt={resource.game?.name}
            src={resource.game?.icon || resource.game?.image}
            sx={{ width: 18, height: 18 }}
          />
        ),
        onClick: () => onTagSelected?.(resource.game?.name!),
        sx: { cursor: 'pointer' }
      } as ChipProps)
    : null
  const chipinatorValues =
    resource?.disambiguatingDescription?.split(CHIPINATOR_DELIMITER) || []

  if (featOldDiscoveryCards) {
    return (
      <DiscoverCard
        avatarImage={
          featCompetitionDiscoveryAvatars
            ? resource.image || resource.game?.icon
            : undefined
        }
        headerImage={
          featCompetitionDiscoveryBanners
            ? resource.image || resource.game?.image || defaultBannerImageSrc
            : undefined
        }
        navigation={{
          root: RootRoute.Competition,
          rootId: resource.id
        }}
        googleTag={googleTag}
        key={resource.id}
        title={resource.name}
        content={
          <Stack display='flex' direction='row' spacing={1} alignItems='center'>
            <Box>
              {activeEvent ? (
                <ScheduleTypography
                  startDate={activeEvent?.startDate}
                  endDate={activeEvent?.endDate}
                  name={activeEvent?.name}
                />
              ) : null}
            </Box>
          </Stack>
        }
        primaryChips={[hiddenChip, gameChip].filter(it => !!it)}
        secondaryChips={chipinatorValues.map(it => ({
          label: it,
          color: 'primary',
          onClick: () => onTagSelected?.(it),
          sx: { cursor: 'pointer' }
        }))}
      />
    )
  }

  return (
    <DiscoverCardvNext
      title={resource.name}
      pinned={resource.listingMode === ListingMode.PROMOTED}
      showAvatar={!!featCompetitionDiscoveryAvatars}
      avatarSrc={resource.image || resource.game?.icon}
      showBanner={!!featCompetitionDiscoveryBanners}
      bannerSrc={
        resource.profile?.cardImage ||
        resource.game?.image ||
        resource.profile?.bannerImage ||
        defaultBannerImageSrc
      }
      navigation={{
        root: RootRoute.Competition,
        rootId: resource.id
      }}
      googleTag={googleTag}
      status={
        activeEvent ? (
          <Typography variant='subtitle2'>
            <ScheduleTypography
              startDate={activeEvent?.startDate}
              endDate={activeEvent?.endDate}
              name={activeEvent?.name}
            />
          </Typography>
        ) : null
      }
      extraContent={
        resource.disambiguatingDescription ? (
          <ChipGrid
            chipProps={chipinatorValues.map(it => ({
              label: it,
              onClick: () => onTagSelected?.(it),
              sx: { cursor: 'pointer' }
            }))}
          />
        ) : null
      }
      gameTitle={resource.game?.name}
      gameSrc={resource.game?.icon || resource.game?.image}
    />
  )
}
