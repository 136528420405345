import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { CompetitionRegistrationActions } from './CompetitionRegistrationActions'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useCompetition } from '@/entity/competition/useCompetition'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'

export const CompetitionActions = () => {
  const { profile } = useCompetition()
  const isMobile = useIsMobile()
  const buttonSize = isMobile ? 'small' : 'medium'

  const discordLink: string | undefined = profile?.primaryDiscordInviteUrl

  const handleDiscordClick = () => {
    if (discordLink) {
      window.open(discordLink)
    }
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      {discordLink ? (
        <RcIconButton
          size={buttonSize}
          key={discordLink}
          onClick={handleDiscordClick}
          icon={['fab', 'discord']}
        />
      ) : null}
      <ContactFlowLink>
        <RcIconButton
          size={buttonSize}
          IconProps={{ size: 'xs' }}
          icon={['fal', 'messages-question']}
        />
      </ContactFlowLink>
      <Box pl={1}>
        <CompetitionRegistrationActions />
      </Box>
    </Stack>
  )
}
