import { CompetitionRulesControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionRulesControllerApi'
import { CompetitionRulesUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionRulesUpdateCommand'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionRules = (options?: EntityOptions) => {
  const competitionId = useParsedParam('competitionId')
  const { ctrl } = useController(CompetitionRulesControllerApi)
  const { read: competitionMeta } = useCompetitionMeta()

  const entity = useReadEntity({
    key: 'useCompetitionRules',
    idOrKey: competitionId,
    expand: '_links',
    metas: [competitionMeta],
    ...options,
    read: (req, { root }) =>
      ctrl({ metas: { root } }).readCompetitionRules(req),
    update: (_, cmd: CompetitionRulesUpdateCommand, expand) =>
      ctrl().updateCompetitionRules({
        id: competitionId,
        competitionRulesUpdateCommand: cmd,
        expand
      })
  })

  return { ...entity, rules: entity.read.data }
}
