import { isEmpty } from 'lodash-es'
import Button from '@mui/material/Button'
import {
  BracketKind,
  BracketState,
  EventKind
} from '@rallycry/api-suite-typescript'
import { BracketCreateEditForm } from '../bracket/BracketCreateEdit/BracketCreateEditForm'
import { RcScheduler } from '../../../../molecules/input/RcScheduler'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useCompetition } from '@/entity/competition/useCompetition'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'
import { useBrackets } from '@/entity/bracket/useBrackets'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { ExpansionType, expand } from '@/core/expand'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'
import { LADDER_KINDS } from '../_Competition'

export const useCompetitionAlertsBrackets = () => {
  const { isCompetitionModerator } = useCompetition()
  const { events, handleEventUpdates } = useCompetitionEvents()
  const { brackets } = useBrackets()
  const { t } = useRcTranslation([TranslationNamespace.Competition])

  const issues: TutorialItem[] = []

  if (!isCompetitionModerator) {
    return issues
  }

  // competition is over, show winners
  if (isEmpty(brackets)) {
    issues.push({
      type: TutorialType.TASK,
      severity: 'info',
      header: <RcTrans i18nKey='competition:issues.no-brackets-title' />,
      children: (
        <RcTrans i18nKey='competition:issues.no-brackets-description' />
      ),
      action: (
        <ModalTrigger
          activation={handleOpen => (
            <Button
              onClick={handleOpen}
              size='medium'
              sx={{ whiteSpace: 'nowrap' }}
            >
              <RcTrans i18nKey='competition:bracket.create-new-bracket' />
            </Button>
          )}
        >
          {({ handleClose }) => (
            <BracketCreateEditForm onComplete={handleClose} />
          )}
        </ModalTrigger>
      )
    })
  }

  const ladder = brackets?.find(it => {
    const settings = expand(
      it,
      it._expanded,
      ExpansionType.CompetitionBracketSettings
    )
    return LADDER_KINDS.includes(settings?.kind)
  })

  const existing = events([EventKind.LADDER]).all
  if (ladder && !existing.length) {
    issues.push({
      type: TutorialType.TASK,
      severity: 'info',
      header: <RcTrans i18nKey='competition:issues.no-ladder-title' />,
      children: <RcTrans i18nKey='competition:issues.no-ladder-description' />,
      action: (
        <PreventParentClick>
          <ModalTrigger
            modalProps={{
              maxWidth: 'md',
              noPadding: true,
              noHeader: true,
              noFooter: true
            }}
            activation={handleOpen => (
              <Button
                onClick={handleOpen}
                size='medium'
                sx={{ whiteSpace: 'nowrap' }}
              >
                <RcTrans i18nKey='competition:bracket.add-slots' />
              </Button>
            )}
          >
            {({ handleClose }) => (
              <RcScheduler
                existing={existing}
                onComplete={({ created, updated, removed }) =>
                  handleEventUpdates(created, updated, removed, handleClose)
                }
                kind={EventKind.LADDER}
                title={t('competition:ladder.add-slots-title')}
                subtitle={t('competition:ladder.add-slots-subtitle')}
                label={t('competition:ladder.add-slots-label')}
                submit={t('shared.submit')}
              />
            )}
          </ModalTrigger>
        </PreventParentClick>
      )
    })
  }

  return issues
}
