import { CompetitionParticipantControllerApi } from '@rallycry/api-suite-typescript/dist/apis'
import {
  EntryMemberControllerApi,
  QueryEntryMembersRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberControllerApi'
import {
  CompetitionEntryResource,
  CompetitionParticipantCreateCommand,
  CompetitionParticipantInvolvementCommand,
  CompetitionParticipantResource,
  UserResource
} from '@rallycry/api-suite-typescript/dist/models'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionTeam } from './useCompetitionTeam'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { expandById, ExpansionType } from '@/core/expand'
import { RequiredNetworksMenu } from '@/components/pages/Competition/components/teams/RequiredNetworksMenu'
import { useCompetition } from '../competition/useCompetition'

export const useCompetitionTeamMembers = (
  options?: EntityOptions<QueryEntryMembersRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const parsedId = useParsedParam('teamId')

  const { read: competitionMeta } = useCompetitionMeta({
    idOrKey: competitionId
  })
  const { requiredNetworks } = useCompetition()

  const { ctrl: participantCtrl } = useController(
    CompetitionParticipantControllerApi
  )

  const { ctrl } = useController(EntryMemberControllerApi)

  const teamId = Number(options?.idOrKey) || parsedId
  const entity = useQueryEntity({
    key: 'useCompetitionTeamMembers',
    expand: 'content{representing,participant,_links}',
    paused: !teamId,
    ...options,
    request: {
      entryId: teamId,
      ...options?.request
    },
    read: (req, { root }) => ctrl({ metas: { root } }).queryEntryMembers(req)
  })

  const {
    team,
    update,
    read: { mutate: mutateEntry }
  } = useCompetitionTeam({ idOrKey: teamId })

  const addMember = async (
    userId: number,
    cmd: Partial<CompetitionParticipantCreateCommand>
  ) => {
    const res = await participantCtrl({
      expectedStatuses: [403]
    }).createCompetitionParticipant({
      competitionId,
      competitionParticipantCreateCommand: {
        entry: teamId,
        skipValidateRequiredNetworks: true,
        ...cmd,
        participant: userId,
        authorizedNetworks: requiredNetworks
      }
    })

    await Promise.allSettled([
      competitionMeta.mutate(),
      mutateEntry(),
      entity.query.mutate()
    ])

    return res
  }

  const removeMember = async (participantId: number) => {
    await participantCtrl().removeCompetitionParticipant({
      id: participantId
    })
    await Promise.allSettled([
      competitionMeta.mutate(),
      mutateEntry(),
      entity.query.mutate()
    ])
  }

  const updateMember = async (
    participantId: number,
    cmd: CompetitionParticipantInvolvementCommand
  ) => {
    await participantCtrl().updateCompetitionParticipantInvolvement({
      id: participantId,
      competitionParticipantInvolvementCommand: cmd
    })

    await Promise.allSettled([
      competitionMeta.mutate(),
      mutateEntry(),
      entity.query.mutate()
    ])
  }

  const transferLeadership = async (participantId: number) => {
    await update(teamId, { leader: participantId })
  }

  const currentLeader = team?.leader?.id!
  const roster = entity.flat.map(it => ({
    ...it,
    user: {
      rank: getRank(it, currentLeader),
      ...(expandById(
        it?.participant?.id,
        it._expanded,
        ExpansionType.User
      ) as UserResource)
    }
  }))

  return {
    ...entity,
    roster,
    addMember,
    removeMember,
    updateMember,
    transferLeadership
  }
}

const getRank = (it: CompetitionParticipantResource, leaderId: number) => {
  const isOwner = it?.id === leaderId
  if (it.management && it.player && isOwner) return TeamRank.HeadCaptain
  if (it.management && it.player) return TeamRank.Captain
  if (it.management && isOwner) return TeamRank.HeadCoach
  if (it.management) return TeamRank.Coach
  if (it.player) return TeamRank.Member
  return TeamRank.Substitute
}

// keep as number based enum for ez sorting
export enum TeamRank {
  HeadCaptain = 0,
  Captain = 1,
  Member = 2,
  Substitute = 3,
  HeadCoach = 4,
  Coach = 5,
  Invited = 6
}
