'use client'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CuratedFormResource } from '@rallycry/api-suite-typescript/dist/models/CuratedFormResource'
import { FormQuestionKind } from '@rallycry/api-suite-typescript/dist/models/FormQuestionKind'
import { FormSectionResource } from '@rallycry/api-suite-typescript/dist/models/FormSectionResource'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { last, orderBy, take } from 'lodash-es'
import { useMemo } from 'react'
import { FormSectionAssignmentMode } from '@rallycry/api-suite-typescript/dist/models'
import { FormResponseQuestionDisplay } from './FormResponseQuestionDisplay'
import { FormResponseQuestionEdit } from './FormResponseQuestionEdit'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { ChipinatorDisplay } from '@/components/molecules/text/Chipinator'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { Page } from '@/components/organisms/page/Page'
import { PageHeader } from '@/components/organisms/page/PageHeader'
import { PageItem } from '@/components/organisms/page/PageItem'
import { ExpansionType, expander } from '@/core/expand'
import { getUserDisplayName } from '@/core/utils'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useFormResponse } from '@/entity/curated-forms/useFormResponse'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { usePage } from '@/components/providers/site/PageProvider'

export const FormResponseSinglePage = () => {
  const responseId = useParsedParam('responseId')

  const { response } = useFormResponse({
    idOrKey: responseId
  })

  const user = expander<UserResource>(response, ExpansionType.User)
  const form = expander<CuratedFormResource>(
    response,
    ExpansionType.CuratedForm
  )

  const competition = response?._expanded?.competition?.[0]
  const notice = response?._expanded?.competitionForm?.[0]?.notice
  const profile = response?._expanded?.competitionProfile?.[0]
  const game = response?._expanded?.game?.[0]
  return (
    <Page>
      {competition ? (
        <PageHeader
          ImageDisplayProps={{
            path: profile?.bannerImage || game?.image || game?.icon
          }}
          AvatarProps={{ src: competition?.image || game?.icon }}
          name={competition?.name}
          description={
            <ChipinatorDisplay value={competition?.disambiguatingDescription} />
          }
        />
      ) : null}
      <PageHeader
        AvatarProps={
          user?.image
            ? {
                src: user?.image
              }
            : undefined
        }
        name={form?.name}
        description={
          <Typography variant='body0'>{getUserDisplayName(user)}</Typography>
        }
      >
        <Box
          sx={{
            whiteSpace: 'pre-line'
          }}
        >
          {notice}
        </Box>
      </PageHeader>

      <PageItem contained={false}>
        <SectionDisplay responseId={response?.id} />
      </PageItem>
    </Page>
  )
}

export const SectionDisplay = ({
  responseId,
  isModal
}: {
  responseId?: number
  isModal?: boolean
}) => {
  const { isCompetitionModerator } = useCompetition()
  const { isOrgModerator } = useOrganization()
  const { response, answers, getAuthenticatedLink } = useFormResponse({
    idOrKey: responseId
  })
  const { open } = usePage()

  // create a linked list of form sections -> next form section,
  // or conditionally based on formOption.next
  const linked = useMemo(() => {
    if (!response) return []
    const sorted = orderBy(response?._expanded?.formSection, 'ordinal')
    const getNextSection = (previous: FormSectionResource | undefined) => {
      // the default "next" section unless a navigable option overrides it
      const section = response?._expanded?.formSection?.find(
        it => previous?.next?.id === it.id
      )

      // filtered ids for navigable questions from the previous section
      const previousNavigableQuestions = response?._expanded?.formQuestion
        ?.filter(
          question =>
            question.section?.id === previous?.id && question.navigable
        )
        ?.map(it => it.id)

      // look through existing answers for a selection option
      // within the previous section's navigable questions
      const answerWithNavigation = answers?.find(
        answer =>
          previousNavigableQuestions?.includes(answer.question?.id) &&
          response?._expanded?.formOption?.find(
            option => option.next && option.id === answer.option?.id
          )
      )

      // get the id of the optional next section
      const nextOption = response?._expanded?.formOption?.find(
        option => answerWithNavigation?.option?.id === option.id
      )?.next?.id

      // get reference to the actual section for optional next
      const nextOptional = response?._expanded?.formSection?.find(
        it => it.id === nextOption
      )

      return nextOptional || section
    }

    // first section always visible
    const linkedList = take(sorted, 1)
    let complete = false
    let count = 0
    do {
      const previous = last(linkedList)
      const next = getNextSection(previous)

      if (next) {
        linkedList.push(next)
      } else {
        complete = true
      }

      count++
    } while (!complete && count < 20)
    return linkedList
  }, [answers, response])

  return (
    <Stack direction='column' spacing={5}>
      {linked?.map(section => {
        const isActiveSection = response?.assignedSection?.id === section?.id

        const handleCopy = async () => {
          try {
            const question = response?._expanded?.formQuestion?.find(
              it =>
                it.section?.id === section?.id &&
                it.kind === FormQuestionKind.ASSIGNMENT
            )
            const answer = response?._expanded?.formAnswer?.find(
              it => it.question?.id === question?.id
            )
            const res = await getAuthenticatedLink(answer?.id!)
            navigator.clipboard.writeText(res.url || '')
            open(
              undefined,
              <RcTrans i18nKey='shared.copied-to-clipboard' />,
              3000
            )
          } catch (error) {
            open(undefined, (error as any)?.response?.status, 3000)
          }
        }

        return (
          <Card
            key={section.id}
            sx={theme => ({
              borderWidth: isActiveSection ? 2 : 1,
              borderColor: isActiveSection
                ? theme.palette.primary.main
                : theme.palette.divider
            })}
          >
            <CardContent>
              <SectionHeader
                title={section.name}
                subtitle={section.disambiguatingDescription}
              >
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='center'
                  spacing={1}
                >
                  {isActiveSection ? (
                    <Typography
                      variant='body1'
                      textAlign='right'
                      color='text.secondary'
                    >
                      <RcTrans i18nKey='shared.assigned' />
                    </Typography>
                  ) : null}
                  {section.assignmentMode ===
                    FormSectionAssignmentMode.RESPONDER &&
                  (isCompetitionModerator || isOrgModerator) ? (
                    <RcIconButton
                      icon={['fal', 'lock-hashtag']}
                      size='small'
                      onClick={() => handleCopy()}
                      TooltipProps={{
                        title: (
                          <RcSuspense skipLoader>
                            <RcTrans i18nKey='administration:copy-form-auth-link' />
                          </RcSuspense>
                        )
                      }}
                    />
                  ) : null}
                </Stack>
              </SectionHeader>
              <FormResponseSection
                key={section.id}
                responseId={responseId}
                section={section}
                isModal={isModal}
              />
            </CardContent>
          </Card>
        )
      })}
    </Stack>
  )
}

const FormResponseSection = ({
  responseId,
  section,
  isModal
}: {
  responseId?: number
  section: FormSectionResource
  isModal?: boolean
}) => {
  const { response, answers } = useFormResponse({
    idOrKey: responseId
  })

  const { account } = useUserAccount()
  const formOwner = expander<UserResource>(response, ExpansionType.User)
  const form = expander<CuratedFormResource>(
    response,
    ExpansionType.CuratedForm
  )
  const canUpdate = !!form?._links?.update

  const isFormOwner = account?.id === formOwner?.id
  const canCreateMissingAnswers =
    // completed forms should not allow creation of missing answers
    !response?.dateCompleted &&
    // form creators can always create answers
    (canUpdate ||
      (section.assignmentMode === FormSectionAssignmentMode.NONE
        ? // for non-assignment sections, the form submitter should be able to create missing answers
          isFormOwner
        : // for the active assignment section, the person assigned (who we can assume is not the form submitter)
          // should be able to create missing answers
          !isFormOwner && response?.assignedSection?.id === section?.id))

  const questions = response?._expanded?.formQuestion?.filter(
    it => it.section?.id === section?.id
  )

  return (
    <Grid container direction='row' spacing={5}>
      {questions?.map(it => (
        <Grid key={it.id} item xs={12} sm={isModal ? 12 : 6}>
          {answers?.find(that => that.question?.id === it?.id)?._links
            ?.update ||
          (canCreateMissingAnswers &&
            !answers?.find(that => that.question?.id === it?.id)) ? (
            <FormResponseQuestionEdit
              key={it.id}
              responseId={responseId}
              question={it}
              canLock={canUpdate}
            />
          ) : (
            <FormResponseQuestionDisplay
              key={it.id}
              responseId={responseId}
              question={it}
              canUnlock={canUpdate}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
