import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CollectionRelToHrefs } from '@rallycry/api-suite-typescript/dist/models/CollectionRelToHrefs'
import { CompetitionBracketSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsResource'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { ContactAccountResource } from '@rallycry/api-suite-typescript/dist/models/ContactAccountResource'
import { MatchGameReportResource } from '@rallycry/api-suite-typescript/dist/models/MatchGameReportResource'
import { MatchGameResource } from '@rallycry/api-suite-typescript/dist/models/MatchGameResource'
import { MatchGameResultCommand } from '@rallycry/api-suite-typescript/dist/models/MatchGameResultCommand'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { useMachine } from '@xstate/react'
import { countBy, every, first, isUndefined, last, some } from 'lodash-es'
import moment from 'moment-timezone'
import { assign, createMachine } from 'xstate'
import { WinCondition } from '@rallycry/api-suite-typescript'
import { TranslatedSourcedGameDetails } from '@/entity/bracket-match/useSourcedGames'

interface MatchGameReportResourceWithLinks extends MatchGameReportResource {
  _links?: CollectionRelToHrefs
}
export interface MatchResultsContext {
  // externally provided data
  isAdmin?: boolean
  isPlayerOrCoach?: boolean
  isBothTeams?: boolean // user is a participant/coach on both teams
  bracketAssignment1?: BracketAssignmentResource
  bracketAssignment2?: BracketAssignmentResource
  bracketSettings?: CompetitionBracketSettingsResource
  externalNetwork?: NetworkKind
  externalNetworkId?: string
  contactAccounts?: ContactAccountResource[]
  match?: BracketMatchResource
  matchGames?: MatchGameResource[]
  matchGameReports?: MatchGameReportResourceWithLinks[]
  sourcedGames?: TranslatedSourcedGameDetails[]
  competition?: CompetitionResource
  participant?: CompetitionParticipantResource | null

  // machine determined data
  scoreSubmissions: (MatchGameResultCommand & { isLocked?: boolean })[]
  selectedSourcedGames: string[]
  preselectedSourcedGames?: boolean
  assignedWinner?: number

  error?: Error
}

export type MatchResultsActions =
  | { type: 'BACK' }
  | {
      type: 'SET_CONTEXT'
      match?: BracketMatchResource
      matchGames?: MatchGameResource[]
      matchGameReports?: MatchGameReportResource[]
    }
  | {
      type: 'ADD_SCORE'
      ordinal: number
      winner: number | undefined
      score1?: number
      score2?: number
    }
  | { type: 'REFRESH_SOURCED_GAMES' }
  | { type: 'TOGGLE_SOURCED_GAME'; id: string }
  | { type: 'REPORT_SOURCED_GAMES' }
  | { type: 'BACK_TO_SOURCED_GAMES' }
  | { type: 'SKIP_SOURCED_GAMES' }
  | { type: 'REPORT_SCORES' }
  | { type: 'FORCE_SCORES' }
  | { type: 'ACCEPT' }
  | { type: 'DECLINE' }
  | { type: 'CONFLICT' }
  | {
      type: 'SET_WINNER'
      winner?: number
    }
  | { type: 'SET_TIE' }
  | { type: 'RESET_MATCH' }
  | { type: 'ADMIN_REPORT_GAMES' }

const getScoreSubmission = (context: MatchResultsContext, ordinal: number) => {
  const found = context.scoreSubmissions.find(it => it.ordinal === ordinal)
  if (!found) throw Error('cannot find ordinal')
  return found
}

const canSubmitScores = (context: MatchResultsContext) =>
  (context.bracketSettings?.winCondition === WinCondition.BEST_OF &&
    context.scoreSubmissions.filter(it => it.winner! !== -1).length ===
      context.bracketSettings.winConditionAmount) ||
  some(
    countBy(
      context.scoreSubmissions.filter(it => !!it.winner),
      it => it.winner
    ),
    it =>
      context.bracketSettings?.winCondition === WinCondition.BEST_OF
        ? it > context.match?.winConditionAmount! / 2
        : it >= context.match?.winConditionAmount!
  )

export const MatchResultsMachine = createMachine<
  MatchResultsContext,
  MatchResultsActions
>(
  {
    predictableActionArguments: true,
    id: 'MatchResults',
    initial: 'determine',
    context: {
      scoreSubmissions: [],
      selectedSourcedGames: []
    },
    on: {
      SET_CONTEXT: {
        actions: assign((context, event) => ({
          match: event.match || context.match,
          matchGames: event.matchGames || context.matchGames,
          matchGameReports: event.matchGameReports || context.matchGameReports
        }))
      },
      // admin functions available regardless of state
      RESET_MATCH: {
        cond: 'canResetMatch',
        target: 'resetMatch'
      },
      SET_WINNER: {
        cond: 'isAdmin',
        actions: assign((_, event) => ({
          assignedWinner: event.winner
        })),
        target: '.adminSetResult'
      },
      SET_TIE: {
        cond: 'isAdmin',
        actions: assign(_ => ({
          assignedWinner: undefined // undefined "set" winner = tie
        })),
        target: '.adminSetResult'
      },
      ADMIN_REPORT_GAMES: {
        cond: 'canAdminReportGames',
        target: '#MatchResults.reportScores'
      }
    },
    states: {
      // only load sourced games if first report
      determine: {
        always: [
          { cond: 'hasScoresReported', target: 'reviewScores' },
          { cond: 'hasExternalNetwork', target: 'sourcedGames.initializing' },
          { target: 'reportScores' }
        ]
      },
      sourcedGames: {
        states: {
          initializing: {
            invoke: {
              src: 'loadSourcedGames',
              onDone: {
                actions: [
                  assign((_, event) => ({
                    sourcedGames: event.data
                  })),
                  'setPreselected'
                ],
                target: 'idle'
              },
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: 'error'
              }
            }
          },
          idle: {
            on: {
              TOGGLE_SOURCED_GAME: { actions: 'toggleSourcedGame' },
              REPORT_SOURCED_GAMES: {
                cond: 'canSubmitSourcedScores',
                target: 'translateSourcedGames'
              }
            }
          },
          error: {},
          translateSourcedGames: {
            entry: 'translateSourcedGames',
            always: { target: 'submitting' }
          },
          submitting: {
            invoke: {
              src: 'submitScores',
              onDone: '#MatchResults.reviewScores',
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: 'error'
              }
            }
          }
        },
        on: {
          REFRESH_SOURCED_GAMES: {
            target: '#MatchResults.sourcedGames.initializing'
          },
          SKIP_SOURCED_GAMES: { target: '#MatchResults.reportScores' }
        }
      },
      reportScores: {
        initial: 'idle',
        states: {
          idle: {
            entry: 'updateScoreSubmissions',
            on: {
              ADD_SCORE: [
                {
                  cond: 'isNumericMode',
                  actions: 'assignNumericScore',
                  // should transition back to self as external event to re-trigger entry logic
                  internal: false,
                  target: 'idle'
                },
                {
                  // win/loss mode
                  actions: 'assignWinLossScore',
                  // should transition back to self as external event to re-trigger entry logic
                  internal: false,
                  target: 'idle'
                }
              ],
              REPORT_SCORES: {
                cond: 'canSubmitScores',
                target: 'submitting'
              },
              FORCE_SCORES: {
                cond: 'canForceScores',
                target: 'forcing'
              },
              BACK_TO_SOURCED_GAMES: [
                {
                  cond: 'hasSourcedGames',
                  target: '#MatchResults.sourcedGames.idle'
                },
                {
                  cond: 'hasExternalNetwork',
                  target: '#MatchResults.sourcedGames.initializing'
                }
              ]
            }
          },
          submitting: {
            invoke: {
              src: 'submitScores',
              onDone: '#MatchResults.reviewScores',
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: 'idle'
              }
            }
          },
          forcing: {
            invoke: {
              src: 'forceScores',
              onDone: 'idle',
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: 'idle'
              }
            }
          }
        }
      },
      reviewScores: {
        always: [
          { cond: 'isDisputed', target: 'disputed' },
          { cond: 'isFinalized', target: 'finalized' }
        ],
        initial: 'idle',
        states: {
          idle: {
            on: {
              ACCEPT: { cond: 'canAccept', target: 'accept' },
              DECLINE: { cond: 'canDecline', target: 'decline' },
              CONFLICT: {
                cond: 'canConflict',
                target: '#MatchResults.reportScores'
              }
            }
          },
          accept: {
            invoke: {
              src: 'accept',
              onDone: 'idle',
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: 'idle'
              }
            }
          },
          decline: {
            invoke: {
              src: 'decline',
              onDone: '#MatchResults.disputed',
              onError: {
                actions: assign((_, event) => ({
                  error: event.data
                })),
                target: '#MatchResults.disputed'
              }
            }
          }
        }
      },
      disputed: {},
      finalized: {},
      adminSetResult: {
        invoke: {
          src: 'setWinner',
          onDone: 'reviewScores',
          onError: {
            actions: assign((_, event) => ({
              error: event.data
            })),
            target: 'determine'
          }
        }
      },
      resetMatch: {
        invoke: {
          src: 'resetMatch'
        }
      }
    }
  },
  {
    guards: {
      hasExternalNetwork: context =>
        !!context.externalNetwork && !!context.externalNetworkId,
      hasSourcedGames: context => some(context.sourcedGames),
      isAdmin: context => !!context.isAdmin,
      isNumericMode: context => !!context?.bracketSettings?.numericGameScore,

      hasScoresReported: context => some(context.matchGameReports),

      isFinalized: context => context.match?.state === MatchState.COMPLETE,
      isDisputed: context =>
        !!context.match?.dateDisputed && !context.match.dateResolved,

      canAdminReportGames: context =>
        !!context.isAdmin && context.match?.state !== MatchState.COMPLETE,
      canAccept: context => !!first(context.matchGameReports)?._links?.accept,
      canDecline: context => !!first(context.matchGameReports)?._links?.decline,
      canConflict: context =>
        !!first(context.matchGameReports)?._links?.accept &&
        !first(context.matchGameReports)?._links?.decline &&
        moment(context.match?.negotiableUntil).isAfter(moment()),
      canSubmitScores: context =>
        !!context.isPlayerOrCoach &&
        !context.isBothTeams && // when participant of both teams, use the force scores flow
        canSubmitScores(context),
      canSubmitSourcedScores: context =>
        !!context.isPlayerOrCoach &&
        some(
          countBy(
            context.sourcedGames?.filter(it =>
              context.selectedSourcedGames.includes(it.id!)
            ),
            it => it.winningTeamIndex
          ),
          it => it >= context.match?.winConditionAmount!
        ),
      canForceScores: context =>
        (!!context.isAdmin || !!context.isBothTeams) &&
        some(context.scoreSubmissions, it => !it.isLocked && it.winner! !== -1),
      canResetMatch: context => !!context.isAdmin
    },
    actions: {
      assignNumericScore: assign((context, event) => {
        if (event.type !== 'ADD_SCORE') return {}
        const found = getScoreSubmission(context, event.ordinal)
        found.scores = found.scores || [0, 0]
        if (!isUndefined(event.score1)) {
          found.scores[0] = event.score1
        }
        if (!isUndefined(event.score2)) {
          found.scores[1] = event.score2
        }
        found.winner =
          found.scores[0] > found.scores[1]
            ? context.bracketAssignment1?.id
            : found.scores[0] < found.scores[1]
              ? context.bracketAssignment2?.id
              : undefined
        return { scoreSubmissions: context.scoreSubmissions }
      }),
      assignWinLossScore: assign((context, event) => {
        if (event.type !== 'ADD_SCORE') return {}
        const found = getScoreSubmission(context, event.ordinal)
        found.winner = event.winner
        return { scoreSubmissions: context.scoreSubmissions }
      }),
      setPreselected: assign(context => {
        const winConditionAmount = context.match?.winConditionAmount!
        let totalScore1 = 0
        let totalScore2 = 0
        const source = context.sourcedGames || []
        const selectedSourcedGames: string[] = []

        for (let i = 0; i < source.length; i++) {
          // max possible games reported
          if (totalScore1 + totalScore2 >= winConditionAmount * 2 - 1) break

          const game = source[i]
          const hasOpponent = some(
            // only examine the opposing team's players
            game.players?.filter(that => that.teamIndex !== game.teamIndex),
            player =>
              some(
                context.contactAccounts,
                account => account.externalId === player.id
              )
          )
          if (!hasOpponent) continue

          const score1 = game.score1 || 0
          const score2 = game.score2 || 0

          if (score1 > score2 && totalScore1 < winConditionAmount) {
            totalScore1++
            selectedSourcedGames.push(game.id!)
          } else if (score2 > score1 && totalScore2 < winConditionAmount) {
            totalScore2++
            selectedSourcedGames.push(game.id!)
          }
        }

        return {
          selectedSourcedGames,
          preselectedSourcedGames: some(selectedSourcedGames)
        }
      }),
      toggleSourcedGame: assign((context, event) => {
        if (event.type !== 'TOGGLE_SOURCED_GAME') return {}
        return {
          selectedSourcedGames: context.selectedSourcedGames.includes(event.id)
            ? context.selectedSourcedGames.filter(it => it !== event.id)
            : [...context.selectedSourcedGames, event.id]
        }
      }),
      translateSourcedGames: assign(context => {
        return {
          scoreSubmissions: context.sourcedGames
            ?.filter(it => context.selectedSourcedGames.includes(it.id!))
            .reverse()
            .map((it, idx) => ({
              ordinal: idx + 1,
              fromSource: {
                id: it.id!,
                startDate: it.startDate!,
                map: it.map?.id!,
                endDate: it.endDate!,
                network: context.bracketSettings?.externalGameNetwork!,
                s: it.s!
              },
              winner:
                it.winningTeamIndex === 1
                  ? context.bracketAssignment1?.id
                  : context.bracketAssignment2?.id,
              scores: [it.score1!, it.score2!]
            }))
        }
      }),
      updateScoreSubmissions: assign({
        scoreSubmissions: context => {
          const winConditionAmount = context?.match?.winConditionAmount || 0
          const { bracketAssignment1, bracketAssignment2 } = context
          const result = []
          let team1Wins = 0
          let team2Wins = 0

          for (const submission of context.scoreSubmissions) {
            // set winner based on numeric scores, if provided
            if (submission.scores) {
              const [score1, score2] = submission.scores
              submission.winner = undefined
              if (score1 > score2) {
                submission.winner = bracketAssignment1?.id
              } else if (score1 < score2) {
                submission.winner = bracketAssignment2?.id
              }
            }

            if (
              context.bracketSettings?.winCondition === WinCondition.BEST_OF &&
              (team1Wins >= winConditionAmount / 2 ||
                team2Wins >= winConditionAmount / 2)
            ) {
              break
            }

            if (submission?.winner === bracketAssignment1?.id) {
              team1Wins++
            }
            if (submission?.winner === bracketAssignment2?.id) {
              team2Wins++
            }

            result.push(submission)

            if (
              team1Wins >= winConditionAmount ||
              team2Wins >= winConditionAmount
            )
              break
          }

          if (
            context.bracketSettings?.winCondition === WinCondition.BEST_OF &&
            (team1Wins >= winConditionAmount / 2 ||
              team2Wins >= winConditionAmount / 2)
          ) {
            return result
          }

          if (last(result)?.winner === -1) {
            return result
          }

          // no winner yet, no ties, add a blank score row to fill out
          // unless its bestof mode, in which case only do so if < winConditionAmount
          if (
            (context.bracketSettings?.winCondition === WinCondition.BEST_OF &&
              result.length < winConditionAmount) ||
            (context.bracketSettings?.winCondition === WinCondition.FIRST_TO &&
              team1Wins < winConditionAmount &&
              team2Wins < winConditionAmount)
          ) {
            result.push({ ordinal: result.length + 1, winner: -1 })
          }

          return result
        }
      })
    }
  }
)

// placeholder to make machine easier to pass around
const M_ = () =>
  useMachine<MatchResultsContext, MatchResultsActions>(MatchResultsMachine)
export type MatchResultsMachine = ReturnType<typeof M_>
