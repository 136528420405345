import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { Countdown } from '@/components/molecules/text/Countdown'
import { expandById, ExpansionType } from '@/core/expand'
import { useTime } from '@/core/hooks/useTime'
import { useBracketMatch } from '@/entity/bracket-match/useBracketMatch'
import { useBracketMatchCheckin } from '@/entity/bracket-match/useBracketMatchCheckins'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  BracketAssignmentResource,
  CompetitionBracketSettingsResource,
  CompetitionEntryResource
} from '@rallycry/api-suite-typescript'
import { useState } from 'react'

const start = new Date()
export const MatchCheckinDisplay = ({
  isPlayerOrCoach
}: {
  isPlayerOrCoach?: boolean
}) => {
  const [countdownComplete, setCountdownComplete] = useState(start)
  const { isCompetitionModerator } = useCompetition()
  const { match } = useBracketMatch()
  const { checkins, checkin } = useBracketMatchCheckin()
  const { involvement } = useCompetitionParticipant()
  const { getNow, displayDateTime } = useTime()

  const bracket = expandById(
    match?.bracket?.id,
    match?._expanded,
    ExpansionType.CompetitionBracket
  )
  const settings = expandById<CompetitionBracketSettingsResource>(
    bracket?.settings?.id,
    match?._expanded,
    ExpansionType.CompetitionBracketSettings
  )

  if (!settings?.matchCheckInEnabled || !match?.checkInBegins) return null

  const assignment1 = expandById<BracketAssignmentResource>(
    match?.assignments?.[0]?.id,
    match?._expanded,
    ExpansionType.BracketAssignment
  )
  const assignment2 = expandById<BracketAssignmentResource>(
    match?.assignments?.[1]?.id,
    match?._expanded,
    ExpansionType.BracketAssignment
  )

  const entry1 = expandById<CompetitionEntryResource>(
    assignment1?.entry?.id,
    match?._expanded,
    ExpansionType.CompetitionEntry
  )
  const entry2 = expandById(
    assignment2?.entry?.id,
    match?._expanded,
    ExpansionType.CompetitionEntry
  )

  const team1Checkin = checkins?.find(
    it => it.assignment?.id === assignment1?.id
  )
  const team2Checkin = checkins?.find(
    it => it.assignment?.id === assignment2?.id
  )

  const myEntries = involvement?.map(it => it.entry?.id!) || []
  const myAssignments: number[] = []
  if (myEntries.includes(assignment1?.entry?.id!)) {
    myAssignments.push(assignment1?.id!)
  }
  if (myEntries.includes(assignment2?.entry?.id!)) {
    myAssignments.push(assignment2?.id!)
  }

  const myCheckIn = checkins?.find(it =>
    myAssignments.includes(it.assignment?.id!)
  )
  const theirCheckIn = checkins?.find(
    it => !myAssignments.includes(it.assignment?.id!)
  )

  const checkinUpcoming = getNow().isBefore(match?.checkInBegins!)
  const isCheckinActive = getNow().isBetween(
    match?.checkInBegins!,
    match?.checkInDeadline
  )

  const handleCheckin = async () => {
    for (const assignment of match?.assignments || []) {
      if (
        (isCompetitionModerator && !isPlayerOrCoach) ||
        myAssignments.includes(assignment.id!)
      ) {
        try {
          await checkin(assignment.id!)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const CheckinStatus = (
    <Stack direction='column' spacing={1} p={2}>
      <Typography variant='caption'>
        {entry1?.alternateName}:{' '}
        {team1Checkin ? (
          displayDateTime(team1Checkin?.dateCreated)
        ) : (
          <RcTrans i18nKey='competition:match.not-checked-in' />
        )}
      </Typography>
      <Typography variant='caption'>
        {entry2?.alternateName}:{' '}
        {team2Checkin ? (
          displayDateTime(team2Checkin?.dateCreated)
        ) : (
          <RcTrans i18nKey='competition:match.not-checked-in' />
        )}
      </Typography>
      {isCheckinActive && checkins.length < 2 ? (
        <Typography variant='caption'>
          {/* Check In Closes In: */}
          <RcTrans i18nKey='competition:match.check-in-countdown'>
            <Countdown
              target={match.checkInDeadline!}
              onComplete={() => {
                setCountdownComplete(new Date())
              }}
            />
          </RcTrans>
        </Typography>
      ) : null}
    </Stack>
  )

  return (
    <Tooltip title={CheckinStatus} arrow key={countdownComplete?.toString()}>
      <Box>
        {checkinUpcoming ? (
          <RcButton disabled size='small'>
            <RcTrans i18nKey='competition:match.checkin-soon' />
            <Box pl={1}>
              <Countdown
                target={match?.checkInBegins!}
                onComplete={() => {
                  setCountdownComplete(new Date())
                }}
              />
            </Box>
          </RcButton>
        ) : checkins.length === 2 ? (
          <RcButton disabled size='small'>
            <RcTrans i18nKey='competition:match.checkin-both-checked-in' />
          </RcButton>
        ) : myCheckIn ? (
          isCheckinActive ? (
            <RcButton color='warning' size='small'>
              <RcTrans i18nKey='competition:match.checkin-current-checked-in' />
            </RcButton>
          ) : (
            <RcButton disabled size='small'>
              <RcTrans i18nKey='competition:match.checkin-opponent-missed-checked-in' />
            </RcButton>
          )
        ) : theirCheckIn ? (
          <RcButton
            highlight={isCheckinActive}
            disabled={!isCheckinActive}
            onClick={handleCheckin}
            size='small'
          >
            {isCheckinActive ? (
              isCompetitionModerator ? (
                <RcTrans i18nKey='competition:match.single-checkin-missing' />
              ) : (
                <RcTrans i18nKey='competition:match.checkin-opponent-checked-in' />
              )
            ) : isCompetitionModerator ? (
              <RcTrans i18nKey='competition:match.single-checkin-missing' />
            ) : (
              <RcTrans i18nKey='competition:match.checkin-missed' />
            )}
          </RcButton>
        ) : isCheckinActive ? (
          <RcButton onClick={handleCheckin} size='small' highlight>
            <RcTrans i18nKey='competition:match.checkin-neither-check-in' />
          </RcButton>
        ) : (
          <RcButton disabled size='small'>
            <RcTrans i18nKey='competition:match.checkin-both-missed-checked-in' />
          </RcButton>
        )}
      </Box>
    </Tooltip>
  )
}
