import {
  BracketMatchControllerApi,
  ViewBracketMatchesByCompetitionRequest
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBracketMatchesUber = (
  options?: EntityOptions<ViewBracketMatchesByCompetitionRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { ctrl } = useController(BracketMatchControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketMatchesUber',
    expand:
      '_links,totalElements,content{bracket{settings},event,games,assignments{entry}',
    mutable: true,
    ...options,
    request: { competitionId, ...options?.request },
    read: req => ctrl().viewBracketMatchesByCompetition(req)
  })

  return {
    ...entity,
    matches: entity.flat
  }
}
