import { EntityOptions } from '../useEntity'
import { useCompetition } from './useCompetition'

export const useCompetitionIssues = (options?: EntityOptions) => {
  const { competition } = useCompetition(options)

  return {
    issues: competition?._issues
  }
}
