import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'
import {
  ViewCompetitionEntryContactAccountsRequest,
  CompetitionAliasControllerApi,
  ContentResourceContactAccountResource,
  NetworkKind
} from '@rallycry/api-suite-typescript'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'

export const useCompetitionTeamAliases = (
  options?: EntityOptions<ViewCompetitionEntryContactAccountsRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { meta } = useCompetitionMeta()
  const { user } = useUserAccount()
  const teamId = useParsedParam('teamId')
  const { ctrl: aliasCtrl } = useController(CompetitionAliasControllerApi)

  const aliasMode = meta?.aliases! > 0
  const entity = useQueryEntity({
    key: 'useCompetitionTeamContactAliases',
    expand: 'content{_links}',
    ...options,
    paused: !user || !aliasMode,
    request: teamId ? { id: teamId } : undefined,
    read: (req, { alias }) =>
      aliasCtrl({
        metas: { alias },
        expectedStatuses: [403, 404],
        fallbackResponse: { content: [] }
      })
        .viewCompetitionAliasesByEntry({
          entryId: req.id
        })
        .then(res => {
          res.content = res?.content?.map(alias => ({
            ...alias,
            user: alias.involved
          }))
          return res as ContentResourceContactAccountResource
        })
  })

  const authorizeSnapshot = async (networks: NetworkKind[]) => {
    aliasCtrl().authorizeSnapshotCompetitionAlias({
      competitionId,
      competitionAliasAuthorizeSnapshotCommand: {
        authorizedNetworks: networks
      }
    })
  }

  return { ...entity, aliases: entity.flat, authorizeSnapshot }
}
