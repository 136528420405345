import {
  EntryMemberRequestControllerApi,
  ReadAllEntryMemberRequestsRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberRequestControllerApi'
import { AcceptDecline } from '@rallycry/api-suite-typescript/dist/models/AcceptDecline'
import { InviteApply } from '@rallycry/api-suite-typescript/dist/models/InviteApply'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionTeamMembers } from './useCompetitionTeamMembers'
import { useApiError } from '@/core/hooks/useApiError'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionTeamApplications = (
  options?: EntityOptions<ReadAllEntryMemberRequestsRequest>
) => {
  const { handle } = useApiError()
  const entryId = useParsedParam('teamId')
  const {
    query: { mutate: mutateRoster }
  } = useCompetitionTeamMembers()
  const { ctrl } = useController(EntryMemberRequestControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeamApplications',
    expand: 'content{_links,agent}',
    mutable: true,
    ...options,
    request: {
      entryId,
      kind: InviteApply.APPLY,
      status: AcceptDecline.PENDING,
      ...options?.request
    },
    read: req =>
      ctrl({
        expectedStatuses: [401],
        fallbackResponse: { content: [] }
      }).readAllEntryMemberRequests(req)
  })

  const accept = async (id: number) => {
    try {
      const req = { id, communityMemberCommand: {} }
      await ctrl().acceptEntryMemberRequest(req)
      await entity.query.mutate()
      await mutateRoster()
    } catch (error) {
      handle(error)
    }
  }

  const reject = async (id: number) => {
    await ctrl().declineEntryMemberRequest({ id })
    await entity.query.mutate()
  }

  return {
    ...entity,
    applications: entity.flat,
    accept,
    reject
  }
}
