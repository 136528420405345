import {
  CompetitionEntryDocumentControllerApi,
  SearchCompetitionEntriesRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryDocumentControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionTeamDocument = (
  options: EntityOptions<SearchCompetitionEntriesRequest>
) => {
  const { ctrl } = useController(CompetitionEntryDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeamDocument',
    paused: !options.request,
    suspense: false,
    ...options,
    mutable: true,
    dedupingInterval: 30000,
    request: {
      ...options?.request,
      q: options?.request?.q || undefined
    },
    read: async req => ctrl().searchCompetitionEntries(req)
  })

  return { ...entity, teams: entity.flat }
}
