'use client'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { BracketKind } from '@rallycry/api-suite-typescript'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { GameResource } from '@rallycry/api-suite-typescript/dist/models/GameResource'
import { PropsWithChildren } from 'react'
import { CompetitionAlertsV2 } from './alerts/CompetitionAlerts'
import { LadderBanner } from './bracket/Ladder/LadderBanner'
import { CompetitionFab } from './CompetitionFab'
import { CompetitionActions } from './shared/CompetitionActions'
import { CompetitionNavigator } from './shared/CompetitionNavigator'
import { useCompetitionTicketFlow } from './tickets/useCompetitionTicketFlow'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { ChipinatorDisplay } from '@/components/molecules/text/Chipinator'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { Page } from '@/components/organisms/page/Page'
import { PageHeader } from '@/components/organisms/page/PageHeader'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import { expand, expander, ExpansionType } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { useBrackets } from '@/entity/bracket/useBrackets'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionMeta } from '@/entity/competition/useCompetitionMeta'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useFirebaseMeta } from '@/entity/site/useFirebaseMeta'

export const Competition = (props: PropsWithChildren) => {
  const { navTo } = useNavigation()
  const { imgCommunityBanner, imgCommunityAvatar } = useFeatures()
  const { read: meta } = useCompetitionMeta()
  const { competition, profile, isCompetitionModerator } = useCompetition()

  useCompetitionTicketFlow({ competition })
  useFirebaseMeta({
    id: competition?.id?.toString(),
    entityType: EntityType.COMPETITION_META,
    // revalidate = true to work around checkin meta race condition in SWR
    mutator: (updated: any) =>
      meta.mutate(prev => ({ ...prev, ...updated }), true),
    paused: !isCompetitionModerator,
    jitter: !isCompetitionModerator
  })

  const game = expander<GameResource>(competition, 'game')
  const defaultAvatarSrc = imgCommunityAvatar as string
  const defaultBannerImageSrc = imgCommunityBanner as string
  const bannerImageSrc =
    profile?.bannerImage || game?.image || game?.icon || defaultBannerImageSrc

  return (
    <Page>
      <PageHeader
        AvatarProps={{
          src: competition?.image || game?.icon || defaultAvatarSrc,
          variant: 'rounded'
        }}
        ImageDisplayProps={{ path: bannerImageSrc }}
        name={competition?.name}
        description={
          <ChipinatorDisplay
            onClick={async val =>
              navTo(
                {
                  root: RootRoute.Discovery,
                  subRoute: DiscoveryRoute.Competitions,
                  query: `tag=${val}`
                },
                true
              )
            }
            value={competition?.disambiguatingDescription}
            preChips={[
              {
                label: game?.name,
                color: 'primary',
                icon: (
                  <RcAvatar
                    variant='rounded'
                    alt={game?.name}
                    src={game?.icon || game?.image}
                    sx={{ width: 18, height: 18 }}
                  />
                ),
                sx: { cursor: 'pointer' },
                onClick: () =>
                  navTo(
                    {
                      root: RootRoute.Discovery,
                      subRoute: DiscoveryRoute.Competitions,
                      query: `tag=${game?.name}`
                    },
                    true
                  )
              }
            ]}
          />
        }
        actions={
          <RcSuspense skipLoader>
            <CompetitionActions />
          </RcSuspense>
        }
      >
        <RcSuspense skipLoader>
          <CompetitionNavigator />
        </RcSuspense>
      </PageHeader>

      <Box>
        <RcSuspense skipLoader skipError>
          <CompetitionAlertsV2 />
        </RcSuspense>
        <Stack direction='column' spacing={2}>
          <RcSuspense>{props.children}</RcSuspense>
        </Stack>
      </Box>
    </Page>
  )
}

export const LADDER_KINDS: BracketKind[] = [
  BracketKind.LADDER,
  BracketKind.SWISS_LADDER
]
export const LadderBannerWrapper = () => {
  const { brackets } = useBrackets()
  const { hasTeam } = useCompetitionParticipant()

  const bracket = brackets?.find(it =>
    LADDER_KINDS.includes(
      expand(it, it._expanded, ExpansionType.CompetitionBracketSettings)?.kind
    )
  )
  if (!bracket || !hasTeam()) return null

  return (
    <RouteParamOverrideProvider
      context={{ bracketId: bracket?.id?.toString() }}
    >
      <LadderBanner />
    </RouteParamOverrideProvider>
  )
}
