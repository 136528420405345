import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { JoinFlowContext } from './join-flow-machine'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useOrganizationCommunity } from '@/entity/organization/useOrganizationCommunity'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'

interface SetCommunityProps {
  context: JoinFlowContext
  onSelected: (values: JoinFlowContext) => void
  onBack?: () => void
}

export const SetCommunity: React.FC<SetCommunityProps> = ({
  context,
  onSelected,
  onBack
}) => {
  // use a local state to allow selection prior to hitting "next"
  const [nonParticipantLeader, setNonParticipantLeader] = useState(
    context.nonParticipantLeader || false
  )
  const { imgCommunityAvatar, featEntryCoaches } = useFeatures()
  const defaultAvatarSrc = imgCommunityAvatar as string

  return (
    <Stack direction='column' spacing={3}>
      {featEntryCoaches ? (
        <Box>
          <LabeledFieldHeader
            label={
              <RcTrans i18nKey='competition:team.non-participating-leader-title' />
            }
            description={
              context.nonParticipantLeaderLocked ? (
                <RcTrans i18nKey='competition:team.non-participating-leader-locked-description' />
              ) : (
                <RcTrans i18nKey='competition:team.non-participating-leader-description' />
              )
            }
          />

          <Select
            value={nonParticipantLeader}
            onChange={async val =>
              setNonParticipantLeader(val.target.value === 'true')
            }
            disabled={context.nonParticipantLeaderLocked}
            fullWidth
            variant='outlined'
          >
            <MenuItem value={'false'}>
              <RcTrans i18nKey='competition:team.participating-leader' />
            </MenuItem>
            <MenuItem value={'true'}>
              <RcTrans i18nKey='competition:team.non-participating-leader' />
            </MenuItem>
          </Select>
        </Box>
      ) : null}
      <Box>
        <Grid container direction='row' spacing={3}>
          <Grid item xs={6} sx={{ cursor: 'pointer' }}>
            <DiscoverCard
              onClick={() => onSelected({ nonParticipantLeader })}
              content={
                <Stack
                  direction='column'
                  height='100%'
                  justifyContent='center'
                  alignItems='center'
                  spacing={1}
                >
                  <RcIcon icon={['fal', 'plus']} size='2x' />
                  <Typography variant='body0'>
                    <RcTrans i18nKey='competition:join-flow.create-new-team' />
                  </Typography>
                </Stack>
              }
            />
          </Grid>
          {context.orgCommunities
            ?.filter(it => it.kind === CommunityKind.TEAM)
            ?.map(it => {
              return (
                <Grid item key={it.id} xs={6} sx={{ cursor: 'pointer' }}>
                  <DiscoverCard
                    title={it.name}
                    onClick={() =>
                      onSelected({
                        image: it?.image || defaultAvatarSrc,
                        representing: it?.id,
                        alternateName: it?.name,
                        nonParticipantLeader
                      })
                    }
                    headerImage={it.image || defaultAvatarSrc}
                  />
                </Grid>
              )
            })}
        </Grid>
      </Box>
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:join-flow.set-team-title' />}
        topAction={onBack}
      />
    </Stack>
  )
}

export const CommunityItem = ({
  it,
  selected,
  setSelected
}: {
  it: CommunityResource
  selected?: CommunityResource
  setSelected: React.Dispatch<
    React.SetStateAction<CommunityResource | undefined>
  >
}) => {
  const community = useOrganizationCommunity({
    community: it,
    expanded: it._expanded
  })

  return (
    <RcPaperButton
      selected={selected?.id === it?.id}
      onClick={() => setSelected(that => (that?.id === it.id ? undefined : it))}
    >
      <AvatarText src={community?.image} variant='rounded'>
        {community?.name}
      </AvatarText>
    </RcPaperButton>
  )
}
