import {
  AccessGroupControllerApi,
  QueryAccessGroupsRequest
} from '@rallycry/api-suite-typescript/dist/apis/AccessGroupControllerApi'
import {
  AccessGroupCreateCommand,
  AccessGroupResource,
  AccessGroupUpdateCommand
} from '@rallycry/api-suite-typescript/dist/models'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useAccessGroups = (
  options?: EntityOptions<QueryAccessGroupsRequest>
) => {
  const { ctrl } = useController(AccessGroupControllerApi)

  const entity = useQueryEntity({
    key: 'useAccessGroups',
    expand: 'content{grantsOnJoin}',
    request: { size: 1000, ...options?.request },
    ...options,
    read: req => ctrl().queryAccessGroups(req)
  })

  const create = async (cmd: AccessGroupCreateCommand) => {
    await ctrl().createAccessGroup({
      accessGroupCreateCommand: cmd
    })
    await entity.query.mutate()
  }

  const update = async (id: number, cmd: AccessGroupUpdateCommand) => {
    await ctrl().updateAccessGroup({
      idOrKey: id.toString(),
      accessGroupUpdateCommand: cmd
    })
    await entity.query.mutate()
  }

  const remove = async (id: number) => {
    await ctrl().removeAccessGroup({
      id
    })
    await entity.query.mutate()
  }

  return {
    ...entity,
    groups: entity.flat,
    update,
    create,
    remove
  }
}
