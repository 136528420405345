import { RcTrans } from '@/components/atoms/RcTrans'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { RcFileInput } from '@/components/molecules/input/RcFileInput'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { RcToggle } from '@/components/molecules/input/RcToggle'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { PageForm } from '@/components/organisms/page/PageForm'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'
import { useCommunityDocument } from '@/entity/community/useCommunityDocument'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserSelfCommunities } from '@/entity/user/useUserSelfCommunities'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { isArray } from 'util'
import { TeamJoinRestrictionForm } from './TeamJoinRestrictionForm'

export const TeamSettings = () => {
  const { isSoloCompetition, isCompetitionModerator } = useCompetition()
  const { team, update } = useCompetitionTeam()

  const isTeamOwner = !!team?._links?._delete || isCompetitionModerator
  const showCommunity =
    team?._expanded?.community?.[0]?.kind !== CommunityKind.TEAM

  const { alternateName, rank, lookingForMore, abbreviation } = team!

  // t('competition:description-context-solo')
  // t('competition:description-context-team')
  return (
    <Stack direction='column' spacing={8}>
      <Box>
        <LabeledFieldHeader
          label={<RcTrans i18nKey='competition:team.image-label' />}
          description={<RcTrans i18nKey='competition:team.image-context' />}
        />
        <Box maxWidth={150}>
          <RcFileInput
            source={team?.image}
            onComplete={async value => update(team?.id!, { image: value })}
            saveAs={`team-image-${team?.id}`}
            aspectRatio={1}
          />
        </Box>
      </Box>

      <RcTextInput
        property='alternateName'
        source={{ alternateName }}
        update={value => update(team?.id!, value)}
        label={<RcTrans i18nKey='competition:team.name-label' />}
        description={<RcTrans i18nKey='competition:team.name-context' />}
        disabled={
          !team?._links?.update ||
          (isSoloCompetition && !isCompetitionModerator)
        }
        required
      />

      <RcTextInput
        property='abbreviation'
        source={{ abbreviation: abbreviation || '' }}
        defaultValue=''
        update={value =>
          update(team?.id!, {
            abbreviation: value.abbreviation ? value.abbreviation : undefined,
            clearAbbreviation: !value.abbreviation
          })
        }
        label={<RcTrans i18nKey='competition:settings.abbreviation-label' />}
        description={
          <RcTrans i18nKey={'competition:settings.abbreviation-context'} />
        }
      />
      {showCommunity ? (
        isCompetitionModerator ? (
          <ModeratorCommunitySelector />
        ) : (
          <UserCommunitySelector />
        )
      ) : null}

      {isTeamOwner && !isSoloCompetition ? <TeamJoinRestrictionForm /> : null}

      {!isSoloCompetition ? (
        <PageForm
          title={<RcTrans i18nKey='competition:team.looking-for-more' />}
          description={
            team?.joinRestriction === JoinRestriction.INVITE_ONLY ? (
              <RcTrans i18nKey='competition:team.looking-for-more-disabled' />
            ) : (
              <RcTrans i18nKey='competition:team.looking-for-more-description' />
            )
          }
          sideChild
        >
          <RcToggle
            disabled={team?.joinRestriction === JoinRestriction.INVITE_ONLY}
            property={'lookingForMore'}
            source={{ lookingForMore: lookingForMore }}
            update={value => update(team?.id!, value)}
          />
        </PageForm>
      ) : null}

      {isCompetitionModerator ? (
        <RcSelect
          property='rank'
          source={{ rank: rank || 0 }}
          update={value =>
            update(
              team?.id!,
              value.rank > 0 ? { rank: value.rank } : { clearRank: true }
            )
          }
          label={<RcTrans i18nKey='competition:team.rank-label' />}
        >
          {Array.from(Array(13).keys()).map(value => (
            <MenuItem key={value} value={value}>
              {value === 0 ? (
                <RcTrans i18nKey='shared.unranked' />
              ) : (
                <RcTrans i18nKey={`shared.rank-${value}`} />
              )}
            </MenuItem>
          ))}
        </RcSelect>
      ) : null}

      <ModalConfiguration
        title={<RcTrans i18nKey='competition:team.settings-title' />}
      />
    </Stack>
  )
}

const UserCommunitySelector = () => {
  const { team, update } = useCompetitionTeam()
  const { getMemberships } = useUserSelfCommunities()
  return (
    <RcSelect
      property='representing'
      source={{ representing: team?.representing?.id || 0 }}
      update={value => update(team?.id!, value)}
      label={<RcTrans i18nKey='shared.community' />}
      description={<RcTrans i18nKey='competition:community-context' />}
    >
      {getMemberships()?.map(it => (
        <MenuItem key={it.community?.id} value={it.community?.id}>
          <AvatarText
            src={it.community.image}
            variant='rounded'
            contain
            ellipsisText
            skipAvatar
          >
            {it.community?.name}
          </AvatarText>
        </MenuItem>
      ))}
    </RcSelect>
  )
}

const ModeratorCommunitySelector = () => {
  const { team, update } = useCompetitionTeam()

  const { t } = useRcTranslation([TranslationNamespace.Discovery])
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 300)

  const {
    communities,
    query: { setSize, size, isValidating }
  } = useCommunityDocument({
    request: { kind: CommunityKind.BASIC, q: debouncedQuery }
  })

  return (
    <Box>
      <LabeledFieldHeader label={<RcTrans i18nKey='shared.community' />} />
      <EntityAutocomplete
        isValidating={isValidating}
        options={communities}
        selected={[]}
        placeholder={t('discovery:community-search-placeholder')}
        onSearch={q => setQuery(q)}
        onLoadOptions={() => setSize(size + 1)}
        onChange={async updated => {
          const community = isArray(updated) ? undefined : updated
          update(team?.id!, { representing: community?.id })
        }}
      />
    </Box>
  )
}
