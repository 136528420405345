import { RcTrans } from '@/components/atoms/RcTrans'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { expandById, ExpansionType } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { Expanded } from '@rallycry/api-suite-typescript/dist/models/Expanded'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { ComponentGenericMatch } from '../../../../../models/types'
import { useMatchResult } from '../../Competition/components/useBracketMatchView'
import { MatchTickerRow } from './MatchTickerRow'

export const MatchTickerDetail = ({
  bracketMatch,
  skipLink,
  skipBorder,
  padding,
  ...props
}: ComponentGenericMatch & {
  team1AvatarSrc?: string
  team2AvatarSrc?: string
  status?: React.ReactNode
  bracketMatch?: BracketMatchResource & {
    _expanded?: Expanded | undefined
  }
  skipLink?: boolean
  skipBorder?: boolean
  padding?: number
}) => {
  const { getPath } = useNavigation()
  const event = expandById<CompetitionEventResource>(
    bracketMatch?.event?.id,
    bracketMatch?._expanded,
    ExpansionType.CompetitionEvent
  )

  const team1 = useMatchResult(
    undefined,
    bracketMatch?.assignments?.[0]?.id,
    bracketMatch,
    null,
    0
  )
  const team2 = useMatchResult(
    undefined,
    bracketMatch?.assignments?.[1]?.id,
    bracketMatch,
    null,
    1
  )

  const image1 = team1.team?.image
  const image2 = team2.team?.image

  const translated = bracketMatch
    ? {
        id: bracketMatch?.id?.toString() || '',
        matchTime: event?.startDate,
        team1AvatarName: team1.orgCommunity?.name,
        team1AvatarSrc: image1,
        team1ShortName: team1.team?.abbreviation,
        team1LongName: team1.team?.alternateName,
        team1Score: team1?.score?.toString(),
        team2AvatarName: team2.orgCommunity?.name,
        team2AvatarSrc: image2,
        team2ShortName: team2.team?.abbreviation,
        team2LongName: team2.team?.alternateName,
        team2Score: team2?.score?.toString(),
        status:
          bracketMatch?.state === MatchState.COMPLETE ? (
            <RcTrans i18nKey='competition:match.final' />
          ) : (
            <RcTrans i18nKey='competition:match.active' />
          ),
        link: {
          id: '',
          path: getPath({
            root: RootRoute.Competition,
            rootId:
              bracketMatch?._expanded?.competitionBracket?.[0]?.competition?.id,
            subRoute: CompetitionRoute.Match,
            subId: bracketMatch?.id
          })
        }
      }
    : null

  const resolved = translated || props

  return (
    <NavigationLink
      to={(!skipLink && resolved.link?.path) || ''}
      underline='none'
    >
      <Stack
        direction='column'
        sx={theme => ({
          height: '100%',
          borderRight: skipBorder ? '' : `1px solid ${theme.palette.divider}`,
          p: padding || 2
        })}
        spacing={2}
        justifyContent='flex-end'
      >
        <MatchTickerRow
          avatar={resolved.team1AvatarSrc}
          avatarName={resolved.team1AvatarName}
          shortName={resolved.team1ShortName}
          longName={resolved.team1LongName}
          score={resolved.team1Score}
          isWinner={
            bracketMatch?.winner?.id === team1.bracketAssignment?.id &&
            bracketMatch?.state === MatchState.COMPLETE
          }
        />
        <MatchTickerRow
          avatar={resolved.team2AvatarSrc}
          avatarName={resolved.team2AvatarName}
          shortName={resolved.team2ShortName}
          longName={resolved.team2LongName}
          score={resolved.team2Score}
          isWinner={
            bracketMatch?.winner?.id === team2.bracketAssignment?.id &&
            bracketMatch?.state === MatchState.COMPLETE
          }
        />
      </Stack>
    </NavigationLink>
  )
}
